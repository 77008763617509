<template>
  <v-col class="pa-10 mt-8 px-3 px-md-10" cols="12" align-items="flex-start">
    <v-card flat>
      <v-card-title class="d-flex flex-column align-start">
        <span class="desctop-bs-header-title">
          {{ $t("bookingPaymentActionRequired.title") }}
        </span>
      </v-card-title>
      <div >
        <v-row no-gutters>
          <v-col class="col-without-top-padding table-color">
            <v-data-table
              class="elevation-0"
              :items="bookingsRequirePaymentAction"
              :headers="headers"
              :loading="isLoading"
              :disable-sort="true"
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template v-slot:item.pickupTime="{ item }">
                <div class="d-flex flex-column">
                  <span class="">{{ formatPickupTime(item.pickupTime)}}</span>
                </div>
              </template>
              <template v-slot:item.origin="{ item }">
                <div class="d-flex flex-column">
                  <span class="text-overflow">{{ item.origin.address }}</span>
                </div>
              </template>

              <template v-slot:item.destination="{ item }">
                <div class="d-flex flex-column">
                  <span class="text-overflow">{{ item.destination.address }}</span>
                </div>
              </template>
              <template v-slot:item.payment="{ item: { payment: { paymentMethod: { brand, number } } } }">
                <div class="d-flex align-center">
                  <component v-bind:is="creditCardIcon(brand)"/>
                  <span class="ml-3"> ending with {{number}}</span>
                </div>
              </template>
              <template v-slot:item.confirm="{ item }">
                <v-btn class="btn primary" @click="$emit('confirmPayment', item)" :loading="confirmLoading" small depressed>
                  {{ $t('bookingPaymentActionRequired.confirm') }}
                </v-btn>
                <v-btn class="btn danger ml-2" @click="$emit('cancelBooking', item)" small depressed>
                  {{ $t('bookingPaymentActionRequired.cancel') }}
                </v-btn>
              </template>
            </v-data-table>
            <div class="py-2 px-4 desctop-bs-table-footer">
              <v-row no-gutters class="desctop-bs-per-page">
                <span>
                  {{ $t(`bookings.perPage`) }}
                </span>
                <span
                  v-for="item  in [10, 20, 30]"
                  :key="item"
                  :class="{ 'active-page-count': item === itemsPerPage }"
                  class="px-1"
                  @click="itemsPerPage = item"
                >
                  {{ item }}
                </span>
              </v-row>
              <v-pagination
                v-model="page"
                :total-visible="10"
                color="#f2f2f2"
                class="desctop-bs-pagination"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';
import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';

export default {
  props: {
    bookingsRequirePaymentAction: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    confirmLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {},
  mixins: [creditCardIcon],
  // eslint-disable-next-line max-lines-per-function
  data() {
    return {
      itemsPerPage: 30,
      page: 1,
      headers: [
        {
          text: this.$t('bookings.pickupDate'),
          align: 'left',
          sortable: true,
          value: 'pickupTime',
        },
        {
          text: this.$t('bookingPaymentActionRequired.dispatchId'),
          align: 'left',
          sortable: true,
          value: 'dispatchId',
        },
        {
          text: this.$t('bookings.origin'),
          align: 'left',
          sortable: false,
          value: 'origin',
        },
        {
          text: this.$t('bookings.destination'),
          align: 'left',
          sortable: false,
          value: 'destination',
        },
        {
          text: this.$t('bookingPaymentActionRequired.card'),
          align: 'left',
          sortable: false,
          value: 'payment',
        },
        {
          text: this.$t('bookingPaymentActionRequired.confirm'),
          align: 'left',
          sortable: false,
          value: 'confirm',
        },
      ],
    };
  },
  computed: {
  },
  watch: {
    options: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    formatPickupTime(pickupTime) {
      return moment(pickupTime).format('DD MMMM YYYY HH:mm');
    },
    async loadData() {
      this.$emit('callLoadData');
    },
  },
};
</script>
