<template>
  <dialog-wrapper ref="dialogConfirm" :width=400>
    <template v-slot:content>
      <v-card>
        <slot></slot>
        <v-card-actions>
          <v-btn
            class="btn btn-flat"
            text
            @click="handleCancelButton"
          >
            {{cancelButtonText ? cancelButtonText : $t('cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-danger"
            depressed
            :loading="loading"
            @click="handleOkButton"
          >
            {{okButtonText ? okButtonText : $t('ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </dialog-wrapper>
</template>

<script>
import DialogWrapper from './DialogWrapper.vue';

export default {
  name: 'ConfirmDialog',
  components: { DialogWrapper },
  props: {
    okButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleOkButton() {
      this.$emit('handleOkButton', this.data);
    },
    handleCancelButton() {
      this.$emit('handleCancelButton');
    },
    close() {
      this.$refs.dialogConfirm.close();
    },
    open() {
      this.$refs.dialogConfirm.openDialog();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "ok": "Ok",
    "cancel": "Cancel"
  },
  "fr": {
    "ok": "Ok",
    "cancel": "Annuler"
  },
  "fi": {
    "ok": "Ok",
    "cancel": "Peruuta"
  },
  "sv": {
    "ok": "Ok",
    "cancel": "Cancel"
  }
}
</i18n>
