
export function bookingCancellable(booking, bookingInCancellation, cancelledBookingIds) {
  return (
    !bookingInCancellation
    && !dispatchRequested(booking.status)
    && !cancelledBookingIds.includes(booking.id)
    && !bookingCancelled(booking.status)
    && !bookingCompleted(booking.status)
    && !bookingInRide(booking.status)
  );
}

export function bookingCompleted(status) {
  return [
    'COMPLETE',
    'COMPLETED',
  ].includes(status);
}

export function dispatchRequested(status) {
  return [
    'BOOKING-DISPATCH-REQUESTED',
  ].includes(status);
}

export function bookingCancelled(status) {
  return [
    'BOOKING-CANCELLATION-REQUESTED',
    'CUSTOMER_CANCELLED',
    'BOOKING-DISPATCH-CANCELLED',
    'DISPATCH_CANCELLED',
    'NOSHOW',
  ].includes(status);
}

export function bookingInRide(status) {
  return [
    'DROPPINGOFF',
    'MADECONTACT',
  ].includes(status);
}
