<template>
  <loader is-full-page active="true" :is-loading="true" color="primary"></loader>
</template>

<script>
import { mapGetters } from 'vuex';
import { logout } from '../../authentication/authenticationManager';

export default {
  name: 'Logout.vue',
  data() {
    return {
      screenName: 'user_profile',
    };
  },
  async created() {
    try {
      await this.$store.dispatch('authentication/revokeToken', { token: this.token, client_id: this.clientId });
      logout(this.idToken, this.postLogoutUrl);
    } catch (error) {
      throw error;
    }
  },
  computed: {
    ...mapGetters({
      token: 'authentication/token',
      idToken: 'authentication/idToken',
      postLogoutUrl: 'bookingChannel/postLogoutUrl',
      clientId: 'bookingChannel/oidcClientId',
    }),
  },
};
</script>

<style scoped>

</style>
