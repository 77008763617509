import enUk from './en-uk.json';
import enUs from './en-us.json';
import fr from './fr.json';
import sp from './sp.json';
import sv from './sv.json';
import fi from './fi.json';
import nl from './nl.json';

export default {
  'en-uk': enUk,
  'en-ca': enUs,
  'en-us': enUs,
  sv,
  fr,
  en: enUk,
  fi,
  sp,
  nl,
};
