<template>
  <div class="multiple-address-container">
    <v-timeline dense>
      <v-timeline-item
        small
        fill-dot
        right
        color="white"
      >
        <template v-slot:icon>
          <map-location class="icon-green" />
        </template>
        <v-col :cols="isShowFlightNumber && !$vuetify.breakpoint.smAndDown ? 7 : 12" class="px-0">
          <multiselect
            v-model="pickup"
            ref="pickup"
            class="address-select"
            track-by="id"
            label="description"
            :autofocus="!pickup"
            open-direction="bottom"
            preselect-first
            :tabindex="0"
            :show-labels="false"
            :placeholder="$t('rideTracking.summary.booking.pickup')"
            :show-no-options="false"
            :close-on-select="true"
            :clear-on-select="false"
            :options="items.pickup"
            :internal-search="false"
            :show-no-results="false"
            :searchable="true"
            :class="[targetValidator.origin.address.$anyError ? 'error-border' : '', isShowFlightNumber ? 'is-flight-number' : '']"
            height="45px"
            @search-change="debouncedSearch($event, 'pickup')"
            @select="getAddressDetails($event, 'pickup')"
          >
            <span slot="caret"></span>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.type === 'favourite'" class="d-flex align-center">
                <favourites-icon style="width:24px" />
                <span class="ml-2">{{ props.option.alias }}</span>
              </div>
              <span v-else>{{ props.option.description }}</span>
            </template>
          </multiselect>
        </v-col>

        <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>

        <v-col v-if="isShowFlightNumber" :cols="$vuetify.breakpoint.smAndDown ? 12 : 5" class="px-0">
          <v-text-field
            v-model="flightNumber"
            id="flightNumber"
            class="mt-0 flightNumberField"
            hide-details
            outlined
            :class="[targetValidator.bookingProperties.flightInfo.number.$anyError ? 'error-border' : '']"
            :placeholder="$t('onboarding.form.flightNumber')"
            @input="onUpdateFlightNumber"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-fade-transition>
                    <v-icon
                      v-if="targetValidator.bookingProperties.flightInfo.number.$anyError"
                      class="validation-icon"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      fa-light fa-circle-exclamation
                    </v-icon>
                  </v-fade-transition>
                </template>
                <div class="d-flex flex-column">
                  <span v-if="!targetValidator.bookingProperties.flightInfo.number.required">
                    {{ $t('validations.required', { attribute: $t('rideTracking.summary.booking.flightNumber') }) }}
                  </span>
                  <span v-if="!targetValidator.bookingProperties.flightInfo.number.minLength">
                    {{ $t('validations.minLength', { attribute: $t('rideTracking.summary.booking.flightNumber'), min: 3 }) }}
                  </span>
                </div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-timeline-item>
      <v-timeline-item
        v-for="(waypoint, index) in waypoints"
        :key="index"
        :index="index"
        small
        fill-dot
        right
        color="white"

      >
        <template v-slot:icon>
          <map-location />
        </template>
        <v-col class="px-0">
          <multiselect
            v-model="waypoints[index]"
            :ref="`waypoint${index}`"
            class="address-select waypoint-select"
            track-by="id"
            label="description"
            open-direction="bottom"
            :placeholder="$t('rideTracking.summary.booking.via')"
            :show-labels="false"
            :close-on-select="true"
            :clear-on-select="false"
            :options="items.waypoint"
            :internal-search="false"
            :show-no-options="false"
            :show-no-results="false"
            :searchable="true"
            :class="targetValidator.waypoints.$each[index].$anyError ? 'error-border' : ''"
            height="45px"
            @search-change="debouncedSearch($event, 'waypoint')"
            @select="getAddressDetails($event, 'waypoints', index)"
          >
            <span slot="caret">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-fade-transition>
                    <v-icon
                      v-if="targetValidator.waypoints.$each[index].$anyError"
                      class="validation-icon"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      fa-light fa-circle-exclamation
                    </v-icon>
                  </v-fade-transition>
                </template>
                <span>{{ $t('validations.required', { attribute: $t('rideTracking.summary.booking.waypoint') }) }}</span>
              </v-tooltip>
            </span>
          </multiselect>
          <v-icon
            class="remove-icon cursor-pointer"
            :class="targetValidator.waypoints.$each[index].$anyError ? 'slide-left-error' : ''"
            @click="$emit('removeWaypoint', index)"
          >mdi-close</v-icon>
        </v-col>
      </v-timeline-item>
      <v-timeline-item
        small
        fill-dot
        right
        color="white"
      >
        <template v-slot:icon>
          <map-location />
        </template>
        <v-col class="px-0">
          <multiselect
            v-model="destination"
            ref="destination"
            class="address-select"
            track-by="id"
            label="description"
            open-direction="bottom"
            :placeholder="$t('rideTracking.summary.booking.destionation')"
            :show-labels="false"
            :close-on-select="true"
            :clear-on-select="false"
            :options="items.destination"
            :internal-search="false"
            :show-no-options="false"
            :show-no-results="false"
            :searchable="true"
            :class="targetValidator.destination.address.$anyError ? 'error-border' : ''"
            height="45px"
            @search-change="debouncedSearch($event, 'destination')"
            @select="getAddressDetails($event, 'destination')"
          >
            <span slot="caret"></span>
            <template slot="option" slot-scope="props">
              <div v-if="props.option.type === 'favourite'" class="d-flex align-center">
                <favourites-icon style="width:24px" />
                <span class="ml-2">{{ props.option.alias }}</span>
              </div>

              <span v-else>{{ props.option.description }}</span>
            </template>
          </multiselect>
        </v-col>
      </v-timeline-item>
    </v-timeline>
    <v-row class="mt-2" v-if="isAddressViasFeatureEnabled">
      <v-col class="py-0">
        <a class="add-link" ref="addLink" v-if="!hasLimitWaypoints" v-on:click="addEmptyWaypoint">{{ $t('book.form.via.addStop') }}</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { compact } from 'lodash';
import debounce from 'lodash/debounce';
import { iconLoader } from '@/helpers/iconsLoader';
import { addressPicker } from '@/mixins/addressPicker.mixins';
import { mapGetters } from 'vuex';

const SOURCE_ICABBI = 'icabbi';

export default {
  name: 'address-picker',
  components: { ...iconLoader.booking, ...iconLoader.favourites },
  mixins: [addressPicker],
  props: {
    validator: {
      type: Object,
      required: true,
    },
    pickup: {
      type: Object,
      default: () => ({}),
    },
    waypoints: {
      type: Array,
      default: () => [],
    },
    destination: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$store.dispatch('favourites/getFavouriteAddresses', {
      payload: { page: 0, maxPerPage: 100 },
    });

    if (this.pickup) return;
    this.$nextTick(() => this.$refs.pickup.$el.focus());
  },
  data: () => ({
    flightNumber: '',
    items: {
      pickup: [],
      waypoint: [],
      destination: [],
    },
    selectedPickup: null,
    selectedDestination: null,
  }),
  watch: {
    waypoints(newVal, oldVal) {
      if (newVal.length >= oldVal.length) {
        const count = this.waypoints.length;

        if (count && !this.waypoints[count - 1].id) {
          this.$nextTick(() => this.$refs[`waypoint${count - 1}`][0].$el.focus());
        }

      }

      this.addDynamicHeightForTimeline(this.waypoints.length + 1);
    },
    isShowFlightNumber(val) {
      this.$emit('setFlightNumber', val);
    },
    flightNumber(val) {
      if (val) {
        this.targetValidator.bookingProperties.flightInfo.number.$touch();
      }
    },
    favouriteAddresses(val) {
      if (val.results.length) {
        val.results.forEach((el) => {
          if (el.suggestForPickupAddress) {
            this.items.pickup.push({
              id: el.id,
              description: el.fullAddressText,
              alias: el.alias,
              source: SOURCE_ICABBI,
              detail: el,
              type: 'favourite',
            });
          }

          if (el.suggestForDestinationAddress) {
            this.items.destination.push({
              id: el.id,
              description: el.fullAddressText,
              alias: el.alias,
              source: SOURCE_ICABBI,
              detail: el,
              type: 'favourite',
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      isAddressViasFeatureEnabled: 'bookingChannel/isAddressViasFeatureEnabled',
      favouriteAddresses: 'favourites/favouriteAddresses',
    }),
    hasLimitWaypoints() {
      return this.waypoints.length >= 10;
    },
    isShowFlightNumber() {
      return this.pickup && this.pickup.locationTypes && this.pickup.locationTypes.includes('airport');
    },
    targetValidator() {
      return this.validator;
    },
    favouriteSuggestionsForPickup() {
      return compact(this.favouriteAddresses.results.map((el) => {
        if (el.suggestForPickupAddress) {
          return {
            id: el.id,
            description: el.fullAddressText,
            alias: el.alias,
            source: SOURCE_ICABBI,
            detail: el,
            type: 'favourite',
          };
        }

        return null;
      }));
    },
    favouriteSuggestionsForDestination() {
      return compact(this.favouriteAddresses.results.map((el) => {
        if (el.suggestForDestinationAddress) {
          return {
            id: el.id,
            description: el.fullAddressText,
            alias: el.alias,
            source: SOURCE_ICABBI,
            detail: el,
            type: 'favourite',
          };
        }

        return null;
      }));
    },
  },
  methods: {
    debouncedSearch: debounce(async function (value, type) {
      if (value && value.length >= 3) {
        this.items[type] = [];

        if (type === 'pickup' && this.favouriteSuggestionsForPickup.length) {

          const matchFavourites = this.favouriteSuggestionsForPickup.filter(el => el.alias.toLowerCase().includes(value.toLowerCase()));

          if (matchFavourites.length) {
            this.items.pickup.push(...matchFavourites);
          }
        }

        if (type === 'destination' && this.favouriteSuggestionsForDestination.length) {

          const matchFavourites = this.favouriteSuggestionsForDestination.filter(el => el.alias.toLowerCase().includes(value.toLowerCase()));

          if (matchFavourites.length) {
            this.items.destination.push(...matchFavourites);
          }
        }

        const result = await this.getSuggestions(value);
        this.items[type].push(...result);
        return this.items;
      }

      this.items[type] = [];
      this.injectFavouriteAddress(type);
      return this.items;
    }, 500),
    injectFavouriteAddress(type) {
      if (type === 'pickup') {
        this.items.pickup.push(...this.favouriteSuggestionsForPickup);
      }

      if (type === 'destination') {
        this.items.destination.push(...this.favouriteSuggestionsForDestination);
      }
    },
    onUpdateFlightNumber(flightNumber) {
      this.flightNumber = flightNumber.toUpperCase();
      this.$emit('updateFlightNumber', this.flightNumber);
    },
    addEmptyWaypoint() {
      this.$emit('addWaypoint');
      this.addDynamicHeightForTimeline(this.waypoints.length + 1);
    },
    addDynamicHeightForTimeline(index) {
      const element = document.querySelector('.v-timeline');
      const newHeight = 63 * index;
      element.style.setProperty('--before-height', `${newHeight}px`);
    },
  },
  beforeDestroy() {
    this.$store.commit('geolocation/resetSuggestions');
  },
};
</script>

<style lang="scss" scoped>
.multiple-address-container {
  width: 100%;
  min-height: 8vh;

  .icon-green {
    color: #47d061
  }

  .remove-icon {
    position: absolute;
    right: 10px;
    top: 32%;
    z-index: 10;

    &:hover {
      color: black;
    }
  }

  .slide-left-error {
    animation: slide-left-error 0.5s;
    right: 6%;
  }

  .validation-icon {
    position: absolute;
    right: 10px;
    top: 33%;
    z-index: 10;
    color:  #ff0000;
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 475px) {
  .multiselect__input::placeholder {
    color: #333333;
    opacity: 1;
  }
}
.multiple-address-container {
  .add-link, .remove-link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.005em;
    text-decoration-line: underline;
    color: #888888 !important;
    cursor: pointer;

    i {
      top: 2px;
      font-size: 20px;
      margin-left: 10px;
    }
    &:hover {
      color: #42A4FF;
      i {
        color: #42A4FF !important;
      }
    }
  }
  .error-border {
    border: 1px solid #FF0000 !important;
    border-radius: 4px;
  }
  .flightNumberField {
    background: #f8f8f8;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333333;

    & fieldset {
      border: none !important;
    }
  }
  .slide-transition-error {
    transition: 0.3s;
    margin-bottom: 20px;
  }
  .is-flight-number {
    & .multiselect__input, .multiselect__tags {
      width: calc(260px - 20px) !important;
    }

    @media screen and (max-width: 475px) {
      & .multiselect__input, .multiselect__tags {
        width: 100% !important;
      }
    }
  }
  .waypoint-select {
    & .multiselect__input {
      width: calc(450px - 40px) !important;
    }
  }
  .address-select {
    .multiselect__single {
      margin-top: 5px;
    }

    .multiselect__input, .multiselect__single, .multiselect__option {
      background: initial !important;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      color: #333333;
      margin-bottom: 0px !important;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .multiselect__placeholder {
      background: initial !important;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      margin-bottom: 0px;
    }

    .multiselect__element:hover {
      background-color: #47d061 !important;

      & .multiselect__option {
        color: white !important;
      }
    }

    .multiselect__content-wrapper {
      margin-top: 1px !important;
    }

    .multiselect__tags {
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      padding: 0px 8px 0 8px;

      display: flex;
      align-content: center;
      align-items: center;
      height: 45px;
    }

    .multiselect__content {
      padding-left: 0px;
    }
  }
}
.v-timeline {
  padding-top: 0px;
}

.v-timeline::before {
  top: 40px;
  height: var(--before-height, 63px);
}

.v-timeline-item {
  height: auto !important;
}

.v-timeline-item__body {
  display: flex;
  align-items: center;
  align-content: center;
}

.v-timeline--dense .v-timeline-item {
  height: 64px;
  padding: 0px;
}

.v-timeline-item__divider {
  justify-content: flex-start;
  min-width: auto !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: 6% !important;
}

.v-timeline--dense .v-timeline-item__body {
  max-width: 100%;
  margin-left: 10px;
}
</style>
