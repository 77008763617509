<template>
  <v-row row wrap class="ma-0 pa-0 mobile-column-reverse">
    <ride-tracking-summary :booking="booking" title="rideTracking.summary.completed" :hide-status="true"></ride-tracking-summary>
    <booking-rating :booking="booking" v-if="canRateBooking"></booking-rating>
  </v-row>
</template>

<script>
import BookingRating from '../../bookingRating/BookingRating.vue';
import RideTrackingSummary from '../shared/RideTrackingSummary.vue';

export default {
  name: 'ride-tracking-completed',
  components: {
    BookingRating,
    RideTrackingSummary,
  },
  props: {
    booking: Object,
  },
  data() {
    return {
      screenName: 'driver_on_the_way_to_destination',
    };
  },
  mounted() {
    this.$emit('suggestedBookingRefreshInterval', 0);
  },
  created() {
    this.$emit('trackByStatusPageView', { screenName: this.screenName, actionName: 'complete_ride_confirmation_dialog_viewed' });
  },
  computed: {
    canRateBooking() {
      if (this.booking) return true;
      return false;
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
