<template>
  <v-row row wrap class="ma-0 pa-0 mobile-column-reverse">
    <ride-tracking-summary :booking="booking" title="rideTracking.summary.cancelled" :cancelled="true" :hide-status="true"></ride-tracking-summary>
  </v-row>
</template>

<script>
import RideTrackingSummary from '../shared/RideTrackingSummary.vue';

export default {
  name: 'ride-tracking-cancelled',
  components: {
    RideTrackingSummary,
  },
  data() {
    return {
      screenName: 'ride_cancelled',
    };
  },
  props: {
    booking: Object,
  },

  mounted() {
    this.$emit('suggestedBookingRefreshInterval', 0);
  },
  created() {
    this.$emit('trackByStatusPageView', { screenName: this.screenName, actionName: 'ride_cancelled_viewed' });
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
</style>
