import { mapGetters } from 'vuex';
import { AllowedPaymentMethodDelegate } from '../core/model/bookingChannel/allowedPaymentMethodDelegate';

// eslint-disable-next-line import/prefer-default-export
export const paymentMethods = {
  data: () => ({
    data: [],
    entitySearchObject: {
      page: 0,
      maxPerPage: 10,
      sort: 'name',
    },
    isLoading: false,
    totalCount: 0,
    stripeClient: null,
  }),
  computed: {
    ...mapGetters({
      loadStripeClient: 'paymentMethods/stripeClient',
      allowedPaymentMethods: 'bookingChannel/allowedPaymentMethods',
    }),
    isCreditCardPaymentMethodAllowed() {
      return AllowedPaymentMethodDelegate.isCreditCardPaymentMethodAllowed({
        allowedPaymentMethods: this.allowedPaymentMethods,
      });
    },
    formatPaymentMethodsTableList() {
      return this.data.map(card => ({
        ...card,
        ...card.stripePaymentMethod,
        id: card.id || card.paymentMethodType,
      }));
    },
  },
  watch: {
    data() {
      this.isLoading = false;
    },
  },
  created() {
    this.initStripe();
  },
  methods: {
    async getPaymentMethods() {
      this.methodLoading = true;
      const data = await this.$store.dispatch('paymentMethods/getPaymentMethods');
      if (data) {

        this.data = AllowedPaymentMethodDelegate.resolveAllowedPaymentMethods({
          allowedPaymentMethods: this.allowedPaymentMethods,
          paymentMethods: data,
        });

        this.totalCount = data.length;
      }
    },
    async saveCard(options) {
      return this.$store.dispatch('paymentMethods/storeCard', options);
    },
    async getSecret(corporationId) {
      return this.$store.dispatch('paymentMethods/retrieveSecret', corporationId);
    },
    async deleteCard(id) {
      return this.$store.dispatch('paymentMethods/deleteCard', { id });
    },
    async initStripe() {
      this.stripeClient = await this.loadStripeClient;
    },
  },
};
