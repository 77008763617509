// eslint-disable-next-line import/no-cycle
import { handleAuthentication } from '../authentication';
// eslint-disable-next-line import/no-cycle
import { handleOnBoarding } from '../onBoarding';

export async function routeGuardWithOnBoarding(to, from, next) {
  await handleAuthentication(async () => {
    await handleOnBoarding(next);
  });
}

export async function routeGuardWithoutOnBoarding(to, from, next) {
  await handleAuthentication(next);
}
