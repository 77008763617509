const FREQUENCY_ERROR_CODE = Object.freeze('BOOKING-COMMAND-60');
const DATE_TIME_ERROR_CODE = Object.freeze('BOOKING-COMMAND-61');
const MAX_AMOUNT_ERROR_CODE = Object.freeze('BOOKING-COMMAND-62');
const LOCATION_ERROR_CODE = Object.freeze('BOOKING-COMMAND-63');
const ZONE_ERROR_CODE = Object.freeze('BOOKING-COMMAND-56');

const ERROR_CODES = [FREQUENCY_ERROR_CODE, DATE_TIME_ERROR_CODE, MAX_AMOUNT_ERROR_CODE, LOCATION_ERROR_CODE, ZONE_ERROR_CODE];

export const checkForTravelRestrictionError = error => !!ERROR_CODES.find(code => error === code); // eslint-disable-line import/prefer-default-export

export const getRuleTypeFromError = (error) => {
  switch (error.code) {
    case FREQUENCY_ERROR_CODE:
      return 'frequency';
    case DATE_TIME_ERROR_CODE:
      return 'dateTime';
    case MAX_AMOUNT_ERROR_CODE:
      return 'maxAmount';
    case LOCATION_ERROR_CODE:
      return 'location';
    case ZONE_ERROR_CODE:
      return 'zone';
    default:
      throw new Error('Unknown travel restriction type');
  }
};
