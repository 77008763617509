<template>
  <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="46" height="32" rx="6" fill="white"/>
    <path d="M33.7636 3.19995H12.2603C5.54049 3.19995 0.0424805 9.04822 0.0424805 16.1961C0.0424805 23.344 5.54049 29.1922 12.2603 29.1922H33.7636C40.4834 29.1922 45.9814 23.344 45.9814 16.1961C45.9814 9.04822 40.4834 3.19995 33.7636 3.19995Z" fill="white"/>
    <path d="M33.7636 4.25264C35.2725 4.25264 36.7386 4.57104 38.1192 5.19486C39.4571 5.79918 40.6544 6.66342 41.6929 7.7616C42.7253 8.85977 43.5378 10.1399 44.106 11.563C44.6924 13.0315 44.9918 14.5911 44.9918 16.1961C44.9918 17.8011 44.6924 19.3607 44.106 20.8292C43.5378 22.2523 42.7253 23.5259 41.6929 24.6306C40.6605 25.7288 39.4571 26.593 38.1192 27.1973C36.7386 27.8212 35.2725 28.1396 33.7636 28.1396H12.2603C10.7514 28.1396 9.28524 27.8212 7.90463 27.1973C6.56678 26.593 5.36944 25.7288 4.33093 24.6306C3.29852 23.5324 2.48604 22.2523 1.91791 20.8292C1.33146 19.3607 1.03212 17.8011 1.03212 16.1961C1.03212 14.5911 1.33146 13.0315 1.91791 11.563C2.48604 10.1399 3.29852 8.86627 4.33093 7.7616C5.36333 6.66342 6.56678 5.79918 7.90463 5.19486C9.28524 4.57104 10.7514 4.25264 12.2603 4.25264H33.7636ZM33.7636 3.19995H12.2603C5.54049 3.19995 0.0424805 9.04822 0.0424805 16.1961C0.0424805 23.344 5.54049 29.1922 12.2603 29.1922H33.7636C40.4834 29.1922 45.9814 23.344 45.9814 16.1961C45.9814 9.04822 40.4834 3.19995 33.7636 3.19995Z" fill="#3C4043"/>
    <path d="M21.9488 17.1188V21.0502H20.7759V11.342H23.8853C24.6734 11.342 25.3453 11.6215 25.8951 12.1803C26.4572 12.7391 26.7382 13.4214 26.7382 14.2272C26.7382 15.0524 26.4572 15.7347 25.8951 16.2871C25.3514 16.8394 24.6795 17.1123 23.8853 17.1123H21.9488V17.1188ZM21.9488 12.5377V15.9232H23.9097C24.374 15.9232 24.765 15.7542 25.0704 15.4228C25.382 15.0914 25.5408 14.6885 25.5408 14.2337C25.5408 13.7853 25.382 13.3889 25.0704 13.0575C24.765 12.7131 24.3801 12.5442 23.9097 12.5442H21.9488V12.5377Z" fill="#3C4043"/>
    <path d="M29.8046 14.1882C30.6721 14.1882 31.3563 14.4352 31.8572 14.929C32.3582 15.4229 32.6086 16.0987 32.6086 16.9564V21.0502H31.4907V20.1275H31.4418C30.9592 20.8877 30.3117 21.2646 29.5053 21.2646C28.815 21.2646 28.2408 21.0502 27.7765 20.6148C27.3122 20.1795 27.0801 19.6401 27.0801 18.9903C27.0801 18.3015 27.3244 17.7557 27.8131 17.3528C28.3019 16.9434 28.9555 16.742 29.768 16.742C30.4644 16.742 31.0386 16.8784 31.4846 17.1514V16.8654C31.4846 16.4301 31.3258 16.0662 31.002 15.7608C30.6782 15.4554 30.2995 15.3059 29.8657 15.3059C29.2121 15.3059 28.6928 15.5983 28.3141 16.1896L27.2817 15.5008C27.8498 14.6236 28.6928 14.1882 29.8046 14.1882ZM28.2896 19.0098C28.2896 19.3347 28.4179 19.6076 28.6806 19.8221C28.9372 20.0365 29.2426 20.147 29.5908 20.147C30.0857 20.147 30.5255 19.952 30.9104 19.5621C31.2952 19.1723 31.4907 18.7174 31.4907 18.191C31.1242 17.8856 30.6171 17.7297 29.9635 17.7297C29.487 17.7297 29.0899 17.8531 28.7722 18.0936C28.4485 18.347 28.2896 18.6524 28.2896 19.0098Z" fill="#3C4043"/>
    <path d="M38.9865 14.4026L35.0768 23.9678H33.8672L35.3211 20.6212L32.7432 14.4026H34.0199L35.877 19.1722H35.9015L37.7097 14.4026H38.9865Z" fill="#3C4043"/>
    <path d="M17.2832 16.3261C17.2832 15.9193 17.249 15.5301 17.1855 15.1558H12.269V17.3001L15.1005 17.3008C14.9857 18.0143 14.6161 18.6225 14.0498 19.028V20.4192H15.7352C16.7194 19.4503 17.2832 18.0182 17.2832 16.3261Z" fill="#4285F4"/>
    <path d="M14.0504 19.0279C13.5812 19.3645 12.977 19.5614 12.2702 19.5614C10.9049 19.5614 9.74665 18.5828 9.33185 17.2637H7.59326V18.6984C8.45462 20.5166 10.225 21.7642 12.2702 21.7642C13.6838 21.7642 14.8714 21.2697 15.7358 20.4185L14.0504 19.0279Z" fill="#34A853"/>
    <path d="M9.16851 16.1993C9.16851 15.8289 9.22654 15.4708 9.33223 15.1342V13.6995H7.59363C7.23748 14.4513 7.03711 15.2999 7.03711 16.1993C7.03711 17.0986 7.23809 17.9473 7.59363 18.6991L9.33223 17.2643C9.22654 16.9277 9.16851 16.5697 9.16851 16.1993Z" fill="#FABB05"/>
    <path d="M12.2702 12.8366C13.0418 12.8366 13.7327 13.1193 14.2782 13.6716L15.7719 12.0842C14.8647 11.1855 13.682 10.6338 12.2702 10.6338C10.2256 10.6338 8.45462 11.8814 7.59326 13.6996L9.33185 15.1344C9.74665 13.8152 10.9049 12.8366 12.2702 12.8366Z" fill="#E94235"/>
  </svg>

</template>

<script>
export default {
  name: 'iconGoogle',
};
</script>

<style scoped>

</style>
