<template>
  <loader :is-loading="bookingInCreation" color="primary" :full-page="true">
    <v-row row wrap  class="ma-0 pa-0 w-full">
      <booking-map :key="recreate" />
      <booking-menu
        :newPaymentMethodId ="paymentId"
        :loadedBooking="bookingDetail"
        :returnBookingTime="returnBookingTime"
        :returnFlightNumber="returnFlightNumber"
        :returnBookingCheckbox="returnBookingCheckbox"
        :state="bookingState"
        @cancelUpdate="goToPreBookingList"
        @addCard="callCreateCard"
        @bookingPaymentActionRequired="callBookingPaymentActions"
        @bookReturnBooking="openReturnBookingDialog"
      />
    </v-row>

    <dialog-wrapper ref="addDialog" :width="400">
      <template v-slot:content>
        <add-card
          @closeDialog="closeCardDialog"
          @methodAdded="setPaymentId"
          :key="recreate"
        />
      </template>
    </dialog-wrapper>
    <dialog-wrapper ref="bookingPaymentActions" :width="800">
      <template v-slot:content>
        <v-flex>
          <v-card flat class="pa-5">
            <v-card-title>
              <div class="d-flex justify-space-between w-full">
                <span class="pb-2 form-title">{{$t('bookingPaymentActionRequired.title')}}</span>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <p>{{$t('bookingPaymentActionRequired.explanations')}}, <router-link to="/bookings/payment-action">{{$t('bookingPaymentActionRequired.review')}}</router-link></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </template>
    </dialog-wrapper>
    <BookingFormReturnBooking ref="returnBookingDialog" @returnBooking="setReturnBooking" @cancelReturnBooking="toggleReturnCheckbox" />
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import { executeSilentRefreshToken } from '../../authentication/authenticationManager';
import BookingMenu from './BookingMenu.vue';
import BookingMap from './BookingMap.vue';
import DialogWrapper from '../../components/elements/DialogWrapper.vue';
import AddCard from '../paymentMethods/AddCard.vue';
import BookingFormReturnBooking from './form/BookingFormReturnBooking.vue';
import BookingPaymentActionRequired from '../bookings/BookingPaymentActionRequired.vue';


export default {
  name: 'book',
  components: {
    BookingPaymentActionRequired,
    BookingMenu,
    BookingMap,
    DialogWrapper,
    AddCard,
    BookingFormReturnBooking,
  },
  data() {
    return {
      recreate: 0,
      paymentId: null,
      isNewBooking: false,
      returnBookingTime: null,
      returnFlightNumber: null,
      returnBookingCheckbox: false,
      bookingsRequirePaymentAction: [],
    };
  },
  async created() {
    const location = await this.getUserLocation();
    if (location) {
      this.$store.dispatch('geolocation/setUserCoordinates', { coordinates: location });
      if (this.isNewBooking) {
        this.$store.dispatch('geolocation/geocode', { lang: this.$i18n.locale, location });
      }
    }
    executeSilentRefreshToken(process.env.VUE_APP_REFRESH_TOKEN_DURATION_BOOK || 600000);
  },
  watch: {
    bookingCreated(newValue) {
      if (newValue.id) {
        this.$router.push({
          path: `/ride-tracking/${newValue.id}`,
        });
      }
    },
    bookingUpdated() {
      this.goToPreBookingList();
    },
  },
  computed: {
    ...mapGetters({
      bookingInCreation: 'book/getBookingInCreation',
      bookingCreated: 'book/getBookingCreated',
      bookingUpdated: 'book/getBookingUpdated',
      bookingDetail: 'bookings/bookingDetail',
      bookingTab: 'bookings/bookingTab',
    }),
    bookingState() {
      const state = get(this.$route, 'meta.state');
      if (state) {
        return this.$route.meta.state;
      }
      this.isNewBooking = true;
      return this.state;
      // eslint-disable-next-line consistent-return
    },
  },
  async mounted() {
    await this.loadBooking(this.$route.params.id);
  },
  methods: {
    goToPreBookingList() {
      this.$router.push({
        name: 'bookings',
        params: {
          tab: this.bookingTab,
        },
      });
    },
    async loadBooking(id) {
      if (id) {
        await this.$store.dispatch(
          'bookings/getBookingDetail',
          { id }
        );
      }
    },
    async callCreateCard() {
      this.$refs.addDialog.openDialog();
    },
    async callBookingPaymentActions(bookingsRequirePaymentAction) {
      this.bookingsRequirePaymentAction = bookingsRequirePaymentAction;
      this.$refs.bookingPaymentActions.openDialog();
    },
    closeCardDialog() {
      this.recreate += 1;
      this.$refs.addDialog.close();
    },
    setPaymentId(data) {
      this.paymentId = data.paymentId;
    },
    setReturnBooking(pickupTime, returnFlightNumber) {
      this.returnBookingTime = pickupTime;
      this.returnFlightNumber = returnFlightNumber;
    },
    openReturnBookingDialog(attrs) {
      this.returnBookingCheckbox = true;
      this.$refs.returnBookingDialog.openDialog(attrs);
    },
    toggleReturnCheckbox() {
      this.returnBookingCheckbox = false;
    },
    async getUserLocation() {
      try {
        const location = await this.$getLocation({ enableHighAccuracy: false });
        return { latitude: location.lat, longitude: location.lng };
      } catch (error) {
        return null;
      }
    },
  },
};
</script>
<style scoped>

</style>
