import { minLength, maxLength, email, required } from 'vuelidate/lib/validators';
import { isValidString } from './validationMethods';

export default {
  userProfile: {
    familyName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(120),
      isValidString,
    },
    givenName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(120),
      isValidString,
    },
    email: {
      required,
      email,
      minLength: minLength(5),
      maxLength: maxLength(120),
    },
    communicationLanguage: {
      required,
    },
  },
};
