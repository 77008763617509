<template>
  <v-col cols="12" class="ride-tracking-map-container pa-0">
    <component
      ref="map"
      v-bind:is="mapComponentName"
      :pickup="pickup"
      :destination="destination"
      :driver-position="driverPosition"
      :taxi-icon-url="taxiIconUrl"
      :show-markers="showMarkers"
    ></component>
  </v-col>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { BookingHelper } from '@/views/sharedRideTracking/shared/bookingHelper';

export default {
  name: 'ride-tracking-map',
  components: {
    GoogleMap: () => import('./GoogleMap.vue'),
  },
  props: {
    booking: Object,
  },
  computed: {
    ...mapGetters({
      accessToken: 'bookingChannel/mapAccessToken',
    }),
    mapComponentName() {
      Vue.use(VueGoogleMaps, {
        load: {
          key: this.accessToken,
          libraries: 'geometry',
        },
      });
      return 'GoogleMap';
    },
    pickup() {
      return this.booking.origin;
    },
    destination() {
      return this.booking.destination;
    },
    driverPosition() {
      const latitude = _.get(this.booking, 'driver.currentPosition.latitude');
      const longitude = _.get(this.booking, 'driver.currentPosition.longitude');
      if (latitude && longitude) {
        return {
          latitude,
          longitude,
        };
      }
      return null;
    },
    taxiIconUrl() {
      return _.get(this.booking, 'offer.properties.mapIconUrl', '/img/pin-taxi.svg');
    },
    showMarkers() {
      return !BookingHelper.isBookingCancelled(this.booking);
    },
  },
};
</script>


<style lang="scss" scoped>

.ride-tracking-map-container {
  height: 100%;
  min-height: 30vh;
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .ride-tracking-map-container {
    padding: 0;
  }
}
</style>
