import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      acrValue: 'authentication/acrValue',
    }),
    userLoginType() {
      switch (this.acrValue) {
        case 'urn:icabbi:otp:email':
          return 'businessAccount';
        case 'urn:icabbi:ic2:account:password':
          return 'ic2Account';
        default:
          return 'passenger';
      }
    },
  },
};
