<template>
  <v-row row wrap>
    <booking-rating-confirmation v-if="confirmationDisplayed"></booking-rating-confirmation>
    <booking-rating-form :booking="booking" v-if="!isRated"></booking-rating-form>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingRatingConfirmation from './BookingRatingConfirmation.vue';
import BookingRatingForm from './BookingRatingForm.vue';

export default {
  name: 'booking-rating',
  props: {
    booking: Object,
    required: true,
  },
  data() {
    return {
      confirmationDisplayed: false,
    };
  },
  components: {
    BookingRatingConfirmation,
    BookingRatingForm,
  },
  mounted() {
    this.$store.dispatch('rating/getBookingRatings', { bookingId: this.booking.id });
  },
  computed: {
    ...mapGetters({
      ratingBookingCreated: 'rating/getBookingRatingCreated',
      bookingRatings: 'rating/getBookingRatings',
    }),
    isRated() {
      if (this.bookingRatings && this.bookingRatings.filter(rating => rating.userId === this.booking.userId).length > 0) return true;
      return this.ratingBookingCreated;
    },
  },
  watch: {
    ratingBookingCreated(newValue) {
      if (newValue === true) this.confirmationDisplayed = true;
    },
  },
};
</script>

<style scoped>
</style>
