<template>
  <component :is="componentName" :is-item-selected="isItemSelected">
    <template v-slot:detail-content>
      <slot name="detail-content"></slot>
    </template>
  </component>
</template>

<script>

import DesktopDetail from './DesktopDetail.vue';
import MobileDetail from './MobileDetail.vue';

export default {
  name: 'Detail',
  components: {
    DesktopDetail,
    MobileDetail,
  },
  props: {
    isItemSelected: {
      type: Boolean,
    },
  },
  computed: {
    componentName() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'mobile-detail';
        case 'sm': return 'mobile-detail';
        case 'md': return 'desktop-detail';
        case 'lg': return 'desktop-detail';
        case 'xl': return 'desktop-detail';
        default: return 'mobile-detail';
      }
    },
  },
};
</script>

<style scoped>

</style>
