import { mapGetters } from 'vuex';

const SOURCE_GOOGLE = 'google';
const SOURCE_ICABBI = 'icabbi';

export const addressPicker = {
  data: () => ({
    suggestions: [],
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      pickupDetails: 'geolocation/pickup',
      destinationDetails: 'geolocation/destination',
      waypointsDetails: 'geolocation/waypoints',
      suggestionsState: 'geolocation/suggestions',
      sourceState: 'geolocation/source',
      userCoordinates: 'geolocation/userCoordinates',
    }),
  },
  methods: {
    async getSuggestions(search) {
      this.isLoading = true;

      const result = await this.$store.dispatch('geolocation/getSuggestions', {
        search,
        type: this.model,
        lang: this.$i18n.locale,
        latitude: this.userCoordinates.latitude,
        longitude: this.userCoordinates.longitude,
        index: this.index,
      });
      this.isLoading = false;

      return result;
    },
    getCoordinates(address) {
      if (address.detail) {
        return {
          description: address.description,
          latitude: address.detail.coordinates.latitude,
          longitude: address.detail.coordinates.longitude,
          dispatchAddressId: address.dispatchAddressId || undefined,
          locationTypes: address.locationTypes,
        };
      }

      if (address.coordinates) {
        return {
          description: address.description,
          latitude: address.coordinates.latitude,
          longitude: address.coordinates.longitude,
          dispatchAddressId: address.dispatchAddressId || undefined,
          locationTypes: address.locationTypes,
        };
      }

      return {
        description: address.description,
        dispatchAddressId: address.dispatchAddressId || undefined,
        latitude: null,
        longitude: null,
      };
    },
    async getAddressDetails(val, type, index) {
      switch (this.sourceState) {
        case SOURCE_GOOGLE:
          // eslint-disable-next-line no-param-reassign
          val.detail = await this.$store.dispatch('geolocation/getSuggestionDetail', {
            address: val,
            type,
            lang: this.$i18n.locale,
            index,
          });
          break;
        case SOURCE_ICABBI:
        default:
          // eslint-disable-next-line no-param-reassign
          val.detail = await this.$store.dispatch('geolocation/getSuggestionDetail', {
            address: val,
            lang: this.$i18n.locale,
            type,
            index,
          });
          break;
      }

      if (val) {
        switch (type) {
          case 'pickup':
            this.$store.dispatch('driverStates/getDrivers', {
              latitude: val.detail.coordinates.latitude,
              longitude: val.detail.coordinates.longitude,
              limit: 10,
              status: 'available',
            });
            this.$emit('updateAddresses', 'pickup', this.getCoordinates(val));
            break;
          case 'destination':
            return this.$emit('updateAddresses', 'destination', this.getCoordinates(val));
          case 'waypoints':
            this.$forceUpdate();
            this.$nextTick(() => {
              if (index === this.waypoints.length - 1) {
                this.$refs.destination.$el.focus();
              } else if (index < this.waypoints.length - 1) {
                this.$refs[`waypoint${index + 1}`][0].$el.focus();
              }
            });

            return this.$emit('updateAddresses', 'waypoints', this.getCoordinates(val), index);
          default:
            break;
        }
      }

      return true;
    },

  },
};

export default {
  addressPicker,
};
