import { FareEstimatorClient } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '../../helpers/apiHelpers';

const defaultState = {
  fareEstimationConnector: null,
};

const getters = {
  // eslint-disable-next-line no-shadow
  fareEstimationConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.fareEstimationConnector) {
      state.fareEstimationConnector = apiInstance({ dispatch, getters: rootGetters, Connector: FareEstimatorClient });
    }
    return state.fareEstimationConnector;
  },
};

const actions = {
  getFareEstimation: apiCall((context, params) => context.getters.fareEstimationConnector(context).getFareEstimation(params.body, params.id, params.paymentMethodType)),
};

export default {
  namespaced: true,
  getters,
  actions,
  state: defaultState,
};
