<template>
  <v-dialog
    fullscreen
    v-model="isItemSelected"
    hide-overlay transition="slide-x-transition">
    <v-row class="mobile-detail-data" justify="center">
      <v-col cols="12" sm="12" class="px-0 pt-0 pb-0">
        <slot name="detail-content"></slot>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: 'MobileDetail',
  props: {
    isItemSelected: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
  .mobile-detail-data {
    margin-top: 56px;
    height: 100%;
    background-color: white;
    margin-right: 0;
    margin-left: 0;
  }
</style>
