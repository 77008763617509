<template>
  <loader
    :is-loading="false"
    color="primary">
    <payment-methods-table
      :isLoading="isLoading"
      :totalCards="totalCount"
      :cards="formatPaymentMethodsTableList"
      @callLoadData="loadData"
      @addCard="$refs.modalDialog.openDialog()"
      @deleteCard="deleteCard"
    ></payment-methods-table>

    <dialog-wrapper ref="modalDialog" :width="400" @closeDialog="removeCard">
      <template v-slot:content>
        <add-card
          @closeDialog="closeCardDialog"
          @methodAdded="loadData"
          :key="recreate"
        />
      </template>
    </dialog-wrapper>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';
import { paymentMethods } from '../../mixins/paymentMethod.mixins';
import PaymentMethodsTable from './PaymentMethodsTable.vue';
import DialogWrapper from '../../components/elements/DialogWrapper.vue';
import AddCard from './AddCard.vue';

export default {
  name: 'payment-method-list-view',
  mixins: [paymentMethods, creditCardIcon],
  components: {
    PaymentMethodsTable,
    AddCard,
    DialogWrapper,
  },
  data() {
    return {
      recreate: 0,
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters('bookingChannel', ['payment']),
  },
  methods: {
    loadData() {
      this.getPaymentMethods();
    },
    async deleteCard(card) {
      this.dialogWidth = 400;
      this.$refs.modalDialog.openDialog({ type: 'delete-payment-method', id: card.id });
    },
    async removeCard(data) {
      this.data = this.data.filter(method => method.id !== data.id);
    },
    closeCardDialog() {
      this.recreate += 1;
      this.$refs.modalDialog.close();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "selectCorporation": "Please select an account",
    "missedCorporation": "Missed account"
  }
}
</i18n>
