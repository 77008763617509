<template>
  <div style="position: relative">
    <div style="width: 100%; height: 100%; text-align: center; display: flex; align-items: center; justify-content: center;">
      <svg viewBox="0 0 32 32" style="border-radius: 50%; width: 100%; height: 100%;">
        <circle r="16" cx="16" cy="16" fill="#F8F8F8" stroke="#EEEEEE" stroke-width="0.6" />
        <circle r="14" cx="16" cy="16" :fill="primaryColor"/>
        <circle r="12" cx="16" cy="16" fill="#dedede"/>
        <circle r="11.5" cx="16" cy="16" fill="#dedede" stroke="white" stroke-width="1" stroke-dasharray="50 73" id="loader_circle"/>
        <circle r="11" cx="16" cy="16" :fill="primaryColor"/>
      </svg>
    </div>
    <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; display: flex; align-items: center; justify-content: center">
      <div v-if="eta" class="text-center" :style="{color: primaryFontColor}">
        <div style="font-weight: 600; font-size: 26px;">{{ eta }}</div>
        <div style="font-size: 13px">{{ $t("publicRideTracking.minutes") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    eta: Number,
  },
  computed: {
    ...mapGetters({
      primaryColor: 'bookingChannel/primaryColor',
      primaryFontColor: 'bookingChannel/primaryFontColor',
    }),
  },
};
</script>

<style scoped>
@keyframes circular_loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#loader_circle{
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;

  -webkit-animation: circular_loading 1000ms ease infinite;
  -ms-animation: circular_loading 1000ms ease infinite;
  animation: circular_loading 1000ms ease infinite;
}
</style>
