import { business } from '@icabbi/api-gateway-client';
import apiInstance, { apiCall } from '@/helpers/apiHelpers';

const defaultState = {
  driversServiceConnector: null,
  drivers: [],
};

const storeGetters = {
  driversServiceConnector: (state, getters, rootState, rootGetters) => ({
    dispatch,
  }) => {
    if (!state.driversServiceConnector) {
      state.driversServiceConnector = apiInstance({
        dispatch,
        getters: rootGetters,
        Connector: business.DriverStatesCommandConnector,
      });
    }

    return state.driversServiceConnector;
  },
  availableDrivers: state => state.drivers.data,
};

const actions = {
  getDrivers: apiCall(async (context,
    payload) => {
    const driversServiceConnector = context.getters.driversServiceConnector(context);
    const result = await driversServiceConnector.requestDriverStates(payload);
    context.commit('setDrivers', result);
    return result;
  }),
};

const mutations = {
  setDrivers(state, drivers) {
    state.drivers = drivers;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: storeGetters,
  actions,
  mutations,
};
