import assert from 'assert';
import { Authentication } from '@icabbi/vue-authentication';

const apiInstance = ({ dispatch, getters, Connector }) => {
  assert(dispatch, 'dispatch is required');
  assert(getters, 'getters is required');
  assert(Connector, 'Connector is required');
  const authentication = Authentication({ dispatch, getters });
  const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
  const serviceConnector = new Connector({
    baseURL: process.env.VUE_APP_API_GATEWAY_URL,
    timeout: parseInt(process.env.VUE_APP_HTTP_REQUEST_TIMEOUT, 10),
  });
  serviceConnector.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
  serviceConnector.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));
  return serviceConnector;
};

export const apiCall = call => async (context, params) => {
  try {
    const result = await call(context, params);
    return result === '' ? true : result;
  } catch (error) {
    return false;
  }
};

export default apiInstance;
