// eslint-disable-next-line import/no-cycle
import { getBearer, executeSilentRefreshToken } from './authenticationManager';
// eslint-disable-next-line import/no-cycle
import store from '../store';

export async function authenticationFailureInterceptor(error) {
  if (error.response && error.response.status === 401) {
    await store.dispatch('authentication/clearToken');
    window.location.replace(`/?tokenExpired=true&from=${window.location.pathname}`);
    return;
  }
  throw error;
}

export async function bearerTokenInterceptor(config) {
  await executeSilentRefreshToken(5 * 60 * 1000);
  // eslint-disable-next-line no-param-reassign
  config.headers = Object.assign({}, config.headers, { Authorization: getBearer() });
  return config;
}
