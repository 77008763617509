<template>
  <v-select
    :items="countries"
    :label="label"
    :placeholder="placeholder"
    @input="onInput"
    item-text="name"
    item-value="code"
  ></v-select>
</template>
<script>
const isoCountries = require('i18n-iso-countries');
isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
isoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

export default {
  name: 'country-picker',
  props: {
    label: String,
    placeholder: String,
    value: String,
  },
  data: () => ({
    countries: [],
  }),
  created() {
    const language = this.$i18n.locale.substr(0, 2);
    const names = isoCountries.getNames(language);
    for (const c in names) { // eslint-disable-line
      this.countries.push({ code: c, name: names[c] });
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
  },
};
</script>
