<template>
  <v-flex class="version-tag" :class="{ 'text-right': !isMobile, 'text-center': isMobile, 'mobile': isMobile }">
    {{ $t('about.poweredBy') }} - {{ $t('about.version') }} {{ versionNumber }}
  </v-flex>
</template>

<script>
import { isMobile } from '../views/shared/mixins/responsive.mixin';

export default {
  name: 'version',
  mixins: [isMobile],
  data() {
    return {
      versionNumber: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
<style lang="scss" scoped>
  .version-tag {
    color: #919191;
    font-size: 12px;
    margin-top: -20px;
    padding-right: 15px;
    text-align: right;

    &.mobile {
      text-align: center;
    }
  }
</style>
