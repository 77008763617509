<template>
    <v-row row wrap class="ma-0 pa-0 ride-tracking-container">
      <loader :is-loading="isLoading" color="primary" :full-page="true">
        <component
          v-bind:is="componentName"
          :booking="booking"
          v-on:suggestedBookingRefreshInterval="onRefreshIntervalReceived"
        ></component>
      </loader>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import RideTrackingBookingDispatchRequested from './status/RideTrackingBookingDispatchRequested.vue';
import RideTrackingBookingCancelled from './status/RideTrackingBookingCancelled.vue';
import RideTrackingEnroute from './status/RideTrackingEnroute.vue';
import RideTrackingDroppingoff from './status/RideTrackingDroppingoff.vue';
import RideTrackingCompleted from './status/RideTrackingCompleted.vue';
import RideTrackingDefault from './status/RideTrackingDefault.vue';
import RideTrackingCancelled from './status/RideTrackingCancelled.vue';
import { executeSilentRefreshToken } from '../../authentication/authenticationManager';

export default {
  name: 'ride-tracking',
  components: {
    RideTrackingDefault,
    RideTrackingBookingDispatchRequested,
    RideTrackingBookingCreated: RideTrackingBookingDispatchRequested,
    RideTrackingNew: RideTrackingBookingDispatchRequested,
    RideTrackingBookingDispatchCancelled: RideTrackingBookingCancelled,
    RideTrackingDispatchCancelled: RideTrackingBookingCancelled,
    RideTrackingEnroute,
    RideTrackingArrived: RideTrackingEnroute,
    RideTrackingDroppingoff,
    RideTrackingNoshow: RideTrackingBookingCancelled,
    RideTrackingCustomerCancelled: RideTrackingBookingCancelled,
    RideTrackingCompleted,
    RideTrackingCancelled,
    RideTrackingComplete: RideTrackingCompleted,
  },
  created() {
    if (this.bookingId) {
      this.$store.dispatch('rideTracking/startGetBooking', {
        bookingId: this.bookingId,
        bookingChannelId: this.bookingChannelId,
        firstLoading: true,
      });
    }
    executeSilentRefreshToken(process.env.VUE_APP_REFRESH_TOKEN_DURATION_RIDE_TRACKING || 600000);
  },
  destroyed() {
    this.$store.dispatch('rideTracking/stopGetBooking');
  },
  computed: {
    ...mapGetters({
      bookingChannelId: 'bookingChannel/bookingChannelId',
      getBooking: 'rideTracking/getBooking',
      isBookingFirstLoadingInProgress: 'rideTracking/getBookingFirstLoadInProgress',
      isBookingInCancellation: 'book/getBookingInCancellation',
      cancelledBookingIds: 'book/getCancelledBookingIds',
      getBookingCreated: 'book/getBookingCreated',
    }),
    booking() {
      if (this.getBookingCreated && !this.getBooking) return this.getBookingCreated;
      return this.getBooking;
    },
    bookingId() {
      return this.$route.params.bookingId;
    },
    componentName() {
      if (this.cancelledBookingIds.includes(this.bookingId)) {
        return 'RideTrackingCancelled';
      }
      return 'RideTrackingBookingDispatchRequested'; // default
    },
    isLoading() {
      return this.isBookingFirstLoadingInProgress || this.isBookingInCancellation;
    },
  },
  methods: {
    bookingStatusToComponentName(bookingStatus) {
      const status = bookingStatus.toLowerCase();
      const camelCaseStatus = status.replace(/(\-\w)|(\_\w)/g, matches => matches[1].toUpperCase()); // eslint-disable-line no-useless-escape

      const name = `RideTracking${camelCaseStatus
        .charAt(0)
        .toUpperCase()}${camelCaseStatus.slice(1)}`;
      if (!this.$options.components[`${name}`]) {
        return 'RideTrackingDefault';
      }

      return name;
    },
    onRefreshIntervalReceived(refreshInterval) {
      if (this.bookingId) {
        this.$store.dispatch('rideTracking/startGetBooking', {
          refreshInterval,
          bookingId: this.bookingId,
          bookingChannelId: this.bookingChannelId,
        });
      }
    },
  },
};
</script>

<style scoped>
.ride-tracking-container {
  height: 100%;
}
</style>
