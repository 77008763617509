<template>
  <v-dialog
    v-model="dialog"
    width="400"
  >
    <template v-slot:activator="{ on }">
      <loader :is-loading="emailVerificationLoading" color="primary" :width="2" :size="19" :margin="0">
        <div id="user-email-verification-popup-message">
          <label class="theme--light">
            {{ $t('userProfile.email.unconfirmed') }}
          </label>
          <a @click="sendVerificationEmail()">{{ $t('userProfile.email.send') }}</a>
        </div>
      </loader>
    </template>
    <v-card class="py-8 px-6">
     <p class="black--text text-h6 mb-4">{{ $t('userProfile.verifyEmailPopup.title') }}</p>

     <v-card-text class="center px-0 py-4">
      <p>{{ $t('userProfile.verifyEmailPopup.message') }}</p>
     </v-card-text>

     <v-card-text class="center pb-0 px-0 d-flex justify-end">
        <v-spacer></v-spacer>
        <v-btn
          color="#333333"
          hide-overlay="true"
          depressed
          class="white--text"
          @click="closeDialog"
        >
          {{ $t('userProfile.verifyEmailPopup.close') }}
        </v-btn>
     </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'user-email-verification-popup',
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
    emailVerificationSent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  validations() {
  },
  components: {
  },
  computed: {
    ...mapGetters({
      emailVerificationLoading: 'userProfile/emailVerificationLoading',
    }),
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.emailVerificationSent = false;
    },
    async sendVerificationEmail() {
      await this.$store.dispatch('userProfile/sendVerificationEmail', this.userProfile);
    },
  },
  watch: {
    emailVerificationSent(val) {
      if (val) this.openDialog();
    },
  },
};
</script>

<style scoped lang="scss">

  #user-email-verification-popup-message {
    padding: 10px;
    background-color: #F9EBEB;
    border-radius: 6px;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    text-transform: capitalize;
    font-size: small;

    display: flex;
    flex-direction: column;

    a {
      cursor: pointer;
      padding-left: 5px;
      text-decoration: none;
      color: #2F80ED;
    }
  }

  #user-email-verification-popup-form {
    margin: auto;
    font-family: Inter;
  }

  #user-email-verification-popup-form-title {
    color: #ffffff;
    background-color: #000000;
    text-transform: uppercase;
    font-size: 14px;
  }

  #user-email-verification-popup-form-title.v-card__title {
    padding: 7px;
  }

  #user-email-verification-popup-form-close {
    padding: 10px;
    width: 100%;
  }
</style>
