import { BookingQueryClient } from '@icabbi/api-gateway-client';

let interval;

const bookingQueryClient = new BookingQueryClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

const defaultState = {
  booking: null,
  bookingFirstLoadInProgress: false,
};

const getters = {
  getBooking: state => state.booking,
  getBookingFirstLoadInProgress: state => state.bookingFirstLoadInProgress,
};

const actions = {
  async getById({ state, commit, dispatch }, { bookingId, token, firstLoading = false }) {
    try {
      commit('setBookingFirstLoadInProgress', firstLoading);
      const booking = await bookingQueryClient.getPubliclySharedBooking({ bookingId, token }, {
        params: {
          incrementVisitCount: firstLoading,
        },
      });
      commit('setBooking', booking);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch('globalError/addError', { message: 'rideTracking.retrievalError' }, { root: true });
      if (state.bookingFirstLoadInProgress) {
        dispatch('stopGetBooking');
      }
    } finally {
      commit('setBookingFirstLoadInProgress', false);
    }
  },
  async startGetBooking({ dispatch, commit }, { refreshInterval, bookingId, token, firstLoading = false }) {
    clearInterval(interval);
    if (firstLoading) {
      commit('setBooking', null);
    }
    if (refreshInterval) {
      dispatch('getById', { bookingId, token, firstLoading });
      interval = setInterval(() => {
        dispatch('getById', { bookingId, token });
      }, refreshInterval);
    }
  },
  async stopGetBooking() {
    clearInterval(interval);
  },
};
const mutations = {
  setBookingFirstLoadInProgress(state, bookingFirstLoadInProgress) {
    state.bookingFirstLoadInProgress = bookingFirstLoadInProgress;
  },
  setBooking(state, booking) {
    state.booking = booking;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
