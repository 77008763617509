import { passenger } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

const defaultState = {
  employeeGroups: [],
  employeeGroupsLoading: false,
  employeeGroupServiceClient: null,
};

const getters = {
  employeeGroups: state => state.employeeGroups,
  employeeGroupsLoading: state => state.employeeGroupsLoading,

  // eslint-disable-next-line no-shadow
  employeeGroupServiceClient: state => () => {
    if (state.employeeGroupServiceClient === null) {
      const employeeGroupServiceClient = new passenger.EmployeeGroupServiceConnector({
        baseURL: process.env.VUE_APP_API_GATEWAY_URL,
        timeout: parseInt(process.env.VUE_APP_HTTP_REQUEST_TIMEOUT, 10),
      });
      employeeGroupServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
      employeeGroupServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));
      state.employeeGroupServiceClient = employeeGroupServiceClient;
    }
    return state.employeeGroupServiceClient;
  },
};

const actions = {
  async listMe(context, { page, maxPerPage, sort = '-createdAt' }) {
    try {
      context.commit('setEmployeeGroupsLoading', true);
      const employeeGroupServiceClient = context.getters.employeeGroupServiceClient(context);
      const { data: employeeGroups } = await employeeGroupServiceClient.getEmployeeGroupsMe({
        sort,
        page,
        maxPerPage,
      });
      context.commit('setEmployeeGroups', employeeGroups || []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.dispatch('globalError/addError', { message: 'employeeGroups.retrievalError' }, { root: true });
    } finally {
      context.commit('setEmployeeGroupsLoading', false);
    }
  },
};

const mutations = {
  setEmployeeGroups(state, employeeGroups) {
    state.employeeGroups = employeeGroups;
  },
  setEmployeeGroupsLoading(state, employeeGroupsLoading) {
    state.employeeGroupsLoading = employeeGroupsLoading;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
