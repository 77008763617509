import { OfferServiceClient } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

const offerServiceClient = new OfferServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

offerServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
offerServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const defaultState = {
  offers: [],
  fleets: [],
  offersLoading: false,
};

const getters = {
  offers: state => state.offers,
  offersLoading: state => state.offersLoading,
};

const actions = {
  async list(context) {
    try {
      context.commit('setOffersLoading', true);
      const { coordinates = {} } = context.rootGetters['geolocation/pickup'];
      const offers = await offerServiceClient.listByEta({
        lang: context.rootGetters['language/selectedLanguage'],
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      });
      context.commit('setOffers', offers);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.dispatch('globalError/addError', { message: 'offers.retrievalError' }, { root: true });
    } finally {
      context.commit('setOffersLoading', false);
    }
  },
  async getOffersList(context, options) {
    try {
      context.commit('setOffersLoading', true);
      const data = await offerServiceClient.offersList(options);
      context.commit('setOffers', data.offers);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', {
        message: error.displayMessage,
        code: error.httpStatusCode,
        title: error.displayTitle,
      }, { root: true });
    } finally {
      context.commit('setOffersLoading', false);
    }
  },
};
const mutations = {
  setOffersLoading(state, offersLoading) {
    state.offersLoading = offersLoading;
  },
  setOffers(state, offers) {
    state.offers = offers;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
