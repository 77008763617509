<template>
  <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" stroke="black"/>
    <path d="M12.588 10.646C12.9819 10.1491 13.2491 9.48187 13.1786 8.80005C12.6021 8.82897 11.8985 9.18374 11.4912 9.68105C11.1254 10.1069 10.8017 10.8021 10.8861 11.4553C11.5333 11.5119 12.1799 11.129 12.588 10.646Z" fill="black"/>
    <path d="M13.1714 11.5829C12.2314 11.5264 11.4323 12.121 10.9834 12.121C10.5343 12.121 9.84694 11.6113 9.10351 11.6251C8.13589 11.6394 7.23805 12.1913 6.74703 13.069C5.73707 14.8248 6.4805 17.4294 7.46263 18.8594C7.93957 19.5669 8.51437 20.3459 9.27176 20.3179C9.98736 20.2896 10.2678 19.8505 11.1377 19.8505C12.0069 19.8505 12.2596 20.3179 13.0171 20.3038C13.8026 20.2896 14.2937 19.5959 14.7707 18.8878C15.3178 18.0813 15.5418 17.3025 15.5559 17.2598C15.5418 17.2456 14.0411 16.6649 14.0272 14.9236C14.013 13.4657 15.2054 12.7722 15.2615 12.7291C14.5882 11.7246 13.5361 11.6113 13.1714 11.5829Z" fill="black"/>
    <path d="M21.3555 9.60962C23.3984 9.60962 24.821 11.0301 24.821 13.0981C24.821 15.1736 23.3692 16.6014 21.3043 16.6014H19.0424V20.2297H17.4082V9.60962H21.3555V9.60962ZM19.0424 15.2177H20.9176C22.3404 15.2177 23.1502 14.445 23.1502 13.1055C23.1502 11.7661 22.3404 11.0007 20.9249 11.0007H19.0424V15.2177Z" fill="black"/>
    <path d="M25.248 18.0292C25.248 16.6749 26.2768 15.8433 28.101 15.7402L30.2022 15.6152V15.0191C30.2022 14.158 29.6257 13.6428 28.6628 13.6428C27.7506 13.6428 27.1815 14.0843 27.043 14.7762H25.5546C25.6421 13.3777 26.824 12.3474 28.7211 12.3474C30.5816 12.3474 31.7708 13.341 31.7708 14.8939V20.2297H30.2605V18.9564H30.2241C29.7791 19.8176 28.8086 20.3621 27.8018 20.3621C26.2988 20.3621 25.248 19.4201 25.248 18.0292ZM30.2022 17.33V16.7192L28.3124 16.8369C27.3712 16.9032 26.8386 17.3226 26.8386 17.985C26.8386 18.662 27.3931 19.1037 28.2395 19.1037C29.3412 19.1037 30.2022 18.3382 30.2022 17.33Z" fill="black"/>
    <path d="M33.1968 23.078V21.79C33.3134 21.8194 33.576 21.8194 33.7074 21.8194C34.437 21.8194 34.831 21.5103 35.0717 20.7155C35.0717 20.7008 35.2105 20.2445 35.2105 20.2371L32.438 12.4873H34.1451L36.0861 18.7873H36.1151L38.0561 12.4873H39.7196L36.8447 20.6345C36.1883 22.5113 35.4294 23.1148 33.8389 23.1148C33.7074 23.1148 33.3134 23.1 33.1968 23.078Z" fill="black"/>
  </svg>

</template>

<script>
export default {
  name: 'iconApple',
};
</script>

<style scoped>

</style>
