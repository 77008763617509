import { CountryLocaleFinder } from '@icabbi/hi-dash';

const defaultState = {
  authCallbackUrl: '',
  allowedPaymentMethods: [],
  colorScheme: {},
  humanReadableId: '',
  id: '',
  oidcClientId: '',
  payment: {},
  privacyPolicyLinks: {},
  termAndConditionLinks: {},
  features: {},
  location: {},
  currencySymbol: undefined,
  communicationLanguage: 'en-uk',
  defaultLocale: 'en-US',
  firebaseWebbookerAnalytics: null,
};

const getters = {
  authCallbackUrl: state => state.authCallbackUrl,
  allowedPaymentMethods: state => state.allowedPaymentMethods,
  bookingChannelId: state => state.id,
  errorColor: state => state.colorScheme.error,
  defaultLocation: state => state.location,
  logo: state => state.logo,
  mapAccessToken: state => state.maps.configuration.accessToken,
  maps: state => state.maps,
  mapStyle: state => state.maps.configuration.mapStyle,
  name: state => state.name,
  oidcClientId: state => state.oidcClientId,
  payment: state => state.payment,
  postLogoutUrl: state => state.postLogoutUrl,
  primaryColor: state => state.colorScheme.primary,
  primaryFontColor: state => state.colorScheme.primaryFont,
  privacyPolicyLinks: state => state.privacyPolicyLinks,
  termAndConditionLinks: state => state.termAndConditionLinks,
  features: state => state.features,
  smsPersonalLoginEnabled: state => !!state.features.smsPersonalLogin,
  isAddressViasFeatureEnabled: state => !!state.features.isAddressViasFeatureEnabled,
  getHumanReadableId: state => state.humanReadableId,
  currencySymbol: state => state.currencySymbol,
  defaultCountry: state => state.defaultCountry,
  defaultLocale: state => state.defaultLocale,
  communicationLanguage: state => state.communicationLanguage,
  firebaseWebbookerAnalytics: state => state.firebaseWebbookerAnalytics,
};

const actions = {};

const mutations = {
  setBookingChannelConfig(state, { id, name, features, allowedPaymentMethods = [], humanReadableId, colorScheme, privacyPolicyLinks, termAndConditionLinks, authCallbackUrl, postLogoutUrl, oidcClientId, maps, logo, payment, location, currencySymbol, defaultCountry, communicationLanguage, firebaseWebbookerAnalytics }) {
    state.authCallbackUrl = authCallbackUrl;
    state.allowedPaymentMethods = allowedPaymentMethods.length > 0
      ? allowedPaymentMethods
      : state.allowedPaymentMethods;
    state.colorScheme = colorScheme;
    state.features = features;
    state.humanReadableId = humanReadableId;
    state.id = id;
    state.location = location;
    state.logo = logo;
    state.maps = maps;
    state.name = name;
    state.oidcClientId = oidcClientId;
    state.payment = payment;
    state.postLogoutUrl = postLogoutUrl;
    state.privacyPolicyLinks = privacyPolicyLinks;
    state.termAndConditionLinks = termAndConditionLinks;
    state.defaultCountry = defaultCountry;
    state.currencySymbol = currencySymbol;
    state.communicationLanguage = communicationLanguage;
    state.firebaseWebbookerAnalytics = firebaseWebbookerAnalytics;
    state.defaultLocale = CountryLocaleFinder.defaultCountryLocale(defaultCountry);

    this.commit('firebase/initializeFirebase', { firebaseWebbookerAnalytics }, { root: true });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
