<template>
  <v-col
    v-if="$vuetify.breakpoint.mobile"
    class="pa-10 mt-8 px-3 px-md-10"
    cols="12"
    align-items="flex-start"
  >
    <v-card flat>
      <v-card-title class="d-flex flex-column align-start">
        <span class="desctop-bs-header-title">
          {{ $t("components.favourites.title") }}
        </span>
        <v-btn
          class="btn primary mt-4 w-full"
          @click="$emit('addFavourite')"
          height="36px"
          depressed
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t("components.favourites.add") }}
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-list class="mobile-list">
        <v-list-item
          v-for="(item, index) in results"
          :key="index"
          class="list-item align-end"
          @click="$emit('selectFavourite', item)"
        >
          <v-list-item-content>
            <v-list-item-title class="mb-1">{{ item.alias }}</v-list-item-title>
            <p class="mb-0 mt-2 text-left">{{ item.fullAddressText }}</p>

            <div class="my-3 d-flex justify-space-between">
              <div>
                <v-list-item-title class="mb-1">{{
                  $t("components.favourites.table.favourite_pickup")
                }}</v-list-item-title>
                <v-checkbox
                  v-model="item.suggestForPickupAddress"
                  color="#47D061"
                  class="mt-0"
                  hide-details
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  @click="handleFavouriteAddressSuggestion(item)"
                >
                  {{ $t("components.favourites.favourite_pickup") }}
                </v-checkbox>
              </div>

              <div class="mr-3">
                <v-list-item-title class="mb-1">{{
                  $t("components.favourites.table.favourite_dropoff")
                }}</v-list-item-title>
                <v-checkbox
                  v-model="item.suggestForDestinationAddress"
                  color="#47D061"
                  class="mt-0"
                  hide-details
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  @click="handleFavouriteAddressSuggestion(item)"
                >
                  {{ $t("components.favourites.favourite_pickup") }}
                </v-checkbox>
              </div>
            </div>

            <div class="d-flex justify-end">
              <delete-icon
                @click.stop="removeFavouriteAddress(item.id)"
                class="icon-control-list"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="not-data-tile" v-if="!results.length">
          {{ $t("bookings.notData") }}
        </v-list-item>
      </v-list>
      <v-pagination
        v-model="page"
        :length="numberOfPage"
        :total-visible="5"
        color="#f2f2f2"
        class="desctop-bs-pagination"
      ></v-pagination>
    </v-card>
  </v-col>
  <v-col v-else class="pa-10 mt-8 px-3 px-md-10" cols="12" align-items="flex-start">
    <v-card flat>
      <v-card-title class="pb-0">
        <span class="desctop-bs-header-title">
          {{ $t("components.favourites.title") }}
        </span>
      </v-card-title>
      <v-card-text class="list-filters">
        <v-col
          lg="12"
          class="d-flex align-center col-without-top-padding col-without-bottom-padding justify-end"
        >
          <v-btn class="btn primary" @click="$emit('addFavourite')" height="36px" depressed>
            <v-icon left>mdi-plus</v-icon>
            {{ $t("components.favourites.add") }}
          </v-btn>
        </v-col>
      </v-card-text>

      <div class="bs-table-table">
        <v-row no-gutters>
          <v-col class="col-without-top-padding table-color">
            <v-data-table
              class="desctop-bs-table elevation-0"
              :headers="headers"
              :items="results"
              :server-items-length="totalFavourites"
              calculate-widths
              :multi-sort="false"
              :must-sort="true"
              :single-expand="true"
              :page.sync="page"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.alias="{ item }">
                <div class="d-flex align-center">
                  <favourites-icon style="color: #47d061; width:24px" />
                  <span class="ml-2">{{ item.alias }}</span>
                </div>
              </template>
              <template v-slot:item.suggestForPickupAddress="{ item }">
                <v-checkbox
                  v-model="item.suggestForPickupAddress"
                  color="#47D061"
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  @click="handleFavouriteAddressSuggestion(item)"
                />
              </template>

              <template v-slot:item.suggestForDestinationAddress="{ item }">
                <v-checkbox
                  v-model="item.suggestForDestinationAddress"
                  color="#47D061"
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  @click="handleFavouriteAddressSuggestion(item)"
                />
              </template>

              <template v-slot:item.actions="{ item }">
                <delete-icon @click="removeFavouriteAddress(item.id)" class="icon-control-list" />
              </template>
            </v-data-table>
            <div class="py-2 px-4 desctop-bs-table-footer">
              <v-row no-gutters class="desctop-bs-per-page">
                <span>
                  {{ $t(`bookings.perPage`) }}
                </span>
                <span
                  v-for="item in [10, 20, 30]"
                  :key="item"
                  :class="{ 'active-page-count': item === itemsPerPage }"
                  class="px-1"
                  @click="itemsPerPage = item"
                >
                  {{ item }}
                </span>
              </v-row>
              <v-pagination
                v-model="page"
                :length="numberOfPage"
                :total-visible="5"
                color="#f2f2f2"
                class="desctop-bs-pagination"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';

export default {
  name: 'favourite-table',
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    totalFavourites: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ...iconLoader.favourites,
    ...iconLoader.elements,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('components.favourites.table.name'),
          align: 'left',
          sortable: false,
          value: 'alias',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.favourites.table.address'),
          align: 'left',
          sortable: false,
          value: 'fullAddressText',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.favourites.table.favourite_pickup'),
          align: 'left',
          sortable: false,
          value: 'suggestForPickupAddress',
          class: 'text-uppercase',
        },
        {
          text: this.$t('components.favourites.table.favourite_dropoff'),
          align: 'left',
          sortable: false,
          value: 'suggestForDestinationAddress',
          class: 'text-uppercase',
        },
        {
          align: 'right',
          sortable: false,
          value: 'actions',
          class: 'text-uppercase',
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    page(page) {
      this.loadData(10, page - 1);
    },
    itemsPerPage(items) {
      this.loadData(items);
    },
  },
  computed: {
    numberOfPage() {
      return Math.ceil(this.totalFavourites / this.itemsPerPage);
    },
    getMobilePaginationResults() {
      return this.results.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    },
  },
  methods: {
    async loadData(items = 10, mobilePage = 0) {
      const { page, itemsPerPage } = this.options;
      this.$emit('callLoadData', {
        payload: {
          page: mobilePage || page - 1 || 0,
          maxPerPage: items || itemsPerPage,
        },
      });
    },
    async removeFavouriteAddress(id) {
      const { page, itemsPerPage } = this.options;
      this.$emit('removeFavouriteAddress', id, {
        payload: {
          page: page - 1 || 0,
          maxPerPage: itemsPerPage,
        },
      });
    },
    handleFavouriteAddressSuggestion(item) {
      this.$store.dispatch('favourites/updateFavouriteAddress', {
        id: item.id,
        payload: {
          suggestForPickupAddress: item.suggestForPickupAddress,
          suggestForDestinationAddress: item.suggestForDestinationAddress,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.empty-state {
  height: 70vh;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  /* identical to box height, or 139% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.005em;

  /* Day/Grey0 */
  color: #333333;
}

.empty-state-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.005em;

  /* Day/Grey1 */
  color: #888888;
}
</style>
