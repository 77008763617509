<template>
  <v-col cols="12" md="12" class="pa-0 ride-tracking-map-container">
    <Map
      :pickup="pickup"
      :destination="destination"
      :waypoints="waypoints"
      :driverLatLng="driverLatLng"
    ></Map>
  </v-col>
</template>

<script>
import Map from '../../maps/Map.vue';

export default {
  name: 'ride-tracking-map',
  components: { Map },
  props: {
    booking: Object,
  },
  computed: {
    pickup() {
      if (this.booking && this.booking.origin) {
        return {
          latitude: this.booking.origin.latitude,
          longitude: this.booking.origin.longitude,
          description: this.booking.origin.address,
        };
      }
      return null;
    },
    waypoints() {
      if (this.booking && this.booking.waypoints) {
        const waypointsCoordinates = [];
        this.booking.waypoints.forEach((coordinates) => {
          if (coordinates && coordinates.latitude && coordinates.longitude) {
            waypointsCoordinates.push({
              latitude: coordinates.latitude,
              longitude: coordinates.longitude,
              description: coordinates.address,
            });
          }
        });

        return waypointsCoordinates;
      }
      return null;
    },
    destination() {
      if (this.booking && this.booking.destination) {
        return {
          latitude: this.booking.destination.latitude,
          longitude: this.booking.destination.longitude,
          description: this.booking.destination.address,
        };
      }
      return null;
    },
    driverLatLng() {
      if (
        this.booking
        && this.booking.driver
        && this.booking.driver.currentPosition
        && this.booking.driver.currentPosition.longitude
        && this.booking.driver.currentPosition.latitude
      ) {
        return {
          latitude: this.booking.driver.currentPosition.latitude,
          longitude: this.booking.driver.currentPosition.longitude,
        };
      }
      return null;
    },
  },
};
</script>


<style lang="scss" scoped>

@media only screen and (max-width: 960px) {
  .ride-tracking-map-container {
    padding: 0px;
  }
}
</style>
