<template>
  <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" stroke="#EEEEEE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2593 23.1746C21.6933 24.5456 19.6618 25.3733 17.442 25.3733C12.4889 25.3733 8.47363 21.2525 8.47363 16.1693C8.47363 11.0861 12.4889 6.96533 17.442 6.96533C19.6618 6.96533 21.6933 7.79299 23.2593 9.16402C24.8254 7.79299 26.8568 6.96533 29.0766 6.96533C34.0297 6.96533 38.045 11.0861 38.045 16.1693C38.045 21.2525 34.0297 25.3733 29.0766 25.3733C26.8568 25.3733 24.8254 24.5456 23.2593 23.1746Z" fill="#ED0006"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2595 23.1746C25.1879 21.4864 26.4106 18.9744 26.4106 16.1693C26.4106 13.3642 25.1879 10.8522 23.2595 9.16402C24.8256 7.79299 26.857 6.96533 29.0768 6.96533C34.0299 6.96533 38.0452 11.0861 38.0452 16.1693C38.0452 21.2525 34.0299 25.3733 29.0768 25.3733C26.857 25.3733 24.8256 24.5456 23.2595 23.1746Z" fill="#F9A000"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2594 9.16406C25.1877 10.8522 26.4104 13.3642 26.4104 16.1693C26.4104 18.9744 25.1877 21.4864 23.2594 23.1745C21.3311 21.4864 20.1084 18.9744 20.1084 16.1693C20.1084 13.3642 21.3311 10.8522 23.2594 9.16406Z" fill="#FF5E00"/>
  </svg>

</template>

<script>
export default {
  name: 'iconMaster',
};
</script>

<style scoped>

</style>
