<template>
  <div>
    <FavouritesTable
      :results="favouriteAddresses.results"
      :totalFavourites="favouriteAddresses.total - 1"
      :maxPerPage="favouriteAddresses.maxPerPage"
      :isLoading="loading"
      @callLoadData="loadData"
      @addFavourite="addFavourite"
      @removeFavouriteAddress="removeFavouriteAddress"
    />

    <dialog-wrapper ref="addDialog" :width="500">
      <template v-slot:content>
        <add-favourite-form @addFavourite="closeFavouriteForm()" @cancel="$refs.addDialog.close()" />
      </template>
    </dialog-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddFavouriteForm from '@/components/favourites/AddFavouriteForm.vue';
import FavouritesTable from '@/components/favourites/FavouritesTable.vue';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';

export default {
  name: 'favourites-view',
  components: {
    AddFavouriteForm,
    FavouritesTable,
    DialogWrapper,
  },
  async mounted() {
    await this.loadData();
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters({
      favouriteAddresses: 'favourites/favouriteAddresses',
    }),
  },
  watch: {
    favouriteAddresses() {
      // this.loading = false;
    },
  },
  methods: {
    async loadData(options) {
      this.loading = true;
      await this.$store.dispatch('favourites/getFavouriteAddresses', options);
    },
    addFavourite(options) {
      this.$refs.addDialog.openDialog(options);
    },
    closeFavouriteForm() {
      const options = {
        payload: { page: 0, maxPerPage: 10 },
      };

      this.$refs.addDialog.close();
      this.loadData(options);
    },
    async removeFavouriteAddress(id, options) {
      this.loading = true;
      await this.$store.dispatch('favourites/deleteFavouriteAddress', { id });
      await this.loadData(options);
      this.loading = false;
    },
  },
};
</script>
