<template>
  <detail :is-item-selected="isItemSelected">
    <template v-slot:detail-content>
        <loader :justify-center="false" color="primary" :is-loading="bookingDetailLoading">
          <div class="my-6 px-4">
            <mobile-back-icon @click="emitUnselect" style="cursor: pointer;" />
            <span class="mb-3 desctop-bs-header-title">
              {{ $t(`bookings.details.${currentFilter}`) }}
            </span>

            <div class="d-flex flex-column">
              <div v-for="(item, index) in  bookingFieldsToShow" :key="index">
                <div class="my-3 d-flex justify-space-between">
                  <span class="text-subtitle-2">{{ $t(`bookings.details.${item[0]}`) }}:</span>
                  <span class="text-caption pl-10 text-end">{{ item[1] }}</span>
                </div>
                <v-divider/>
              </div>
            </div>
          </div>
      </loader>

        <loader :justify-center="false" color="primary" :is-loading="bookingDetailLoading">
          <Map
            source="expandItem"
            :pickup="getMapAddressData.pickup"
            :destination="getMapAddressData.destination"
            :waypoints="getMapAddressData.waypoints"
            style="height: 25rem;"
          />
        </loader>

        <!-- <v-card-title class="detail-title">
          <div>
            <v-icon color="#000" large @click="emitUnselect">keyboard_arrow_left</v-icon>
            {{ $t(`bookings.details.${currentFilter}`) }}
          </div>
          <div v-if="filterNotCompleted">
            <v-btn depressed color="primary" class="primaryFont--text" :to="rideTrackingUrl" >{{ $t('bookings.details.trackMyRide') }}</v-btn>
          </div>
          <div v-if="!filterNotCompleted && completed">
            <v-btn depressed color="primary" class="primaryFont--text" @click="showReceipt">{{ $t('bookings.details.downloadReceipt') }}</v-btn>
          </div>
        </v-card-title>
        <loader
          :justify-center="false"
          color="primary"
          :is-loading="bookingDetailLoading">
          <v-row class="detail-data" row wrap>
            <v-col class="display-name" cols="12">
              {{ displayName }}
            </v-col>
            <v-col class="pickup-time" cols="12">
              {{ pickupTime }}
            </v-col>
            <v-col class="company-name" cols="12">
              {{ companyName }}
            </v-col>
            <v-col class="vehicle-type" cols="12">
              {{ vehicleType }}
            </v-col>
            <v-col class="location" cols="12">
              <v-icon>icabbi-start</v-icon>
              {{ origin }}
            </v-col>
            <v-col class="location" cols="12">
              <v-icon>icabbi-end</v-icon>
              {{ destination }}
            </v-col>
            <v-col class="receipt-details" cols="12" v-if="!filterNotCompleted && completed">
              <v-row row>
                <v-col grow pa-1>{{ $t('bookings.receiptDetails') }}</v-col>
              </v-row>
            </v-col>
            <v-col class="receipt-tip" cols="12" v-if="!filterNotCompleted && completed">
              <v-row row>
                <v-col grow pa-1>{{ $t('bookings.tip') }}</v-col>
                <v-col shrink pa-1>{{ tip }}</v-col>
              </v-row>
            </v-col>
            <v-col class="price" cols="12" v-if="!filterNotCompleted && completed">
              <v-row row>
                <v-col grow pa-1>{{ $t('bookings.price') }}</v-col>
                <v-col shrink pa-1>{{ price }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </loader> -->
    </template>
  </detail>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import { iconLoader } from '@/helpers/iconsLoader';
import Map from '../maps/Map.vue';
import Detail from '../shared/list/detail/Detail.vue';
import bookingMixin from './mixin/bookingMixin';

export default {
  name: 'BookingDetail',
  components: {
    Detail,
    Map,
    ...iconLoader.sideMenu,
  },
  data: () => ({
    screenName: 'booking_history_details',
    apiGatewayBaseUrl: `${process.env.VUE_APP_API_GATEWAY_URL}/v1`,
  }),
  mixins: [bookingMixin],
  props: {
    selectedBookingId: {
      type: String,
    },
    currentFilter: {
      type: String,
    },
  },
  watch: {
    selectedBookingId(newValue) {
      if (newValue) {
        this.$store.dispatch('bookings/getBookingDetail', { id: newValue });
      }
    },
  },
  computed: {
    ...mapGetters({
      bookingChannelName: 'bookingChannel/name',
      bookingDetail: 'bookings/bookingDetail',
      bookingDetailLoading: 'bookings/bookingDetailLoading',
    }),
    getMapAddressData() {
      if (this.bookingDetail) {
        return {
          pickup: {
            description: this.bookingDetail.origin.address,
            latitude: this.bookingDetail.origin.latitude,
            longitude: this.bookingDetail.origin.longitude,
          },
          destination: {
            description: this.bookingDetail.destination.address,
            latitude: this.bookingDetail.destination.latitude,
            longitude: this.bookingDetail.destination.longitude,
          },
          waypoints: this.bookingDetail.waypoints ? this.bookingDetail.waypoints.map(waypoint => ({
            description: waypoint.address,
            latitude: waypoint.latitude,
            longitude: waypoint.longitude,
          })) : [],
        };
      }

      return {};
    },
    bookingFieldsToShow() {
      const fields = [
        'bookingId',
        'pickupTime',
        'origin',
        'destination',
        'status',
        'vehicle',
        'price',
        'tip',
        'companyName',
      ];

      if (this.bookingDetail) {
        return Object.entries(this.bookingDetail)
          .sort(([key]) => (key === fields[key] ? -1 : 1))
          .filter(([key]) => fields.includes(key))
          .reduce((acc, [key, value]) => {
            if (key === 'status') {
              acc.push([key, this.$t(`rideTracking.summary.booking.status.${value.toUpperCase()}`)]);
            } else if (key === 'vehicle') {
              acc.push([key, this.formatVehicleType(value, this.unavailable)]);
            } else if (key === 'pickupTime') {
              acc.push([key, `${this.formatPickupDate(value, this.locale, this.unavailable)} ${this.formatPickupTime(value, this.locale, this.unavailable)}`]);
            } else if (key === 'price') {
              acc.push([key, this.formatPrice(this.bookingDetail)]);
            } else if (key === 'tip') {
              acc.push([key, this.formatTip(this.bookingDetail)]);
            } else if (key === 'origin' || key === 'destination') {
              acc.push([key, value.address]);
            } else if (key === 'waypoints') {
              acc.push([key, value.length ? value.map(waypoint => waypoint.address).join(', ') : []]);
            } else {
              acc.push([key, value]);
            }
            return acc;
          }, []);
      }

      return [];
    },
    isItemSelected() {
      return !!this.selectedBookingId;
    },
    displayName() {
      const firstName = get(this.bookingDetail, 'userInfo.givenName', '');
      const lastName = get(this.bookingDetail, 'userInfo.familyName', '');
      return `${firstName} ${lastName}`.trim() || this.unavailable;
    },
    unavailable() {
      return this.$t('bookings.unavailable');
    },
    vehicleType() {
      const vehicle = get(this.bookingDetail, 'vehicle');
      return this.formatVehicleType(vehicle, this.unavailable);
    },
    origin() {
      return get(this.bookingDetail, 'origin.address', this.unavailable);
    },
    destination() {
      return get(this.bookingDetail, 'destination.address', this.unavailable);
    },
    pickupTime() {
      const pickupTime = get(this.bookingDetail, 'pickupTime');
      return this.formatPickupTime(pickupTime, this.locale, this.unavailable);
    },
    price() {
      return this.formatPrice(this.bookingDetail);
    },
    tip() {
      return this.formatTip(this.bookingDetail);
    },
    rideTrackingUrl() {
      return `/ride-tracking/${this.selectedBookingId}`;
    },
    filterNotCompleted() {
      return this.currentFilter !== 'pastWithCancelled';
    },
    companyName() {
      return this.bookingChannelName;
    },
    completed() {
      return (
        this.bookingDetail
        && (this.bookingDetail.status === 'COMPLETE' || this.bookingDetail.status === 'COMPLETED')
      );
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    emitUnselect() {
      this.$emit('unselect');
    },
    // getItemTempalte(item) {
    //   const [label, value] = item;

    //   if (label === 'bookingId') {
    //     return `${this.$t(`bookings.details.${label}`)}: ${value}`;
    //   }

    //   if (label === 'status') {
    //     return `${this.$t(`bookings.details.${label}`)}: ${this.$t(`rideTracking.summary.booking.status.${value.toUpperCase()}`)}`;
    //   }

    //   if (label === 'origin' || label === 'destination') {
    //     return `${this.$t(`bookings.details.${label}`)}: ${value.address}`;
    //   }

    //   return '';
    // },
    async showReceipt() {
      const receiptHTML = await this.$store.dispatch('bookings/getReceiptByBookingId', {
        bookingId: this.selectedBookingId,
      });
      if (receiptHTML) {
        const newWindow = window.open();
        newWindow.document.write(receiptHTML);
      }
    },
  },
};
</script>

<style scoped>
.detail-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  padding: 10px 10px 10px 0 !important;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
}
.detail-data {
  margin: 10px 12px 0;
  font-size: 14px;
}

.display-name {
  font-weight: bold;
  font-size: 18px;
  padding: 10px 0;
}

.vehicle-type {
  padding: 10px 0;
}

.pickup-time {
  padding: 10px 0;
  border-bottom: 1px solid #c4c4c4;
}

.location {
  padding: 15px 0;
}

.receipt-details {
  padding-top: 10px;
  border-top: solid 1px #c4c4c4;
  font-weight: bold;
}

.receipt-tip {
  padding: 0 0 10px 0;
}

.price {
  padding-top: 10px;
  border-top: solid 2px #000;
  font-weight: bold;
}

.company-name {
  padding-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
