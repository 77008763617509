<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mt-1"
        small
        v-on="on"
        v-bind="attrs"
        :class="{ 'employee-field-icon': validator.$error }"
      >mdi-help-circle-outline</v-icon>
    </template>
    <span>{{ `${getError}` }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'error-tooltip',
  props: {
    validator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getError() {
      // if (!this.validator?.required) {
      //   return this.$t('validation.required');
      // }
      //
      // if (!this.validator?.minLength) {
      //   return this.$t('validation.minLength', { attribute: this.validator.$params.minLength.min });
      // }
      //
      // if (!this.validator?.email) {
      //   return this.$t('validation.email');
      // }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-field-icon {
  color: #EB5757;
}
</style>

<i18n>
{
  "en": {
    "validation": {
      "required": "This field is required",
      "minLength": "Field should exist minimum with {attribute} characters",
      "email": "Invalid email format"
    }
  },
  "fr": {
    "validation": {
      "required": "Ce champ est obligatoire",
      "minLength": "Ce champ doit avoir un minimum de {attribute} caractères",
      "email": "Format courriel invalide"
    }
  },
  "fi": {
    "validation": {
      "required": "Tämä on vaadittu kenttä",
      "minLength": "Kenttään pitää syöttää vähintään {attribute} merkkiä",
      "email": "Sähköpostiosoitteen muotoilu on väärä"
    }
  },
  "sv": {
    "validation": {
      "required": "This field is required",
      "minLength": "Field should exist minimum with {attribute} characters",
      "email": "Invalid email format"
    }
  }
}
</i18n>
