<template>
  <v-snackbar
      v-model="display"
      :color="color"
      :timeout="timeout"
      top
      absolute
      class="mt-14 mt-md-0"
    >
      <div class="d-flex justify-space-between" :class="[ error.title && error.code ? 'align-start' : 'align-center' ]">
        <v-icon>mdi-alert-circle</v-icon>
        <div v-if="error.title && error.code" class="d-flex flex-column ml-2">
          <span class="mb-2">
            Error {{ error.code }} - {{ error.title }}
          </span>
          <span>
            {{ error.message }}
          </span>
        </div>

        <span v-else>
          {{ error.message }}
        </span>

        <v-icon @click="onDismiss()">mdi-close</v-icon>
      </div>
    </v-snackbar>
</template>

<script>

export default {
  name: 'error-snack',
  components: {},
  props: {
    error: {
      type: Object,
      required: true,
    },
    timeout: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      dismiss: false,
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  computed: {
    display() {
      return this.error.message && this.show && !this.dismiss;
    },
  },
  methods: {
    onDismiss() {
      this.$emit('dismissed', this.error);
      this.dismiss = true;
    },
  },
};
</script>
<style>
</style>
