<template>
  <section class="ride-tracking-driver" v-if="driverInfo">
    <v-row align-center row spacer>
      <v-col xs3 sm2 v-if="pictureUrl">
        <v-avatar size="72px">
          <img :src="pictureUrl" alt="Driver Picture" />
        </v-avatar>
      </v-col>
      <v-col :class="{ 'xs9 sm10': pictureUrl }">
        <p class="driver-name">{{ name }}</p>
        <p class="driver-id">{{ $t('rideTracking.driver.id') }}: {{ id }}</p>
        <p v-if="vehicleInfo" class="vehicle">{{ $t('rideTracking.summary.booking.vehicle') }} {{ vehicle }}</p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'driver',
  props: {
    driverInfo: Object,
    vehicleInfo: Object,
  },
  computed: {
    name() {
      return this.driverInfo && this.driverInfo.name;
    },
    id() {
      return this.driverInfo && this.driverInfo.id;
    },
    pictureUrl() {
      return this.driverInfo && this.driverInfo.pictureUrl;
    },
    make() {
      return _.get(this.vehicleInfo, 'make', '');
    },
    model() {
      return _.get(this.vehicleInfo, 'model', '');
    },
    colour() {
      return _.get(this.vehicleInfo, 'colour', '');
    },
    plate() {
      return _.get(this.vehicleInfo, 'plate', '');
    },
    licenceRegistration() {
      return _.get(this.vehicleInfo, 'licenceRegistration', '');
    },
    vehicle() {
      const colour = this.colour ? `(${this.colour})` : '';
      const plate = this.plate ? `- ${this.plate}` : '';
      const licenceRegistration = this.licenceRegistration ? `- reg ${this.licenceRegistration}` : '';
      const values = [this.make, this.model, colour, plate, licenceRegistration];
      return values.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-name {
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 1px;
}

.driver-id {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  margin-bottom: 1px;
}
</style>
