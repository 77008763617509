import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import VueIcons from '@icabbi/vue-icons';
import Vue from 'vue';

import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAvatar,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCol,
  VCheckbox,
  VContainer,
  VContent,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VFlex,
  VForm,
  VIcon,
  VImg,
  VInput,
  VLayout,
  VList,
  VListItem,
  VListItemAction,
  VListItemTitle,
  VListItemContent,
  VListItemSubtitle,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VProgressCircular,
  VPagination,
  VRow,
  VSelect,
  VSnackbar,
  VSpacer,
  VTextarea,
  VTextField,
  VToolbar,
  VToolbarItems,
} from 'vuetify/lib';


Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAvatar,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCheckbox,
    VContainer,
    VContent,
    VDataTable,
    VDatePicker,
    VDialog,
    VCol,
    VDivider,
    VFlex,
    VForm,
    VIcon,
    VImg,
    VInput,
    VLayout,
    VAppBar,
    VList,
    VListItem,
    VListItemAction,
    VListItemTitle,
    VListItemContent,
    VListItemSubtitle,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VProgressCircular,
    VPagination,
    VRow,
    VSelect,
    VSnackbar,
    VSpacer,
    VTextarea,
    VTextField,
    VToolbar,
    VToolbarItems,
  },
});

export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'fa',
    values: VueIcons.allIcons(),
  },
});

// icons_system_add: { component: Add },
// icons_system_add_filled: { component: AddFilled },
// icons_system_arrow: { component: Arrow },
// icons_system_cancel: { component: Cancel },
// icons_system_caret: { component: Caret },
// icons_system_caret_down: { component: CaretDown },
// icons_system_checkbox: { component: CheckBox },
// icons_system_checkbox_empty: { component: CheckBoxEmpty },
// icons_system_delete: { component: Delete },
// icons_system_delete_filled: { component: DeleteFilled },
// icons_system_edit: { component: Edit },
// icons_system_menu: { component: Menu },
// icons_system_navigation_drawer: { component: NavigationDrawer },
// icons_system_search: { component: Search },
// icons_system_warning: { component: Warning },
// icons_product_avatar: { component: Avatar },
// icons_product_booking: { component: Booking },
// icons_product_calendar: { component: Calendar },
// icons_product_car: { component: Car },
// icons_product_chart: { component: Chart },
// icons_product_credit_card: { component: CreditCard },
// icons_product_department: { component: Department },
// icons_product_email: { component: Email },
// icons_product_employee: { component: Employee },
// icons_product_notes: { component: Notes },
// icons_product_user: { component: User },
// icons_product_worker: { component: Worker },
