<template>
  <v-data-table
    ref="bookingsTable"
    class="desctop-bs-table elevation-0"
    calculate-widths
    hide-default-footer
    :loading="bookingsLoading"
    :headers="headers"
    :single-expand="true"
    :expanded.sync="expanded"
    :no-data-text="$t('bookings.notData')"
    :no-results-text="$t('bookings.notData')"
    :items="list"
    :items-per-page="itemsPerPage"
    @click:row="(item, slot) => openExpandItem(item, slot)"
  >
    <template v-slot:expanded-item="{ headers }">
      <expand-item v-if="!bookingDetailLoading" :headers="headers" :data="bookingDetail" />
    </template>
    <template v-slot:item.companyName="{ item }">
      {{ item.companyName }}
    </template>
    <template v-slot:item.origin="{ item }">
      <div class="d-flex flex-column">
        <span v-if="item.estimatedTimeArrival" class="eta-tooltip">{{
          $t(`bookings.eta`, { amount: item.estimatedTimeArrival })
        }}</span>
        <span class="text-overflow">{{ item.origin }}</span>
      </div>
    </template>

    <template v-slot:item.destination="{ item }">
      <div class="d-flex flex-column">
        <span v-if="item.destinationEtaMinutes" class="eta-tooltip">{{
          $t(`bookings.eta`, { amount: item.destinationEtaMinutes })
        }}</span>
        <span class="text-overflow">{{ item.destination }}</span>
      </div>
    </template>

    <template v-slot:item.payment="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <component v-bind:is="creditCardIcon(getPaymentIcon(item.payment) || 'cash')" />
          </span>
        </template>
        <span>
          {{ getPaymentDetails(item.payment) }}
        </span>
      </v-tooltip>
    </template>

    <template v-slot:item.status="{ item: { status } }">
      <div :class="`status-container py-1 px-2 status-${status.toUpperCase()}`">
        {{ $t(`rideTracking.summary.booking.status.${status}`) }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';
import ExpandItem from './detail/ExpandItem.vue';
import listMixin from './mixin/listMixin';

export default {
  name: 'DesktopList',
  mixins: [listMixin, creditCardIcon],
  components: {
    ExpandItem,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data: () => ({
    expanded: [],
  }),
  computed: {
    ...mapGetters({
      bookingDetail: 'bookings/bookingDetail',
      bookingDetailLoading: 'bookings/bookingDetailLoading',
      bookingsLoading: 'bookings/bookingsLoading',
    }),
  },
  methods: {
    async openExpandItem(item, slot) {
      await this.$store.dispatch('bookings/getBookingDetail', { id: item.id });

      return slot.expand(!slot.isExpanded);
    },
    getPaymentIcon(payment) {
      if (!payment) return 'cash';
      return payment.type === 'creditCard' ? payment.paymentMethod.brand : payment.type;
    },
    // eslint-disable-next-line consistent-return
    getPaymentDetails(payment) {
      if (payment.type === 'cash') {
        return this.$t('book.form.payment.cash');
      }
      if (payment.type === 'creditCard') {
        return this.$t('book.form.payment.cardEnding', {
          number: payment.paymentMethod.number,
        });
      }
      if (payment.type === 'businessAccount') {
        return this.$t('book.form.payment.IC2Accounts');
      }

      return this.$t('book.form.payment.cash');
    },
  },
};
</script>

<style lang="scss">
.desktop-list {
  th {
    padding: 10px 10px 10px 0 !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
    color: #000 !important;
    font-size: 14px !important;
  }
  td {
    padding: 10px 10px 10px 0 !important;
    cursor: pointer;
  }
}

.eta-tooltip {
  color: #ffa557;
}
</style>
