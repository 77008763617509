import { BookingCommandClient, Ic2BookingServiceClient, business } from '@icabbi/api-gateway-client';
import { filter } from 'lodash';
import i18n from '@/i18nInstance';

import { triggerFirebaseEvent } from '@/events/firebase';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';
import { checkForTravelRestrictionError, getRuleTypeFromError } from '../../helpers/travelRestrictionErrorManagement';

const bookingCommandClient = new BookingCommandClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});
bookingCommandClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
bookingCommandClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const ic2BookingServiceClient = new Ic2BookingServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

ic2BookingServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
ic2BookingServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const fleetAccountServiceClient = new business.FleetAccountServiceConnector({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

fleetAccountServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
fleetAccountServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const defaultState = {
  accountFields: [],
  accountFieldsLoading: false,
  bookingInCreation: false,
  bookingCreated: null,
  returnBooking: null,
  bookingUpdated: null,
  bookingInCancellation: false,
  cancelledBookingIds: [],
  travelRestrictionError: null,
  confirmDuplicate: false,
  bookingDuplicateInfos: {},
};

const getters = {
  getAccountFields: state => state.accountFields,
  getAccountFieldsLoading: state => state.accountFieldsLoading,
  getBookingInCreation: state => state.bookingInCreation,
  getBookingCreated: state => state.bookingCreated,
  getReturnBooking: state => state.returnBooking,
  getBookingUpdated: state => state.bookingUpdated,
  getBookingInCancellation: state => state.bookingInCancellation,
  getCancelledBookingIds: state => state.cancelledBookingIds,
  getTravelRestrictionError: state => state.travelRestrictionError,
  getDuplicateConfirmError: state => state.confirmDuplicate,
  getBookingDuplicateInfos: state => state.bookingDuplicateInfos,
};
const actions = {
  async resetBookingCreated({ commit }) {
    commit('setBookingCreated', null);
  },
  async create({ commit, dispatch }, { booking }) {
    try {
      commit('setBookingInCreation', true);
      const requestedBooking = await bookingCommandClient.requestBooking({ data: booking });

      if (requestedBooking.requiresConfirmation) {
        const { paymentIntent } = await dispatch('stripePayment3ds', requestedBooking.authorizationClientSecret);

        if (paymentIntent && paymentIntent.status === 'requires_capture') {
          const createdBooking = await bookingCommandClient.confirmBooking({ bookingId: requestedBooking.bookingId });
          commit('setBookingCreated', Object.assign({ status: 'BOOKING-CREATED' }, createdBooking));
        } else {
          dispatch('globalError/addError', { message: i18n.t('errors.booking.creationError') }, { root: true });
        }

        return true;
      }


      const createdBooking = await bookingCommandClient.confirmBooking({ bookingId: requestedBooking.bookingId });
      commit('setBookingCreated', Object.assign({ status: 'BOOKING-CREATED' }, createdBooking));
    } catch (error) {
      // eslint-disable-next-line no-console
      if (checkForTravelRestrictionError(error.code)) {
        commit('setTravelRestrictionError', error);
      } else {
        dispatch('globalError/addError', { message: i18n.t('errors.booking.creationError') }, { root: true });
      }
    } finally {
      commit('setBookingInCreation', false);
    }

    return true;
  },
  async createV2({ commit, dispatch, rootGetters }, { booking }) {
    try {
      commit('setBookingInCreation', true);

      const payload = {
        ...booking,
        waypoints: booking.waypoints.map(waypoint => ({
          address: waypoint.description,
          latitude: waypoint.latitude,
          longitude: waypoint.longitude,
        })),
      };

      const { returnBooking } = payload;
      const firebaseAnalytics = rootGetters['firebase/firebaseAnalytics'];
      const user = rootGetters['userProfile/user'];

      commit('triggerRequestBookingFirebaseEvent', { firebaseAnalytics, user });

      const requestedBooking = await bookingCommandClient.requestBookingV2({ data: { ...payload, returnBooking: null } });

      if (requestedBooking.requiresConfirmation) {
        const { paymentIntent } = await dispatch('stripePayment3ds', requestedBooking.authorizationClientSecret);

        if (paymentIntent && paymentIntent.status === 'requires_capture') {
          const createdBooking = await bookingCommandClient.confirmBooking({ bookingId: requestedBooking.bookingId });
          if (!returnBooking) {
            commit('setBookingCreated', Object.assign({ status: 'BOOKING-CREATED' }, createdBooking));
          } else {
            commit('setReturnBooking', Object.assign({ status: 'RETURN-BOOKING' }, createdBooking));
          }
        } else {
          dispatch('globalError/addError', { message: i18n.t('errors.booking.creationError') }, { root: true });
        }

        return true;
      }


      const createdBooking = await bookingCommandClient.confirmBooking({ bookingId: requestedBooking.bookingId });
      if (!returnBooking) {
        commit('setBookingCreated', Object.assign({ status: 'BOOKING-CREATED' }, createdBooking));
      } else {
        commit('setReturnBooking', Object.assign({ status: 'RETURN-BOOKING' }, createdBooking));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      if (checkForTravelRestrictionError(error.code)) {
        commit('setTravelRestrictionError', error);
      } else {
        const confirmDuplicateBooking = 'DUPLICATE_BOOKING_NOT_ACKNOWLEDGED';
        if (error.code === confirmDuplicateBooking) {
          commit('setBookingDuplicateInfos', booking);
          commit('setDuplicateConfirmError', true);
        } else {
          dispatch('globalError/addError', {
            message: error.displayMessage,
            code: error.httpStatusCode,
            title: error.displayTitle,
          }, { root: true });
        }
      }
    } finally {
      commit('setBookingInCreation', false);
    }

    return true;
  },

  async updateBooking({ commit, dispatch, rootGetters }, { booking }) {
    try {
      commit('setBookingInCreation', true);

      const firebaseAnalytics = rootGetters['firebase/firebaseAnalytics'];
      const user = rootGetters['userProfile/user'];

      commit('triggerRequestUpdateBookingFirebaseEvent', { firebaseAnalytics, user, booking });

      const updateBooking = await bookingCommandClient.updateBooking(booking.bookingId, booking);

      if (updateBooking.requiresConfirmation) {
        const { paymentIntent } = await dispatch('stripePayment3ds', updateBooking.authorizationClientSecret);

        if (paymentIntent && paymentIntent.status === 'requires_capture') {
          const createdBooking = await bookingCommandClient.confirmBookingUpdate(updateBooking.bookingId);
          commit('setBookingUpdated', Object.assign({ status: 'BOOKING-UPDATED' }, createdBooking));
        } else {
          const { displayMessage } = updateBooking.erors[0];
          dispatch('globalError/addError', { message: displayMessage || i18n.t('errors.booking.creationError') }, { root: true });
        }

        return true;
      }

      if (updateBooking) {
        await bookingCommandClient.confirmBookingUpdate(updateBooking.bookingId);
      }
      commit('setBookingUpdated', Object.assign({ status: 'BOOKING-UPDATED' }));
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      if (checkForTravelRestrictionError(error.code)) {
        commit('setTravelRestrictionError', error);
      } else {
        const paymentDisabled = 'BOOKING-COMMAND-CASH-PAYMENT-METHOD-NOT-ALLOWED';
        dispatch('globalError/addError', {
          message: paymentDisabled === error.code
            ? i18n.t('errors.booking.disablePayment')
            : error.displayMessage || i18n.t('errors.booking.creationError'),
        }, { root: true });
      }
    } finally {
      commit('setBookingInCreation', false);
    }

    return true;
  },

  async stripePayment3ds({ rootGetters }, clientSecretId) {
    const client = await rootGetters['paymentMethods/stripeClient'];

    const paymentIntentDetails = await client.retrievePaymentIntent(clientSecretId);
    const confirmationResult = await client.confirmCardPayment(paymentIntentDetails.paymentIntent.client_secret, {
      payment_method: paymentIntentDetails.paymentIntent.payment_method,
    });

    return confirmationResult;
  },

  async cancel({ commit, dispatch, rootGetters }, bookingId) {
    try {
      commit('setBookingInCancellation', true);

      const firebaseAnalytics = rootGetters['firebase/firebaseAnalytics'];
      const user = rootGetters['userProfile/user'];

      commit('triggerRequestCancelBookingFirebaseEvent', { firebaseAnalytics, user, bookingId });

      const result = await bookingCommandClient.cancelBooking({ bookingId });
      commit('addCancelledBookingId', bookingId);

      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch('globalError/addError', { message: 'booking.cancellationError' }, { root: true });
    } finally {
      dispatch('bookings/list', {
        filter: ['current'],
        page: 0,
        maxPerPage: 10,
      }, { root: true });
      commit('setBookingInCancellation', false);
      // eslint-disable-next-line no-unsafe-finally
      return true;
    }
  },
  async getAccountFields(context, params) {
    try {
      context.commit('setAccountFieldsLoading', true);
      const { data } = await fleetAccountServiceClient.getFleetAccounts(params);
      context.commit('setAccountFields', filter(data, field => (field.active)));
      context.commit('setAccountFieldsLoading', false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.commit('setAccountFields', false);
      context.commit('setAccountFieldsLoading', false);
      context.dispatch('globalError/addError', { message: 'booking.ic2AccountFieldsError' }, { root: true });
    }
  },
  clearRestrictionError(context) {
    context.commit('setTravelRestrictionError', null);
  },
};

const mutations = {
  setAccountFields(state, accountFields) {
    state.accountFields = accountFields;
  },
  setAccountFieldsLoading(state, accountFieldsLoading) {
    state.accountFieldsLoading = accountFieldsLoading;
  },
  setBookingCreated(state, bookingCreated) {
    state.bookingCreated = bookingCreated;
  },
  setReturnBooking(state, returnBooking) {
    state.returnBooking = returnBooking;
  },
  setBookingInCreation(s, bookingInCreation) {
    s.bookingInCreation = bookingInCreation; // eslint-disable-line
  },
  setBookingUpdated(state, bookingUpdated) {
    state.bookingUpdated = bookingUpdated;
  },
  setBookingInCancellation(s, bookingInCancellation) {
    s.bookingInCancellation = bookingInCancellation; // eslint-disable-line
  },
  addCancelledBookingId(s, bookingId) {
    s.cancelledBookingIds.push(bookingId); // eslint-disable-line
  },
  setTravelRestrictionError(s, error) {
    s.travelRestrictionError = error ? { ruleType: getRuleTypeFromError(error) } : null; // eslint-disable-line
  },
  setDuplicateConfirmError(s, value) {
    s.confirmDuplicate = value; // eslint-disable-line
  },
  setBookingDuplicateInfos(s, bookingInfos) {
    s.bookingDuplicateInfos = bookingInfos; // eslint-disable-line
  },
  triggerRequestBookingFirebaseEvent(state, { firebaseAnalytics, user }) {
    triggerFirebaseEvent({
      firebaseAnalytics,
      event: 'request_booking',
      payload: {
        firebaseUserId: user.id,
      },
    });
  },
  triggerRequestUpdateBookingFirebaseEvent(state, { firebaseAnalytics, user, booking }) {
    triggerFirebaseEvent({
      firebaseAnalytics,
      event: 'request_update_booking',
      payload: {
        firebaseUserId: user.id,
        bookingId: booking.bookingId,
      },
    });
  },
  triggerRequestCancelBookingFirebaseEvent(state, { firebaseAnalytics, user, bookingId }) {
    triggerFirebaseEvent({
      firebaseAnalytics,
      event: 'booking_cancelled',
      payload: {
        firebaseUserId: user.id,
        bookingId,
      },
    });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
