<template>
  <v-row row wrap class="ma-0 pa-0 mobile-column-reverse">
    <ride-tracking-summary :booking="booking" screenName="search_driver"></ride-tracking-summary>
    <ride-tracking-map :booking="booking"></ride-tracking-map>
  </v-row>
</template>

<script>
import RideTrackingMap from '../shared/RideTrackingMap.vue';
import RideTrackingSummary from '../shared/RideTrackingSummary.vue';

export default {
  name: 'ride-tracking-booking-dispatch-requested',
  data() {
    return {
      screenName: 'search_driver',
    };
  },
  components: {
    RideTrackingSummary,
    RideTrackingMap,
  },
  props: {
    booking: Object,
  },
  mounted() {
    this.$emit('suggestedBookingRefreshInterval', 2000);
  },
  created() {
    this.$emit('trackByStatusPageView', { screenName: this.screenName, actionName: 'search_driver_viewed' });
  },
  computed: {
    bookingId() {
      if (this.booking) return this.booking.id;
      return undefined;
    },
  },
};
</script>

<style scoped>
</style>
