<template>
  <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" stroke="#EEEEEE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1041 12L6.34473 20.6385H10.8452L11.4031 19.2611H12.6784L13.2363 20.6385H18.1901V19.5872L18.6315 20.6385H21.1939L21.6354 19.565V20.6385H31.9378L33.1905 19.2969L34.3635 20.6385L39.6551 20.6496L35.8839 16.3433L39.6551 12H34.4456L33.2261 13.3168L32.0901 12H20.8824L19.92 14.2297L18.935 12H14.4439V13.0155L13.9443 12H10.1041ZM10.975 13.2267H13.1687L15.6623 19.0845V13.2267H18.0654L19.9914 17.4267L21.7665 13.2267H24.1576V19.4254H22.7026L22.6908 14.5681L20.5696 19.4254H19.2681L17.135 14.5681V19.4254H14.1418L13.5744 18.0357H10.5087L9.94238 19.4241H8.33867L10.975 13.2267ZM31.4049 13.2267H25.4887V19.4217H31.3133L33.1906 17.3686L35.0001 19.4217H36.8916L34.1423 16.3421L36.8916 13.2267H35.0821L33.2143 15.2562L31.4049 13.2267ZM12.042 14.2755L11.032 16.751H13.0509L12.042 14.2755ZM26.9496 15.6417V14.5101V14.509H30.641L32.2518 16.3187L30.5697 18.1382H26.9496V16.9029H30.1771V15.6417H26.9496Z" fill="#1F72CD"/>
  </svg>
</template>

<script>
export default {
  name: 'iconAmex',
};
</script>

<style scoped>

</style>
