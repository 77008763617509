
// eslint-disable-next-line import/prefer-default-export
export function createFirebaseMiddlewareRouteEvent(store) {
  return (to, from, next) => {

    const firebaseAnalytics = store.getters['firebase/firebaseAnalytics'];

    if (!firebaseAnalytics) {
      next();
      return;
    }

    const user = store.getters['userProfile/user'];

    triggerFirebaseEvent({
      firebaseAnalytics,
      eventName: `visit_${to.name}_page`,
      payload: {
        pageLocation: window.location.href,
        pagePath: to.path,
        firebaseUserId: user.id,
      },
    });

    next();
  };
}

export function triggerFirebaseEvent({ firebaseAnalytics, eventName, payload }) {
  if (!firebaseAnalytics) {
    return;
  }

  firebaseAnalytics.logEvent(eventName, payload);
}
