import { UserServiceClient } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

const userServiceClient = new UserServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

userServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
userServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const defaultState = {
  user: {},
  userLoading: false,
  emailVerificationSent: false,
  emailVerificationLoading: false,
  emailNotified: false,
};

const getters = {
  user: state => state.user,
  userLoading: state => state.userLoading,
  emailVerificationSent: state => state.emailVerificationSent,
  emailVerificationLoading: state => state.emailVerificationLoading,
  communicationLanguage: state => state.user.communicationLanguage,
};

const actions = {
  async getUser(context) {
    try {
      context.commit('setUserLoading', true);
      const user = await userServiceClient.getMe();
      context.commit('setUser', user);
      context.commit('setUserLoading', false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'userProfile.retrievalError' }, { root: true });
    } finally {
      context.commit('setUserLoading', false);
    }
  },
  async updateUser(context, payload) {
    try {
      context.commit('setUserLoading', true);
      const response = await userServiceClient.updateMe({ data: payload });
      context.commit('setEmailVerificationSentState', response.emailVerificationSent);
      await context.dispatch('getUser', true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'userProfile.updateError' }, { root: true });
    } finally {
      context.commit('setUserLoading', false);
    }
  },
  async sendVerificationEmail(context, payload) {
    try {
      context.commit('setEmailVerificationLoading', true);
      await userServiceClient.sendVerificationEmail({ data: payload });
      context.commit('setEmailVerificationSentState', true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'userProfile.sendVerificationEmailError' }, { root: true });
    } finally {
      context.commit('setEmailVerificationLoading', false);
    }
  },
  async confirmEmail(context, payload) {
    try {
      return await userServiceClient.confirmEmail({ data: payload });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'userProfile.confirmEmailError' }, { root: true });
    } finally {
      // eslint-disable-next-line no-console
      console.log('Email confirmed');
    }
    return { confirmed: false };
  },
  async deleteUser(context) {
    try {
      context.commit('setUserLoading', true);
      await userServiceClient.deleteMe();
    } catch (error) {
      const passengerHasActiveBookings = error.code === 'PASSENGER_HAS_ACTIVE_BOOKINGS';
      if (passengerHasActiveBookings) {
        context.dispatch('globalError/addError', { message: 'userProfile.deleteErrorWithActiveBookings' }, { root: true });
        return false;
      }

      context.dispatch('globalError/addError', { message: 'userProfile.deleteError' }, { root: true });
      return false;
    } finally {
      context.commit('setUserLoading', false);
    }
    return true;
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserLoading(state, userLoading) {
    state.userLoading = userLoading;
  },
  setEmailVerificationLoading(state, emailVerificationLoading) {
    state.emailVerificationLoading = emailVerificationLoading;
  },
  setEmailVerificationSentState(state, emailVerificationSent) {
    state.emailVerificationSent = emailVerificationSent;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
