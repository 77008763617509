<template>
  <v-col class="pa-10 mt-8 px-3 px-md-10" cols="12" align-items="flex-start">
    <v-card flat>
      <v-card-title class="d-flex flex-column align-start">
        <span class="desctop-bs-header-title">
          {{ $t("paymentMethods.title") }}
        </span>
        <div class="d-flex justify-end w-full mt-4">
          <v-btn class="btn primary" @click="addCard" height="36px" depressed :class="{ 'w-full': $vuetify.breakpoint.mobile }">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('paymentMethods.search.buttonText')}}
          </v-btn>
        </div>
      </v-card-title>
      <div class="bs-table-table">
        <v-row no-gutters>
          <v-col class="col-without-top-padding table-color">
            <v-data-table
              class="desctop-bs-table elevation-0"
              calculate-widths
              :items="getTableData"
              :headers="headers"
              :server-items-length="totalCards"
              :loading="isLoading"
              :multi-sort="false"
              :must-sort="true"
              :single-expand="true"
              :page.sync="page"
              :disable-sort="true"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >

              <template v-slot:item.brand="{ item: { brand, last4 } }">
                <div class="d-flex align-center">
                  <component v-bind:is="creditCardIcon(brand)"/>
                  <span class="ml-3"> ending with {{last4}}</span>
                </div>
              </template>

              <template v-slot:item.expiration="{ item: { expiration } }">
                <span>{{formatExpiry({year: expiration.year, month: expiration.month})}}</span>
              </template>

              <template v-slot:item.cardHolderName="{ item: { cardHolderName } }">
                <span>{{cardHolderName}}</span>
              </template>

              <template v-slot:item.status="{ item }">
                <v-icon @click="confirmDelete(item)" class="icon-control-list">mdi-delete</v-icon>
              </template>
            </v-data-table>
            <div class="py-2 px-4 desctop-bs-table-footer">
              <v-row no-gutters class="desctop-bs-per-page">
                <span>
                  {{ $t(`bookings.perPage`) }}
                </span>
                    <span
                      v-for="item  in [10, 20, 30]"
                      :key="item"
                      :class="{ 'active-page-count': item === maxPerPage }"
                      class="px-1"
                      @click="maxPerPage = item"
                    >
                  {{ item }}
                </span>
              </v-row>
              <v-pagination
                v-model="page"
                :length="numberOfPage"
                :total-visible="10"
                color="#f2f2f2"
                class="desctop-bs-pagination"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </div>
      <confirm-dialog
        ref="confirmDialog"
        :data="modalData"
        :okButtonText="$t('delete')"
        @handleCancelButton="cancelModal"
        @handleOkButton="deleteCard"
      >
        <v-card-title class="text-h5">
          {{$t('paymentMethods.dialog.delete.title')}}
        </v-card-title>
        <v-card-text>
          <p class="font-weight-bold">{{$t('paymentMethods.dialog.delete.message')}}</p>
          <p>{{$t('paymentMethods.dialog.delete.sub_message')}}</p>
        </v-card-text>
      </confirm-dialog>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';
import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';
import { longDateTimeDay, time } from '../../helpers/dateTime';
import ConfirmDialog from '../../components/elements/ConfirmDialog.vue';
import List from '../shared/list/List.vue';

export default {
  props: {
    totalCards: {
      type: Number,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: { ConfirmDialog, List },
  mixins: [creditCardIcon],
  filters: { longDateTimeDay, time },
  // eslint-disable-next-line max-lines-per-function
  data() {
    return {
      tabs: [
        { tab: 'cards', filter: [] },
      ],
      showAddCard: false,
      tab: 0,
      page: 1,
      pageCount: 0,
      options: {},
      expanded: [],
      itemsPerPage: 10,
      dispatchId: null,
      maxPerPage: 10,
      headers: [
        {
          text: this.$t('paymentMethods.tableHeadings.paymentMethod'),
          align: 'left',
          sortable: true,
          value: 'brand',
        },
        {
          text: this.$t('paymentMethods.tableHeadings.expiry'),
          align: 'left',
          sortable: false,
          value: 'expiration',
        },
        {
          text: this.$t('paymentMethods.tableHeadings.cardName'),
          align: 'left',
          sortable: false,
          value: 'cardHolderName',
        },
        {
          align: 'right',
          sortable: false,
          value: 'status',
        },
      ],
      modalData: null,
    };
  },
  computed: {
    getTableData() {
      return this.cards.filter(paymentMethod => paymentMethod.paymentMethodType !== 'cash')
        .filter(el => !el.businessAccount);
    },
    numberOfPage() {
      return Math.ceil(this.totalCards / this.maxPerPage);
    },
  },
  watch: {
    options: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    formatExpiry({ month, year }) {
      return moment(`${year}-${month}-1`).format('YYYY/MM');
    },
    async loadData() {
      this.$emit('callLoadData');
    },
    addCard() {
      this.$emit('addCard');
    },
    deleteCard(data) {
      this.$emit('deleteCard', data);
      this.$refs.confirmDialog.close();
    },
    cancelModal() {
      this.modalData = null;
      this.$refs.confirmDialog.close();
    },
    confirmDelete(data) {
      this.$emit('deleteCard', data);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "search": "Search",
    "add": "Add",
    "delete": "Delete",
    "dialog": {
      "title": "Delete card",
      "message": "Are you sure you want to delete this card?",
      "sub_message": "This action cannot be undone."
    }
  },
  "fr": {
    "search": "Rechercher",
    "add": "Ajouter",
    "delete": "Supprimer",
    "dialog": {
      "title": "Supprimer carte",
      "message": "Vous voulez vraiment supprimer cette carte?",
      "sub_message": "Cette action ne peut pas être défaite."
    }
  },
  "fi": {
    "search": "Etsi",
    "add": "Lisää",
    "delete": "Poista",
    "dialog": {
      "title": "Poista kortti",
      "message": "Oletko varma että haluat poistaa tämän kortin?",
      "sub_message": "Tätä toimintoa ei voi perua."
    }
  },
  "sv": {
    "search": "Search",
    "add": "Add",
    "delete": "Delete",
    "dialog": {
      "title": "Delete card",
      "message": "Are you sure you want to delete this card?",
      "sub_message": "This action cannot be undone."
    }
  }
}
</i18n>
