/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';

import authentication from './modules/authentication';
import book from './modules/book';
import bookingChannel from './modules/bookingChannel';
import businessAccounts from './modules/businessAccounts';
import bookings from './modules/bookings';
import employeeGroups from './modules/employeeGroups';
import features from './modules/features';
import geolocation from './modules/geolocation';
import globalError from './modules/globalError';
import language from './modules/language';
import offers from './modules/offers';
import paymentMethods from './modules/paymentMethods';
import fareEstimation from './modules/fareEstimation';
import rating from './modules/rating';
import rideTracking from './modules/rideTracking';
import sharedRideTracking from './modules/sharedRideTracking';
import userProfile from './modules/userProfile';
import customerConfiguration from './modules/customerConfiguration';
import firebase from './modules/firebase';
import favourites from './modules/favourites';
import driverStates from './modules/drivers';

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    authentication,
    book,
    bookingChannel,
    bookings,
    businessAccounts,
    driverStates,
    employeeGroups,
    fareEstimation,
    features,
    geolocation,
    globalError,
    language,
    offers,
    paymentMethods,
    rating,
    rideTracking,
    sharedRideTracking,
    userProfile,
    customerConfiguration,
    firebase,
    favourites,
  },
});
