import dayJs from 'dayjs';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { NumberFormatter } from '@icabbi/hi-dash';

export default {
  computed: {
    ...mapGetters({
      currencySymbol: 'bookingChannel/currencySymbol',
      defaultLocale: 'bookingChannel/defaultLocale',
    }),
  },
  methods: {
    formatPickupDate(pickupTime, locale, defaultString) {
      if (!pickupTime) {
        return defaultString;
      }
      return dayJs(pickupTime).locale(locale).format('DD MMMM YYYY');
    },
    formatPickupTime(pickupTime, locale, defaultString) {
      if (!pickupTime) {
        return defaultString;
      }
      return dayJs(pickupTime).locale(locale).format('HH:mm');
    },
    formatVehicleType(vehicle, defaultString) {
      if (!vehicle) {
        return defaultString;
      }
      const model = get(vehicle, 'model', '');
      const make = get(vehicle, 'make', '');
      const color = vehicle.color ? `(${vehicle.color})` : '';
      const registration = vehicle.registration ? `- ${vehicle.registration}` : '';
      return `${make} ${model} ${color} ${registration}`;
    },
    formatPrice(booking) {
      const price = get(booking, 'pricingDetails.total');

      if (!price) {
        return this.unavailable;
      }

      const priceString = parseFloat(Math.round(price * 100) / 100).toFixed(2);
      return `${NumberFormatter.stringValueDecimalSeparator(priceString, this.defaultLocale)} ${this.currencySymbol}`;
    },
    formatTip(booking) {
      const tip = get(booking, 'pricing.tip');
      if (!tip) {
        return this.unavailable;
      }

      const tipString = parseFloat(Math.round(tip * 100) / 100).toFixed(2);
      return `${NumberFormatter.stringValueDecimalSeparator(tipString, this.defaultLocale)} ${this.currencySymbol}`;
    },
    formatDestinationEta(booking, locale) {
      const eta = get(booking, 'destinationEtaMinutes');
      const status = get(booking, 'status');

      if (eta && status === 'DROPPINGOFF') {
        return dayJs().locale(locale).add(eta, 'minutes').format('HH:mm');
      }

      return null;
    },
    formatPickupEta(booking, locale) {
      const eta = get(booking, 'estimatedTimeArrival');
      const status = get(booking, 'status');

      if (eta && status === 'ENROUTE') {
        return dayJs().locale(locale).add(eta, 'minutes').format('HH:mm');
      }

      return null;
    },
  },
};
