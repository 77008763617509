const BUSINESS_ACCOUNTS_KEY = 'businessAccounts';

const defaultState = {
  accounts: null,
};

const getters = {
  accounts: state => state.accounts || JSON.parse(localStorage.getItem(BUSINESS_ACCOUNTS_KEY)),
};

const actions = {

};

const mutations = {

};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
