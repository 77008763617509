import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import { isValidAlphanumericalString, isValidString } from './validationMethods';

export default {
  billingDetails: {
    address: {
      postal_code: {
        minLength: minLength(1),
        maxLength: maxLength(10),
        required,
        isValidAlphanumericalString,
      },
      line1: {
        minLength: minLength(1),
        required,
        isValidString,
      },
      city: {
        minLength: minLength(1),
        required,
        isValidString,
      },
      country: {
        minLength: minLength(1),
        maxLength: maxLength(2),
        required,
        isValidAlphanumericalString,
      },
    },
    name: {
      minLength: minLength(1),
      required,
      isValidString,
    },
  },
};
