// eslint-disable-next-line import/prefer-default-export
export const entityLoader = {
  data: () => ({
    data: [],
    entitySearchObject: {
      page: 0,
      maxPerPage: 10,
      sort: 'givenName',
    },
    entityLoading: false,
    entityTotalCount: {
      totalCount: 0,
    },
    intersection: false,
  }),
  watch: {
    data() {
      this.entityLoading = false;
    },
  },
  methods: {
    async getList(url, options) {
      this.entityLoading = true;
      const { data, headers } = await this.$store.dispatch(url, options);
      if (data && this.intersection) {
        this.data.push(...data);
      } else {
        this.data = data;
      }

      this.entityTotalCount = this.formatHeaders(headers);
      return true;
    },
    async actionPost(url, options) {
      const result = await this.$store.dispatch(url, options);

      if (result) return true;

      return false;
    },
    formatHeaders(headers) {
      return {
        totalCount: Number(headers['x-total-count'] || 0),
      };
    },
  },
};
