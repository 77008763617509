var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-10 mt-8 px-3 px-md-10",attrs:{"cols":"12","align-items":"flex-start"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"desctop-bs-header-title"},[_vm._v(" "+_vm._s(_vm.$t("bookingPaymentActionRequired.title"))+" ")])]),_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-without-top-padding table-color"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.bookingsRequirePaymentAction,"headers":_vm.headers,"loading":_vm.isLoading,"disable-sort":true,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pickupTime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{},[_vm._v(_vm._s(_vm.formatPickupTime(item.pickupTime)))])])]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-overflow"},[_vm._v(_vm._s(item.origin.address))])])]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-overflow"},[_vm._v(_vm._s(item.destination.address))])])]}},{key:"item.payment",fn:function(ref){
var ref_item_payment_paymentMethod = ref.item.payment.paymentMethod;
var brand = ref_item_payment_paymentMethod.brand;
var number = ref_item_payment_paymentMethod.number;
return [_c('div',{staticClass:"d-flex align-center"},[_c(_vm.creditCardIcon(brand),{tag:"component"}),_c('span',{staticClass:"ml-3"},[_vm._v(" ending with "+_vm._s(number))])],1)]}},{key:"item.confirm",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn primary",attrs:{"loading":_vm.confirmLoading,"small":"","depressed":""},on:{"click":function($event){return _vm.$emit('confirmPayment', item)}}},[_vm._v(" "+_vm._s(_vm.$t('bookingPaymentActionRequired.confirm'))+" ")]),_c('v-btn',{staticClass:"btn danger ml-2",attrs:{"small":"","depressed":""},on:{"click":function($event){return _vm.$emit('cancelBooking', item)}}},[_vm._v(" "+_vm._s(_vm.$t('bookingPaymentActionRequired.cancel'))+" ")])]}}])}),_c('div',{staticClass:"py-2 px-4 desctop-bs-table-footer"},[_c('v-row',{staticClass:"desctop-bs-per-page",attrs:{"no-gutters":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("bookings.perPage"))+" ")]),_vm._l(([10, 20, 30]),function(item){return _c('span',{key:item,staticClass:"px-1",class:{ 'active-page-count': item === _vm.itemsPerPage },on:{"click":function($event){_vm.itemsPerPage = item}}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('v-pagination',{staticClass:"desctop-bs-pagination",attrs:{"total-visible":10,"color":"#f2f2f2"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }