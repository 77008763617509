<template>
  <v-col cols="12" align-items="flex-start">
    <v-card flat style="border-radius: 6px;">
      <v-card-title class="pb-0">
        <slot name="title" />
      </v-card-title>
      <v-card-text
        class="list-filters">
          <slot name="filters"></slot>
      </v-card-text>
      <MobileList
        :list-key="listKey"
        class="hidden-md-and-up"
        :list="mobileList"
        @itemSelected="emitItemSelected"
      ></MobileList>
      <DesktopList
        class="hidden-sm-and-down"
        :headers="desktopHeaders"
        :list="desktopList"
        :itemsPerPage="itemsPerPage"
        @itemSelected="emitItemSelected"
      ></DesktopList>
    </v-card>

    <slot name="pagination"></slot>
  </v-col>
</template>

<script>
import MobileList from './MobileList.vue';
import DesktopList from './DesktopList.vue';

export default {
  name: 'List',
  components: {
    MobileList,
    DesktopList,
  },
  props: {
    desktopList: {
      type: Array,
      required: true,
    },
    desktopHeaders: {
      type: Array,
      required: true,
    },
    mobileList: {
      type: Array,
      required: true,
    },
    listKey: {
      type: String,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    emitItemSelected(item) {
      this.$emit('itemSelected', item);
    },
  },
};
</script>

<style scoped>
  .list-filters{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    padding-top: 0px;
    border-bottom: 1px solid #f2f2f2;
  }
  .pagination {
    justify-content: space-between;
  }
</style>
