import { ReverseGeocoderServiceClient, SuggestionServiceClient } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

const reverseGeocoderClient = new ReverseGeocoderServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

const suggestionsClient = new SuggestionServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

reverseGeocoderClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
reverseGeocoderClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));
suggestionsClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
suggestionsClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));


const defaultState = {
  pickupGeocodingInProgress: false,
  userCoordinates: {},
  pickup: {},
  destination: {},
  waypoints: [],
  suggestions: [],
  source: null,
};

const getters = {
  source: state => state.source,
  suggestions: state => state.suggestions,
  pickup: state => state.pickup,
  destination: state => state.destination,
  waypoints: state => state.waypoints,
  userCoordinates: state => state.userCoordinates,
  pickupGeocodingInProgress: state => state.pickupGeocodingInProgress,
};

const actions = {
  async reset({ commit }) {
    commit('setSuggestionDetails', { type: 'pickup', address: {} });
    commit('setSuggestionDetails', { type: 'destination', address: {} });
    commit('resetWaypoints');
  },
  async getSuggestions(context, { search, type, lang, index, latitude, longitude }) {
    try {
      const { suggestions, source } = await suggestionsClient.getSuggestions({
        params: {
          search,
          lang,
          latitude,
          longitude,
          limit: 5,
        },
      });
      context.commit('setSuggestions', { suggestions, type, index });
      context.commit('setSource', { source, type, index });

      return suggestions;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.dispatch('globalError/addError', { message: 'geolocation.suggestionRetrievalError' }, { root: true });
    }
    return null;
  },

  async getSuggestionDetail(context, { address, type, lang, index }) {
    if (address.type === 'favourite') {
      const params = {
        description: address.description,
        coordinates: {
          latitude: address.detail.latitude,
          longitude: address.detail.longitude,
        },
        locationTypes: address.locationTypes,
      };
      context.commit('setSuggestionDetails', { address: params, type, index });
      return params;
    }

    if (!address.info.coordinates || !address.info.coordinates.latitude || !address.info.coordinates.longitude) {
      const addressDetail = await suggestionsClient.getSuggestionDetails(address.id, {
        params: {
          lang,
        },
      });

      context.commit('setSuggestionDetails', { address: addressDetail, type, index });
      return addressDetail;
    }

    const params = {
      description: address.description,
      coordinates: address.info.coordinates,
      locationTypes: address.locationTypes,
    };
    context.commit('setSuggestionDetails', { address: params, type, index });
    return params;
  },

  async setUserCoordinates(context, { coordinates }) {
    context.commit('setUserCoordinates', { coordinates });
  },

  async setSuggestionDetails(context, { address, type, index }) {
    const mappedAddress = Object.assign({}, {
      description: address.description,
      coordinates: address.coordinates,
    });
    context.commit('setSuggestionDetails', { address: mappedAddress, type, index });
  },


  async geocode(context, { location, lang }) {
    try {
      context.commit('setPickupGeocodingInProgress', true);
      const { latitude, longitude } = location;
      const options = {
        params: {
          lang,
          latitude,
          longitude,
        },
      };
      const response = await reverseGeocoderClient.getReverseGeocode(options);

      if (!response.results || response.results.length === 0) {
        context.commit('setPickupGeocodingInProgress', false);
        return;
      }
      const { description, locationTypes } = response.results[0];
      context.commit('setPickup', { address: { description, locationTypes, coordinates: { latitude, longitude } } });
      context.commit('setPickupGeocodingInProgress', false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'geolocation.geocodeError' }, { root: true });
      context.commit('setPickupGeocodingInProgress', false);
    }
  },
  removeLastWaypoint(context) {
    context.commit('removeLastWaypoint');
  },
  removeWaypointIndex(context, index) {
    context.commit('removeWaypointIndex', index);
  },
};

const mutations = {
  setPickupGeocodingInProgress(state, pickupGeocodingInProgress) {
    state.pickupGeocodingInProgress = pickupGeocodingInProgress;
  },
  setUserCoordinates(state, { coordinates }) {
    state.userCoordinates = coordinates;
  },
  setPickup(state, { address }) {
    state.pickup = address;
  },
  resetWaypoints(state) {
    state.waypoints = [];
  },
  resetSuggestions(state) {
    state.suggestions = [];
    state.source = null;
  },
  setSuggestions(state, { suggestions }) {
    state.suggestions = suggestions.length ? [...new Map(suggestions.map(item => [item.description, item])).values()] : [];
  },
  setSource(state, { source }) {
    state.source = source;
  },
  setSuggestionDetails(state, { address, type, index }) {
    if (type === 'destination') {
      state.destination = address;
    } else if (type === 'pickup') {
      state.pickup = address;
    } else if (type === 'waypoints') {
      const waypoints = state.waypoints.map(waypoint => waypoint);
      waypoints[index] = address;
      state.waypoints = waypoints;
    }

  },
  removeLastWaypoint(state) {
    const waypoints = state.waypoints.map(waypoint => waypoint);

    if (waypoints.length > 0) {
      waypoints.pop();
      state.suggestions.waypoints.pop();
    }

    state.waypoints = waypoints;
  },
  removeWaypointIndex(state, index) {
    const waypoints = state.waypoints.map(waypoint => waypoint);

    if (waypoints.length > 0) {
      waypoints.splice(index, 1);
      state.suggestions.waypoints.splice(index, 1);
    }

    state.waypoints = waypoints;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
