<template>
<v-app class="booking-channel-missing-container">
  <v-content>
    <v-container fluid fill-height class="ma-0 pa-0">
      <v-row row wrap align-center>
        <v-col cols="12" sm6 md="4" offset-sm3 offset-md="4">
          <v-card color="black darken-2" class="white--text booking-channel-missing-card">
            <v-img
              src="/img/icons/i-cabbi-xl-white.svg"
              contain
              aspect-ratio="1.778"
              class="image-logo"
            ></v-img>
            <v-card-title primary-title class="booking-channel-missing-title">
              <div>
                <div class="headline">{{ $t('bookingChannelMissing.title', { hostname }) }}</div>
                <span>{{ $t('bookingChannelMissing.label') }}</span>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</v-app>
</template>

<script>
export default {
  name: 'BookingChannelMissing',
  components: {},
  data: () => ({
    hostname: window.location.hostname,
  }),
};
</script>

<style lang="scss" scoped>
.theme--light.v-application.booking-channel-missing-container {
  background-image: url('../../../public/img/missing-config.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.booking-channel-missing-card {
  max-height: 180px;
  padding-bottom: 5px;

  .booking-channel-missing-title {
    padding-top: 0px;
  }

  .image-logo {
    max-width: 75px;
    margin: 0 16px;
    padding-top: 24px;
  }
}
</style>
