import { render, staticRenderFns } from "./EmailLoginRedirect.vue?vue&type=template&id=010b4295&"
import script from "./EmailLoginRedirect.vue?vue&type=script&lang=js&"
export * from "./EmailLoginRedirect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports