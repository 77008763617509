import { PaymentMethodCommandClient } from '@icabbi/api-gateway-client';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-cycle
import apiInstance, { apiCall } from '../../helpers/apiHelpers';

const defaultState = {
  paymentMethodServiceConnector: null,
  stripeClient: null,
  clientSecret: null,
  paymentProviderName: null,
};

const storeGetters = {
  paymentMethodServiceConnector: (state, getters, rootState, rootGetters) => ({ dispatch }) => {
    if (!state.paymentMethodServiceConnector) {
      state.paymentMethodServiceConnector = apiInstance({ dispatch, getters: rootGetters, Connector: PaymentMethodCommandClient });
    }
    return state.paymentMethodServiceConnector;
  },
  paymentProviderName: (state, getters, rootState, rootGetters) => {
    if (!state.paymentProviderName) {
      state.paymentProviderName = rootGetters['bookingChannel/payment'].name;
    }
    return state.paymentProviderName;
  },
  stripeClient: async (state, getters, rootState, rootGetters) => {
    if (!state.stripeClient) {
      const { publishableKey } = rootGetters['bookingChannel/payment'].configuration;
      state.stripeClient = await loadStripe(publishableKey);
    }
    return state.stripeClient;
  },
};

const actions = {
  async resetSecret(context) {
    context.commit('clientSecret', null);
  },
  getPaymentMethods: apiCall(async (context) => {
    const paymentMethodsServiceConnector = context.getters.paymentMethodServiceConnector(context);
    return paymentMethodsServiceConnector.getCardsList({ paymentProviderName: context.getters.paymentProviderName });
  }),

  // eslint-disable-next-line consistent-return
  retrieveSecret: apiCall(async (context) => {
    const client = context.getters.paymentMethodServiceConnector(context);
    const response = await client.getClientSecret({ paymentProviderName: context.getters.paymentProviderName });
    context.commit('setSecret', response.secret);
  }),
  storeCard: apiCall(async (context, {
    cardElement, cardName, postalCode,
  }) => {
    const client = context.getters.paymentMethodServiceConnector(context);
    const result = await context.state.stripeClient.confirmCardSetup(context.state.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardName,
          address: {
            postal_code: postalCode,
          },
        },
      },
    });

    if (result.error) {
      console.log(result.error); // eslint-disable-line no-console
      context.dispatch('globalError/addError', {
        message: result.error.displayMessage,
        code: result.error.httpStatusCode,
        title: result.error.displayTitle,
      }, { root: true });
      return false;
    }

    const storedCard = await client.storeCard({ id: result.setupIntent.payment_method, paymentProviderName: context.getters.paymentProviderName });
    return {
      result: !storedCard,
      paymentId: result.setupIntent.payment_method,
    };
  }),
  deleteCard: apiCall(async (context, id) => {
    try {
      const client = context.getters.paymentMethodServiceConnector(context);
      await client.deleteCard({ id, paymentProviderName: context.getters.paymentProviderName });
      return true;
    } catch (error) {
      context.dispatch('globalError/addError', {
        message: error.displayMessage || error.message,
        code: error.httpStatusCode,
        title: error.displayTitle,
      }, { root: true });

      return false;
    }
  }),
};

const mutations = {
  setSecret(state, clientSecret) {
    state.clientSecret = clientSecret;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: storeGetters,
  actions,
  mutations,
};
