import passenger from './passenger';
import business from './business';
import ic2Account from './ic2Account';
import base from './base';

export default {
  passenger,
  business,
  ic2Account,
  base,
};
