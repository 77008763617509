<template>
  <v-row row wrap class="ma-0 pa-0 ride_tracking_container">
    <ride-tracking-map :booking="booking"></ride-tracking-map>
    <ride-tracking-summary :booking="booking"></ride-tracking-summary>
  </v-row>
</template>

<script>
import RideTrackingMap from '../shared/RideTrackingMap.vue';
import RideTrackingSummary from '../shared/RideTrackingSummary.vue';

export default {
  name: 'ride-tracking-default',
  components: {
    RideTrackingSummary,
    RideTrackingMap,
  },
  props: {
    booking: Object,
  },
  data() {
    return {
      screenName: 'public_ride_tracking',
    };
  },
  created() {
    this.$emit('trackByStatusPageView', { screenName: this.screenName, actionName: 'public_ride_tracking_viewed' });
  },
};
</script>

<style scoped>
.ride_tracking_container{
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.ride-tracking-map-container{
  flex: 2;
}
.ride_tracking_summary_container{
  z-index: 1;
  width: 100%;
}
/*Small to medium tablet (600px and up)*/
@media (min-width: 600px) {
  .ride_tracking_summary_container{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50%;
  }
  .ride-tracking-map-container{
    flex: 2;
  }
}

/*Large tablet to laptop(960px and up)*/
@media (min-width: 960px) {

}

/*Desktop (1264px and up)*/
@media (min-width: 1264px) {
  .ride_tracking_summary_container{
    position: absolute;
    top: 50px;
    left: 80px;
    width: 500px;
  }
}

/*4k and ultra-wide (1904px and up)*/
@media (min-width: 1904px) {

}
</style>
