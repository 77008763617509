<template>
  <v-row row wrap class="ma-0 pa-0 mobile-column-reverse">
    <ride-tracking-summary :booking="booking" :title="title" :hide-status="true"></ride-tracking-summary>
  </v-row>
</template>

<script>
import RideTrackingSummary from '../shared/RideTrackingSummary.vue';

export default {
  name: 'ride-tracking-default',
  components: {
    RideTrackingSummary,
  },
  props: {
    booking: Object,
  },
  mounted() {
    this.$emit('suggestedBookingRefreshInterval', 10000);
  },
  computed: {
    title() {
      const status = this.booking.status.toLowerCase();
      return `${status.charAt(0).toLocaleUpperCase()}${status.slice(1).replace(/(-)|(_)/g, ' ')}`; // eslint-disable-line no-useless-escape
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
