const { CustomerConfigurationServiceConnector: CustomerConfigurationServiceClient } = require('@icabbi/api-gateway-client/src/passenger');

const customerConfigurationServiceClient = new CustomerConfigurationServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

const defaultState = {
  publicAppConfiguration: null,
};

const getters = {
  getPublicAppConfiguration: state => state.publicAppConfiguration,
};

const actions = {
  async getPublicAppConfiguration({ commit, dispatch }, { bookingChannelId }) {
    try {
      const publicAppConfiguration = await customerConfigurationServiceClient.getPublicAppConfiguration({ bookingChannelId });
      commit('setPublicAppConfiguration', publicAppConfiguration);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch('globalError/addError', { message: 'customerConfiguration.retrievalError' }, { root: true });
    }
  },
};

const mutations = {
  setPublicAppConfiguration(state, publicAppConfiguration) {
    state.publicAppConfiguration = publicAppConfiguration;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
