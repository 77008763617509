<template>
  <td :colspan="headers.length" class="pt-3 pb-6">
    <v-row class="custom-padding-expand-details">
      <v-col cols="4">
        <Map
          source="expandItem"
          :pickup="getMapAddressData.pickup"
          :destination="getMapAddressData.destination"
          :waypoints="getMapAddressData.waypoints"
          :status="data.status"
          :markersDisabled="true"
          style="min-height: 23rem;"
        />
      </v-col>
      <v-col cols="8">
        <v-container class="expand-info-container">
          <v-row no-gutters style="height: fit-content">
            <v-col cols="4">
              <div class="d-flex flex-column">
                <span class="detail-info-title text-uppercase my-2">{{
                  $t(`bookings.expandItem.vehicleClass`)
                }}</span>
                <span class="detail-info-value">{{ getVehicleInfo }}</span>
              </div>
            </v-col>

            <v-divider vertical class="expand-divider"></v-divider>

            <v-col cols="4" class="pl-5">
              <div class="d-flex flex-column">
                <span class="detail-info-title text-uppercase my-2">{{
                  $t(`bookings.expandItem.tripId`)
                }}</span>
                <span class="detail-info-value">{{ data.dispatchId || `N/A` }}</span>
              </div>
            </v-col>

            <v-divider vertical class="expand-divider"></v-divider>

            <v-col cols="4" class="pl-5">
              <div class="d-flex flex-column">
                <span class="detail-info-title text-uppercase my-2">{{
                  $t(`bookings.expandItem.tripETA`)
                }}</span>
                <span class="detail-info-value">{{ calculateMileagues(data.distanceMeters) }}</span>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-2"></v-divider>

          <v-row no-gutters v-if="data.pricingDetails">
            <v-col cols="4" class="d-flex flex-column">
              <span class="detail-info-title text-uppercase mb-2">
                {{ $t("bookings.expandItem.pricing.title") }}
              </span>

              <div class="d-flex justify-space-between pr-3">
                <span class="detail-info-value">
                  {{ $t("bookings.expandItem.pricing.fare") }}:
                </span>

                <span class="detail-info-value">
                  {{ data.pricingDetails.price.toFixed(2) }}
                </span>
              </div>

              <div class="d-flex justify-space-between pr-3 my-2">
                <span class="detail-info-value"
                  >{{ $t("bookings.expandItem.pricing.additionalFees") }}:</span
                >

                <span class="detail-info-value">
                  {{ data.pricingDetails.additionalFees.toFixed(2) }}
                </span>
              </div>

              <!-- Details of additional fees -->
              <div>
                <div class="d-flex justify-space-between pr-3 mt-1">
                  <span class="price-details"
                    >{{
                      $t("bookings.expandItem.pricing.evaluatedServiceCharge")
                    }}:</span
                  >

                  <span class="price-details"
                    >{{
                      data.pricingDetails.additionalFeeLineItems.evaluatedServiceCharge.toFixed(
                        2
                      )
                    }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details"
                    >{{ $t("bookings.expandItem.pricing.waitingPrice") }}:</span
                  >

                  <span class="price-details">
                    {{
                      data.pricingDetails.additionalFeeLineItems.waitingPrice.toFixed(2)
                    }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details"
                    >{{ $t("bookings.expandItem.pricing.tolls") }}:</span
                  >

                  <span class="price-details">
                    {{ data.pricingDetails.additionalFeeLineItems.tolls.toFixed(2) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details"
                    >{{ $t("bookings.expandItem.pricing.extras") }}:</span
                  >

                  <span class="price-details">
                    {{ data.pricingDetails.additionalFeeLineItems.extras.toFixed(2) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">
                    {{ $t("bookings.expandItem.pricing.tip") }}:
                  </span>

                  <span class="price-details">
                    {{ configuredTip.value.toFixed(2) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between pr-3 mt-2">
                  <span class="price-details">
                    {{ $t("bookings.expandItem.pricing.discount") }}:
                  </span>

                  <span class="price-details">
                    {{ data.pricingDetails.totalDiscount.toFixed(2) }}
                  </span>
                </div>
              </div>

              <v-divider class="my-2 mr-3 expand-divider"></v-divider>

              <div class="d-flex justify-space-between pr-3 my-2">
                <span class="detail-info-value">
                  {{ $t("bookings.expandItem.pricing.total") }}
                </span>

                <span class="detail-info-value">
                  {{ currencySymbol }}
                  {{ getBookingTotalPrice() }}
                </span>
              </div>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col
              v-if="
                data.businessAccountConfig && data.businessAccountConfig.fields
              "
              cols="8"
            >
              <v-row no-gutters>
                <v-col
                  v-for="(item, index) in data.businessAccountConfig.fields"
                  :key="index"
                  class="d-flex"
                  cols="6"
                >
                  <v-divider v-if="index !== 0" vertical class="expand-divider"></v-divider>

                  <div class="d-flex flex-column pl-4">
                    <span class="detail-info-title text-uppercase my-2">{{ item.title }}</span>
                    <span class="detail-info-value">{{ item.value }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider v-if="data.pricingDetails" class="my-2 expand-divider"></v-divider>

          <v-row no-gutters v-if="data.notes">
            <v-col class="d-flex flex-column mt-2">
              <span class="detail-info-title text-uppercase mb-2">
                {{ $t(`bookings.expandItem.notes`) }}:
              </span>
              <span class="detail-info-value">{{ data.notes }}</span>
            </v-col>
          </v-row>

          <v-divider v-if="data.notes" class="my-2"></v-divider>

          <v-row
            justify="space-between"
            class="align-center expand-actions-container mt-4"
            no-gutters
          >
            <v-col
              class="d-flex flex-row-reverse col-without-bottom-padding col-without-top-padding"
            >
              <v-btn
                outlined
                v-if="data.status === 'PREBOOKED'"
                @click="editBooking(data.bookingId)"
                class="expand-button expand-white ml-2"
              >
                {{ $t("button.edit_booking") }}
              </v-btn>

              <v-btn
                class="expand-button expand-black ml-2"
                outlined
                tile
                @click="copyBooking(data.bookingId)"
                >{{ $t(`button.reuse`) }}</v-btn
              >

              <v-btn
                v-if="getBooking && getBooking.publicRideTrackingConfig.url && showTmTLink"
                :href="getBooking.publicRideTrackingConfig.url"
                target="_blank"
                outlined
                tile
                class="expand-button expand-black ml-2"
              >
                {{ $t(`button.track`) }}
              </v-btn>
              <v-btn
                v-if="canCancelTrip"
                class="expand-button expand-white"
                outlined
                tile
                @click="openDialog('cancel-booking')"
                >{{ $t(`button.cancel`) }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <dialog-wrapper ref="dialogComponent" :width="400">
      <template v-slot:content>
        <cancel-form />
      </template>
    </dialog-wrapper>
  </td>
</template>

<script>
import { mapGetters } from 'vuex';
import Map from '@/views/maps/Map.vue';
import CancelForm from '@/components/elements/CancelForm.vue';
import DialogWrapper from '@/components/elements/DialogWrapper.vue';

export default {
  name: 'expan-item',
  components: {
    DialogWrapper,
    CancelForm,
    Map,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('rideTracking/getById', { bookingId: this.data.bookingId });
  },
  methods: {
    openDialog(type) {
      this.$refs.dialogComponent.openDialog({
        id: this.data.bookingId,
        type,
        fee: this.data.prematureCancellationFee,
      });
    },
    copyBooking(id) {
      this.$router.push({ name: 'booking-copy', params: { id } });
    },
    editBooking(id) {
      this.$router.push({ name: 'booking-edit', params: { id } });
    },
    calculateMileagues(distance) {
      if (!distance) return 'N/A';
      return `${(distance * 0.000621371).toFixed(2)} ml`;
    },
    getBookingTotalPrice() {
      return this.data.pricingDetails.total.toFixed(2);
    },
  },
  computed: {
    ...mapGetters({
      communicationLanguage: 'userProfile/communicationLanguage',
      getBooking: 'rideTracking/getBooking',
      currencySymbol: 'bookingChannel/currencySymbol',
    }),
    getMapAddressData() {
      return {
        pickup: {
          description: this.data.origin.address,
          latitude: this.data.origin.latitude,
          longitude: this.data.origin.longitude,
        },
        destination: {
          description: this.data.destination.address,
          latitude: this.data.destination.latitude,
          longitude: this.data.destination.longitude,
        },
        waypoints: this.data.waypoints.map(waypoint => ({
          description: waypoint.address,
          latitude: waypoint.latitude,
          longitude: waypoint.longitude,
        })),
      };
    },
    getVehicleInfo() {
      if (this.data.vehicle) {
        return `${this.data.vehicle.make} ${this.data.vehicle.model}`;
      }

      return '-';
    },
    isTipConfigured() {
      return this.data.pricingDetails.tip.value > 0;
    },
    configuredTip() {
      return this.data.pricingDetails.tip;
    },
    wasTipAppliedToFinalFare() {
      return this.data.pricing.tip > 0;
    },
    pricingModel() {
      return this.data.pricingDetails;
    },
    wasNoShowFeeApplied() {
      return this.data.pricingDetails.passengerNoShowFee > 0;
    },
    wasPrematureCancellationFeeApplied() {
      return this.data.pricingDetails.passengerPrematureCancellationFee > 0;
    },
    showTmTLink() {
      return [
        'NEW',
        'ASSIGN',
        'ENROUTE',
        'PREBOOKED',
        'ARRIVED',
        'POB',
        'ONBOARD',
        'DROPPINGOFF',
      ].includes(this.data.status);
    },
    canCancelTrip() {
      return ['NEW', 'ASSIGN', 'ENROUTE', 'PREBOOKED'].includes(this.data.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-padding-expand-details {
  padding-bottom: 10px !important;
}
.price-details {
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  letter-spacing: -0.005em;
  text-align: right;
  color: #888888;
}
.expand-info-container {
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.expand-divider {
  height: 48px;
  background: #eeeeee;
}
img {
  width: 100%;
  min-height: 115px;
  height: 100%;
}
.map-image {
  background-image: url("../../../../assets/map.png");
  background-size: auto 100%;
  height: 150px;
}
.detail-info-title {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #888888;
}
.detail-info-value {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #333333;
}

.expand-divider {
  background: #eeeeee;
}

.additional-fees span {
  font-size: 12px;
  font-weight: normal;
}

.expand-links {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  text-decoration-line: underline;
  color: #888888;
  cursor: pointer;
}

.expand-button {
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.005em;
}

.expand-button::before {
  background-color: transparent !important;
}
.expand-white {
  color: #333333;
  background: #ffffff;
}

.expand-black {
  background-color: #333333;
  color: #ffffff;
}

.expand-black:hover {
  background-color: #888888;
  border: 1px solid #888888;
}

.expand-white:hover {
  color: #888888;
  border: 1px solid #888888;
}
</style>
