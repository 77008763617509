<template>
  <loader :is-loading="loading" color="primary">
    <v-col>
      <v-alert
        :value="invalidCallback"
        type="error"
      >
        {{ $t('authentication.error') }}
      </v-alert>
    </v-col>
  </loader>
</template>
<script>
import { mapGetters } from 'vuex';
import { triggerFirebaseEvent } from '@/events/firebase';
import { exchangeCodeForToken } from '../../authentication/authenticationManager';

export default {
  name: 'OAuthCallback',
  async created() {
    if (this.isValidCallback(this.$route.query)) {
      this.loading = true;
      await exchangeCodeForToken(this.$route.query.code, this.loginContext, this.clientId, this.redirectUri);
      await this.$store.dispatch('authentication/setFieldsFromIdToken');
      await this.$store.dispatch('authentication/setAnalyticsId');
      triggerFirebaseEvent({
        firebaseAnalytics: this.firebaseAnalytics,
        eventName: 'exchange_token',
        payload: {
          firebaseUserId: this.user.id,
        },
      });
      this.$router.push(this.loginContext.to.path);
      this.$store.dispatch('authentication/clearLoginContext');
      this.loading = false;
    } else {
      await this.$store.dispatch('globalError/setAuthError', { message: this.getError(this.$route.query) });
      this.$router.push('/');
    }
  },
  data() {
    return {
      invalidCallback: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      loginContext: 'authentication/loginContext',
      token: 'authentication/token',
      clientId: 'bookingChannel/oidcClientId',
      redirectUri: 'bookingChannel/authCallbackUrl',
      firebaseAnalytics: 'firebase/firebaseAnalytics',
      user: 'userProfile/user',
    }),
  },
  methods: {
    isValidCallback(query) {
      return query.state
          && this.loginContext
          && this.loginContext.state
          && !query.error
          && query.state === this.loginContext.state;
    },
    getError(query) {
      if (query.error) {
        console.log('error', query.error);
        console.log('description', query.error_description);
        return query.error_description;
      }
      if (!this.loginContext || !this.loginContext.state) {
        return this.$t('authentication.invalidContext');
      }
      if (!query.state || !(query.state === this.loginContext.state)) {
        return this.$t('authentication.invalidState');
      }
      return this.$t('authentication.error');
    },
  },
};
</script>

<style scoped>
</style>
