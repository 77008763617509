<template>
<v-app class="cookie-container">
  <v-content>
    <v-container fluid fill-height class="ma-0 pa-0">
      <v-row row wrap align-center>
        <v-col cols="12" sm6 md="4" offset-sm3 offset-md="4">
          <v-card color="black darken-2" class="white--text cookie-card">
            <v-img
              src="/img/icons/i-cabbi-xl-white.svg"
              contain
              aspect-ratio="1.778"
              class="image-logo"
            ></v-img>
            <v-card-title primary-title class="cookie-title">
              <div>
                <div class="headline">{{ $t('cookies.title') }}</div>
                <span>{{ $t('cookies.label') }}</span>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</v-app>
</template>

<script>
export default {
  name: 'CookieDisabled',
  components: {},
};
</script>

<style lang="scss" scoped>
.theme--light.v-application.cookie-container {
  background-image: url('../../../public/img/cookies.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.cookie-card {
  max-height: 180px;
  padding-bottom: 5px;

  .cookie-title {
    padding-top: 0px;
  }

  .image-logo {
    max-width: 75px;
    margin: 0 16px;
    padding-top: 24px;
  }
}
</style>
