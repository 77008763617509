<template>
  <loader :is-loading="isLoading" color="primary">
    <v-col lg="4" md6 sm="8" cols="10">
      <v-card flat id="payment-method-card">
        <v-card-title id="payment-method-title">{{ $t('paymentMethod.form.title') }}</v-card-title>
        <v-card-text id="payment-method-form">
          <validated-component
            name="cardholderName"
            v-model="cardholderName"
            :validator="$v.billingDetails.name"
          >
            <v-text-field
              class="payment-method-field"
              v-model="cardholderName"
              :label="$t('paymentMethod.form.cardholderName')"
              :placeholder="$t('paymentMethod.form.cardholderName')"
              text
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
          <validated-component
            name="line1"
            v-model="line1"
            :validator="$v.billingDetails.address.line1"
          >
            <v-text-field
              class="payment-method-field"
              v-model="line1"
              :label="$t('paymentMethod.form.addressLine1')"
              :placeholder="$t('paymentMethod.form.addressLine1')"
              text
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
          <v-text-field
            class="payment-method-field"
            v-model="line2"
            :label="$t('paymentMethod.form.addressLine2')"
            :placeholder="$t('paymentMethod.form.addressLine2')"
            text
            hide-details
            no-filter
          ></v-text-field>
          <validated-component
            name="postal_code"
            v-model="postal_code"
            :validator="$v.billingDetails.address.postal_code"
          >
            <v-text-field
              class="payment-method-field"
              v-model="postal_code"
              :label="$t('paymentMethod.form.postCode')"
              :placeholder="$t('paymentMethod.form.postCode')"
              text
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
          <validated-component
            name="city"
            v-model="city"
            :validator="$v.billingDetails.address.city"
          >
            <v-text-field
              class="payment-method-field"
              v-model="city"
              :label="$t('paymentMethod.form.city')"
              :placeholder="$t('paymentMethod.form.city')"
              text
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
          <validated-component
            name="country"
            v-model="country"
            :validator="$v.billingDetails.address.country"
          >
            <country-picker
              class="payment-method-field"
              v-model="country"
              :label="$t('paymentMethod.form.country')"
              :placeholder="$t('paymentMethod.form.country')"
            ></country-picker>
          </validated-component>
          <component
            ref="card"
            v-bind:is="cardComponentName"
            :billing-details="billingDetails"
            :label="$t('paymentMethod.form.state')"
          ></component>
          <div role="alert" :if="error">{{error}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="saveLoading"
            :disabled="disableSubmit"
            class="submit-button primaryFont--text"
            @click="saveCard"
            depressed
          >{{ $t('paymentMethod.form.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';

import StripeCardInput from './StripeCardInput.vue';
import CountryPicker from '../shared/inputs/CountryPicker.vue';
import paymentMethodValidation from '../../validation/paymentMethodValidationData';

export default {
  name: 'payment-method-form',
  components: { CountryPicker, StripeCardInput },
  data() {
    return {
      screenName: 'payment_methods',
      error: null,
      saveLoading: false,
      validationData: paymentMethodValidation,
      cardholderName: '',
      line1: '',
      line2: '',
      postal_code: '',
      country: '',
      city: '',
    };
  },
  validations() {
    return paymentMethodValidation;
  },
  mounted() {
    this.$refs.card.init();
  },
  computed: {
    ...mapGetters({
      isLoading: 'paymentMethods/paymentMethodLoading',
      paymentConfiguration: 'bookingChannel/payment',
    }),
    cardComponentName() {
      if (this.paymentConfiguration && this.paymentConfiguration.name) {
        return `${this.paymentConfiguration.name}CardInput`;
      }
      return 'StripeCardInput'; // default
    },
    billingDetails() {
      return {
        address: {
          city: this.city,
          country: this.country,
          line1: this.line1,
          line2: this.line2,
          postal_code: this.postal_code,
        },
        name: this.cardholderName,
      };
    },
    disableSubmit() {
      return this.$v.$invalid;
    },
  },
  methods: {
    async saveCard() {
      try {
        this.saveLoading = true;
        const result = await this.$refs.card.saveCard();
        if (result) {
          this.$router.push('/payment-methods');
        }
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>


<style scoped lang="scss">
#payment-method-title {
  border-bottom: 1px solid #c4c4c4;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.payment-method-form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
}

@media only screen and (max-width: 960px) {
  .submit-button {
    width: 100%;
  }
}

.v-card__title {
  padding: 16px 0 4px;
}

.v-card__actions {
  padding: 16px 0;
  border-top: 1px solid #c4c4c4;
}

.v-card__text {
  padding: 16px 0;
}
</style>

<style lang="scss">
#payment-method-card {
  margin-top: 44px;
}

.payment-method-field {
  margin-top: 10px !important;
  :after {
    display: none;
  }

  .v-input__control {
    .v-input__slot {
      border: 1px solid #e4e4e4 !important;
      label {
        top: -1px;
        margin-left: 2px;
        text-transform: uppercase;
        font-size: 12px;
        color: black;
      }

      .primary--text {
        color: black !important;
      }

      &:before {
        border: none !important;
      }

      &:after {
        border: none;
      }

      input {
        padding-left: 10px;
        caret-color: black;
      }
    }
  }
}
</style>
