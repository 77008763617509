<template>
  <v-container fluid class="align-start align-content-start fill-height">
    <booking-detail
      @unselect="unselect"
      :selected-booking-id="selectedBookingId"
      :current-filter="filters[tab].value"></booking-detail>
    <list
      class="px-0 px-md-10 mt-15 mt-md-5"
      list-key="id"
      :desktop-list="desktopList"
      :mobile-list="desktopList"
      :desktop-headers="desktopHeaders"
      :itemsPerPage="maxPerPage"
      @itemSelected="setSelectedBooking"
    >
      <template v-slot:title>
        <span class="desctop-bs-header-title">
          {{ $t('bookings.title') }}
        </span>
      </template>
      <template v-slot:filters>
        <v-row justify="space-between" class="align-end mt-2 mt-md-0">
          <v-col v-if="!$vuetify.breakpoint.mobile" class="d-flex direction-column">
            <div
              v-for="filter in filters"
              :key="filter.label"
              class="tabs-text mr-6"
              :class="{ 'tabs-text-active': selectedFilter.includes(filter.value) }"
              @click="() => filterBookings(filter, 1)"
            >
              {{ $t(filter.label) }}
            </div>
          </v-col>
          <v-col v-else cols="12" md="auto" class="pb-1 d-flex align-center">
            <v-select
              v-show="!searchShow"
              :value="filters[tab]"
              :items="filters"
              item-text="label"
              item-value="value"
              class="mx-0 px-0"
              height="30px"
              dense
              outlined
              hide-details
              return-object
              @change="($event) => filterBookings($event, 1)"
            >
              <template v-slot:item="{ item }">
                {{ $t(item.label) }}
              </template>
              <template v-slot:selection="{ item }">
                {{ $t(item.label) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="auto" class="pb-1 d-flex align-end">
            <v-btn to="book" class="add-bs-button" :height="40" color="#333333" depressed width="100%">
              <v-icon class="mr-1">mdi-plus</v-icon>
              {{ $t('bookings.newBooking') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:pagination>
        <div class="py-1 px-4 desctop-bs-table-footer">
          <v-row no-gutters class="desctop-bs-per-page">
            <span>
              {{ $t(`bookings.perPage`) }}
            </span>
            <span
              v-for="item  in [10, 20, 30]"
              :key="item"
              :class="{ 'active-page-count': item === maxPerPage }"
              class="px-1"
              @click="maxPerPage = item"
            >
              {{ item }}
            </span>
          </v-row>
          <v-pagination
            v-model="page"
            :length="numberOfPage"
            :total-visible="5"
            color="#f2f2f2"
            class="desctop-bs-pagination"
          ></v-pagination>
        </div>
      </template>
    </list>
  </v-container>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import List from '../shared/list/List.vue';
import BookingDetail from './BookingDetail.vue';
import BookingMixin from './mixin/bookingMixin';

export default {
  name: 'BookingList',
  components: {
    BookingDetail,
    List,
  },
  mixins: [BookingMixin],
  created() {
    const routeTab = get(this.$route, 'params.tab');
    const tab = routeTab ? this.filters.filter(val => val.value === routeTab)[0] : this.filters[1];
    this.tab = this.filters.indexOf(tab);
    this.filterBookings(tab, 1);
  },
  mounted() {
    this.time = setInterval(() => {
      this.getBookings(this.page);
    }, 20000);
  },
  watch: {
    page(newValue) {
      this.getPage(newValue);
    },
    locale() {
      this.$forceUpdate();
    },
    maxPerPage() {
      this.getPage(1);
    },
    tab: {
      handler(newValue) {
        // eslint-disable-next-line security/detect-object-injection
        this.$store.commit('bookings/setBookingTab', this.filters[newValue].value);
      },
      immediate: true,
    },
  },
  data() {
    return {
      screenName: 'booking_history',
      selectedBookingId: null,
      selectedFilter: null,
      primaryColor: null,
      page: 1,
      tab: 0,
      maxPerPage: 10,
      pageCounts: [10, 20, 50],
      filters: [
        {
          value: 'all',
          label: 'bookings.all',
        },
        {
          value: 'current',
          label: 'bookings.current',
        },
        {
          value: 'future',
          label: 'bookings.future',
        },
        {
          value: 'past',
          label: 'bookings.past',
        },
        {
          value: 'cancelled',
          label: 'bookings.cancelled',
        },
      ],
      searchShow: false,
    };
  },
  computed: {
    ...mapGetters({
      bookings: 'bookings/bookings',
      bookingsLoading: 'bookings/bookingsLoading',
      totalCount: 'bookings/totalCount',
      currencySymbol: 'bookingChannel/currencySymbol',
    }),
    unavailable() {
      return this.$t('bookings.unavailable');
    },
    desktopList() {
      return this.bookings
        .sort((a, b) => {
          if (a.pickupTime < b.pickupTime) return 1;
          if (a.pickupTime > b.pickupTime) return -1;
          return 0;
        }).map(booking => ({
          id: booking.bookingId,
          origin: get(booking, 'origin.address', this.unavailable),
          destination: get(booking, 'destination.address', this.unavailable),
          waypoints: get(booking, 'waypoints', []),
          payment: get(booking, 'payment', this.unavailable),
          pickupDate: this.formatPickupDate(booking.pickupTime, this.locale, this.unavailable),
          pickupTime: this.formatPickupTime(booking.pickupTime, this.locale, this.unavailable),
          vehicleType: this.formatVehicleType(booking.vehicle, this.unavailable),
          destinationEtaMinutes: this.formatDestinationEta(booking, this.locale, this.unavailable),
          estimatedTimeArrival: this.formatPickupEta(booking, this.locale, this.unavailable),
          price: this.formatPrice(booking),
          companyName: get(booking, 'companyName', this.unavailable),
          status: booking.status,
        }));
    },
    hasPage() {
      return this.totalCount > this.maxPerPage;
    },
    locale() {
      return this.$i18n.locale;
    },
    numberOfPage() {
      return Math.ceil(this.totalCount / this.maxPerPage);
    },
    filterNotCompleted() {
      return this.selectedFilter && this.selectedFilter.value !== 'past';
    },
    desktopHeaders() { // eslint-disable-line max-lines-per-function
      const headers = [
        {
          text: this.$t('bookings.pickupDate'),
          align: 'left',
          sortable: true,
          value: 'pickupDate',
        },
        {
          text: this.$t('bookings.pickupTime'),
          align: 'left',
          sortable: true,
          value: 'pickupTime',
        },
        {
          text: this.$t('bookings.name'),
          align: 'left',
          sortable: false,
          value: 'companyName',
        },
        {
          text: this.$t('bookings.origin'),
          align: 'left',
          sortable: false,
          value: 'origin',
        },
        {
          text: this.$t('bookings.destination'),
          align: 'left',
          sortable: false,
          value: 'destination',
        },
        {
          text: this.$t('bookings.payment'),
          align: 'center',
          sortable: false,
          value: 'payment',
        },
        {
          text: this.$t('bookings.pricing'),
          align: 'center',
          sortable: false,
          value: 'price',
        },
        {
          align: 'right',
          sortable: false,
          value: 'status',
        },
      ];

      return headers;
    },
  },
  methods: {
    setSelectedBooking(selectedBooking) {
      this.selectedBookingId = selectedBooking.id;
    },
    unselect() {
      this.selectedBookingId = null;
    },
    getPage(page) {
      this.getBookings(page);
    },
    filterBookings(filter, page) {
      this.selectedFilter = filter.value === 'all'
        ? ['current', 'past', 'future', 'cancelled']
        : [filter.value];

      this.page = page;
      this.getBookings(page);
    },
    getBookings(page) {
      this.$store.dispatch('bookings/list', {
        filter: this.selectedFilter,
        maxPerPage: this.maxPerPage,
        sort: '-pickupTime',
        page: page - 1,
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
};
</script>

<style scoped>
.tabs-text {
  display: block;
  cursor: pointer;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #aaaaaa;
}

.tabs-text-active {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #333333;
}
  .filter {
    padding: 0 5px;
    margin: 5px;
  }

  .add-bs-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.005em;
    color: #FFFFFF !important;
    text-transform: initial;
    border-radius: 6px;
  }
</style>
