const defaultState = {
  features: {
    businessAccountEnabled: process.env.VUE_APP_FEATURE_BUSINESS_ACCOUNTS === 'true',
    paymentMethods: process.env.VUE_APP_FEATURE_PAYMENT_METHODS === 'true',
    emailVerification: process.env.VUE_APP_FEATURE_EMAIL_VERIFICATION === 'true',
  },
};

const getters = {
  paymentMethods: state => state.features.paymentMethods,
  emailVerification: state => state.features.emailVerification,
  businessAccountEnabled: state => state.features.businessAccountEnabled,
  features: state => state.features,
};

const actions = {};
const mutations = {};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
