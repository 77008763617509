<template>
  <loader :is-loading="emailConfirmationLoading" color="primary">
    <v-card light id="email-confirmation">
      <v-card-title id="email-confirmation-title">
        <span v-if="emailConfirmed">{{ $t('emailConfirmation.success.thankYou') }}</span>
        <span v-else>{{ $t('emailConfirmation.failure.sorry') }}</span>
      </v-card-title>
      <v-card-text class="center">
        <v-row justify-space-between column fill-height align-center>
          <v-col md9 id="email-confirmation-text-title">
            <span v-if="emailConfirmed">{{ $t('emailConfirmation.success.title') }}</span>
            <span v-else>{{ $t('emailConfirmation.failure.title') }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </loader>
</template>

<script>
import _ from 'lodash';

/* eslint-disable */
export default {
  name: 'email-confirmation',
  mounted() {
    this.$i18n.locale = _.get(this.$route,'query.lang') || 'en-uk';
  },
  props: {
  },
  data() {
    return {
      emailConfirmationLoading: true,
      emailConfirmed: false,
      dialog: false,
    };
  },
  components: {
  },
  created() {
    this.isEmailConfirmed();
  },
  ready: function() {
    this.emailConfirmationLoading = false;
  },
  methods: {
    async isEmailConfirmed() {
      const { oobCode } = this.$route.query;
      const response = await this.$store.dispatch('userProfile/confirmEmail', { oobCode });
      if (response) {
        const { confirmed } = response || false;
        this.emailConfirmed = confirmed;
      }
      this.emailConfirmationLoading = false;
      return this.emailConfirmed;
    },
  },
};
</script>

<style scoped>

  #email-confirmation {
    width: 520px;
    margin: 20% 5%;
    font-family: Inter;
  }

  #email-confirmation-title {
    color: #ffffff;
    background-color: #27AF3D;
    font-weight: bold;
  }

  #email-confirmation-text-title {
    font-weight: bold;
    font-size: 20px;
  }

</style>
