/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-cycle
import store from '../store';
import { getLoginRedirectionUrl, getCodeVerifier, getState, getCodeChallenge, getExchangeCodeForTokenPayload, getTokenExpirationFromDuration } from './authenticationHelpers';

export async function handleAuthentication(guardCallback) {
  const token = store.getters['authentication/token'];
  if (token) {
    await guardCallback();
    return;
  }
  location.replace('/');
}

export async function exchangeCodeForToken(code, loginContext, clientId, redirectUri) {
  const payload = getExchangeCodeForTokenPayload(code, loginContext, clientId, redirectUri);
  await store.dispatch('authentication/exchangeCodeForToken', payload);
}

export function logout(idToken, postLogoutUrl) {
  location.replace(`${process.env.VUE_APP_LOGOUT_REDIRECTION_URL}?id_token_hint=${idToken}&post_logout_redirect_uri=${postLogoutUrl}`);
}

export function getBearer() {
  return `Bearer ${store.getters['authentication/token']}`;
}

export async function getLoginUrl(to, audience, acrValues, requestUrl) {
  const verifier = getCodeVerifier();
  const codeChallenge = getCodeChallenge(verifier);
  const state = getState();
  await store.dispatch('authentication/setLoginContext', { verifier, codeChallenge, state, to });
  const clientId = store.getters['bookingChannel/oidcClientId'];
  const redirectUri = store.getters['bookingChannel/authCallbackUrl'];
  const locale = store.getters['language/selectedLanguage'];
  return getLoginRedirectionUrl(state, codeChallenge, clientId, redirectUri, locale, audience, acrValues, requestUrl);
}

export async function executeRefreshToken(from = '/book') {
  await store.dispatch('authentication/refreshToken', { redirectTo: from });
}

// Duration in ms
export async function executeSilentRefreshToken(duration = 600000) {
  if (parseInt(store.getters['authentication/tokenExpiredAt'], 10) < getTokenExpirationFromDuration(duration)) {
    await store.dispatch('authentication/refreshToken');
  }
}
