<template>
  <v-flex>
    <v-card flat class="pa-5">
      <v-card-title>
        <div class="d-flex justify-space-between w-full">
          <span class="pb-2 form-title">{{$t('paymentMethods.newCard.title')}}</span>
          <v-icon @click="closeDialog">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h5 class="section-title mb-1 input-label">{{$t('paymentMethods.newCard.card.name')}}</h5>
            <v-text-field
              outlined
              filled
              hide-details
              background-color="#F8F8F8"
              :placeholder="$t('paymentMethods.newCard.card.name_placeholder')"
              :class="{ 'input-field-error': $v.cardName.$error }"
              v-model.trim="$v.cardName.$model">
              <template v-if="$v.cardName.$anyError" v-slot:append>
                <error-tooltip :validator="$v.cardName"/>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="section-title input-label mb-1">{{$t('paymentMethods.newCard.card.number')}}</h5>
            <div class="card-container" ref="cardNumber" id="card-ref-input"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="section-title mb-1 input-label">{{$t('paymentMethods.newCard.card.expiry')}}</h5>
            <div class="card-container" ref="expiry" id="card-expiry-input"></div>
          </v-col>
          <v-col>
            <h5 class="section-title mb-1 input-label">{{$t('paymentMethods.newCard.card.cvc')}}</h5>
            <div class="card-container" ref="cvc" id="card-cvc-input"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="section-title mb-1 input-label">{{$t('paymentMethods.newCard.card.postalCode')}}</h5>
            <v-text-field
              outlined
              filled
              hide-details
              maxLength="20"
              background-color="#F8F8F8"
              :placeholder="$t('paymentMethods.newCard.card.postcode_placeholder')"
              :class="{ 'input-field-error': $v.postalCode.$error }"
              v-model.trim="$v.postalCode.$model">
              <template v-if="$v.postalCode.$anyError" v-slot:append>
                <error-tooltip :validator="$v.postalCode"/>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-btn
          class="btn btn-flat"
          text
          @click="closeDialog"
        >
          {{$t('paymentMethods.newCard.actionButtons.cancel')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="btn primary"
          @click="addCard"
          :disabled="isLoading"
          :loading="isLoading"
        >
          {{$t('paymentMethods.newCard.actionButtons.add')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { paymentMethods } from '../../mixins/paymentMethod.mixins';
import ErrorTooltip from '../../components/elements/ErrorTooltip.vue';

export default {
  name: 'AddCard',
  components: { ErrorTooltip },
  mixins: [paymentMethods],
  data() {
    return {
      cardName: '',
      postalCode: '',
      cardElements: {},
      isLoading: false,
      validRefs: {
        cardNumber: false,
        cvc: false,
        expiry: false,
      },
      formReset: false,
      corporations: [],
      groups: [],
      selectedGroups: [],
      corporation: null,
      corporationsLoading: false,
      groupsLoading: false,
    };
  },
  validations: {
    cardName: { required },
    postalCode: { required },
  },
  async mounted() {
    await this.getSecret();
    this.initStripePaymentComponents();
  },
  computed: {
    ...mapGetters('bookingChannel', ['payment']),
  },
  methods: {
    listenForErrors() {
      this.cardElements.number.on('change', ({ complete }) => {
        this.handleError(complete, 'cardNumber');
      });

      this.cardElements.cvc.on('change', ({ complete }) => {
        this.handleError(complete, 'cvc');
      });

      this.cardElements.expiry.on('change', ({ complete }) => {
        this.handleError(complete, 'expiry');
      });
    },
    handleError(complete, ref) {
      this.validRefs[ref] = complete;
      if (complete) {
        this.$refs[ref].classList.remove('error-input');
      } else {
        this.$refs[ref].classList.add('error-input');
      }
    },
    initStripePaymentComponents() {
      const style = {
        base: {
          '::placeholder': {
            color: 'rgba(0, 0, 0, 0.28)',
          },
        },
      };
      const locale = this.$i18n.locale.substr(0, 2);
      const elements = this.stripeClient.elements({ locale });
      this.cardElements.number = elements.create('cardNumber', {
        style,
      });
      this.cardElements.number.mount('#card-ref-input');

      this.cardElements.expiry = elements.create('cardExpiry', {
        style,
      });
      this.cardElements.expiry.mount('#card-expiry-input');

      this.cardElements.cvc = elements.create('cardCvc', {
        style,
      });
      this.cardElements.cvc.mount('#card-cvc-input');

      this.listenForErrors();
    },
    // eslint-disable-next-line consistent-return
    hasErrors() {
      if (Object.values(this.validRefs).filter(val => !val).length > 0) {
        Object.keys(this.validRefs).forEach((ref) => {
          if (!this.validRefs[ref]) {
            this.$refs[ref].classList.add('error-input');
          }
        });
        return true;
      }
    },
    // eslint-disable-next-line consistent-return
    async addCard() {
      if (this.hasErrors() || this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }
      try {
        this.isLoading = true;
        const response = await this.saveCard({
          cardElement: this.cardElements.number,
          cardName: this.cardName,
          postalCode: this.postalCode,
        });
        if (response.result) {
          this.$emit('methodAdded', { paymentId: response.paymentId });
          this.closeDialog();
        }
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
  beforeDestroy() {
    this.cardElements.number.destroy('#card-ref-input');
    this.cardElements.expiry.destroy('#card-expiry-input');
    this.cardElements.cvc.destroy('#card-cvc-input');
  },

};
</script>

<style scoped>
.card-container {
  min-height: 42px;
  background: #f8f8f8;
  border: 1px solid #efefef;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  padding: 12px;
  border-radius: 4px;
}
.card-container.error-input{
  border: 1px solid red;
}
.v-input {
  font-weight: normal;
  font-size: 14px !important;
  color: #000 !important;
}
</style>
