import ApplePay from '@/assets/icons/paymentMethod/iconApple.vue';
import GooglePay from '@/assets/icons/paymentMethod/iconGoogle.vue';
import VisaCard from '@/assets/icons/paymentMethod/iconVisa.vue';
import MasterCard from '@/assets/icons/paymentMethod/iconMaster.vue';
import AmexCard from '@/assets/icons/paymentMethod/iconAmex.vue';
import DiscoverCard from '@/assets/icons/paymentMethod/iconDiscover.vue';
import UnknownCard from '@/assets/icons/paymentMethod/iconUnknown.svg';
import DefaultCard from '@/assets/icons/paymentMethod/defaultCard.svg';
import PaymentBusiness from '@/assets/icons/paymentMethod/paymentBusiness.svg';
import NewCard from '@/assets/icons/paymentMethod/newCard.svg';
import PayInCar from '@/assets/icons/paymentMethod/iconPayInCar.svg';
import BusinessAccount from '@/assets/icons/paymentMethod/iconBusinessAccount.svg';

import IcabbiCar from '@/assets/icons/icabbi-car.svg';
import DriverStateCar from '@/assets/icons/driver-state.svg';

import BookingMapLocation from '@/assets/icons/bookingForm/map-location.svg';

import Bookings from '@/assets/icons/sidemenu/bookings.svg';
import BookTrip from '@/assets/icons/sidemenu/bookTrip.svg';
import PaymentMethod from '@/assets/icons/sidemenu/paymentMethod.svg';
import ExpandIconClose from '@/assets/icons/expand-icon-close.svg';
import ExpandIconOpen from '@/assets/icons/expand-icon-open.svg';
import AccountIcon from '@/assets/icons/sidemenu/accountIcon.svg';
import FavouritesIcon from '@/assets/icons/sidemenu/favourites.svg';
import AboutIcon from '@/assets/icons/sidemenu/about.svg';
import TermsAndConditionsIcon from '@/assets/icons/sidemenu/termsAndConditions.svg';
import LanguageIcon from '@/assets/icons/sidemenu/language.svg';
import TripConfirmed from '@/assets/icons/tripConfirmed.svg';
import Logout from '@/assets/icons/sidemenu/logout.svg';
import MobileBackIcon from '@/assets/icons/sidemenu/mobileBackIcon.svg';

import EditIcon from '@/assets/icons/edit-icon.svg';
import DeleteIcon from '@/assets/icons/delete-icon.svg';
import EmailIcon from '@/assets/icons/email.svg';
import AvatarIcon from '@/assets/icons/avatar.svg';

// eslint-disable-next-line import/prefer-default-export
export const iconLoader = {
  booking: {
    'map-location': BookingMapLocation,
    'icabbi-car': IcabbiCar,
    'driver-state-car': DriverStateCar,
    'trip-confirmed': TripConfirmed,
  },
  sideMenu: {
    'bookings-list': Bookings,
    'book-trip': BookTrip,
    'payment-method': PaymentMethod,
    'expand-icon-open': ExpandIconOpen,
    'expand-icon-close': ExpandIconClose,
    'account-icon': AccountIcon,
    'favourites-icon': FavouritesIcon,
    'about-icon': AboutIcon,
    'terms-and-conditions-icon': TermsAndConditionsIcon,
    'logout-icon': Logout,
    'language-icon': LanguageIcon,
    'mobile-back-icon': MobileBackIcon,
  },
  paymentMethod: {
    'apple-pay': ApplePay,
    'google-pay': GooglePay,
    'visa-card': VisaCard,
    'master-card': MasterCard,
    'amex-card': AmexCard,
    'discover-card': DiscoverCard,
    'unknown-card': UnknownCard,
    'default-card': DefaultCard,
    'pay-business-account': PaymentBusiness,
    'new-card': NewCard,
    'pay-in-car': PayInCar,
    'business-account': BusinessAccount,
  },
  favourites: {
    'favourites-icon': FavouritesIcon,
  },
  elements: {
    'avatar-icon': AvatarIcon,
    'delete-icon': DeleteIcon,
    'edit-icon': EditIcon,
    'email-icon': EmailIcon,
  },
};
