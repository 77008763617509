import Vue from 'vue';
import Router from 'vue-router';
// import Book from './views/book/Book.vue';
import Book from './views/book/Book.vue';
import RideTracking from './views/rideTracking/RideTracking.vue';
import SharedRideTracking from './views/sharedRideTracking/SharedRideTracking.vue';
import OAuthCallback from './views/auth/OAuthCallback.vue';
// eslint-disable-next-line import/no-cycle
import { routeGuardWithoutOnBoarding, routeGuardWithOnBoarding } from './routing/routingHelper';
// eslint-disable-next-line import/no-cycle
import store from './store';
import UserProfile from './views/userProfile/UserProfile.vue';
import Logout from './views/auth/Logout.vue';
import GetStarted from './views/auth/GetStarted.vue';
import OnBoarding from './views/onBoarding/OnBoarding.vue';
import BookingList from './views/bookings/BookingList.vue';
import BookingPaymentActionRequired from './views/bookings/BookingPaymentActionRequired.vue';
import PaymentMethodForm from './views/paymentMethods/PaymentMethodForm.vue';
import EmailConfirmation from './views/email/EmailConfirmation.vue';
import EmailLoginRedirect from './views/email/EmailLoginRedirect.vue';
import PaymentMethods from './views/paymentMethods/PaymentMethods.vue';
import FavouritesView from './views/favourites/FavouritesView.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'get-started',
      component: GetStarted,
    },
    {
      path: '/email/login-redirect',
      name: 'email-login-redirect',
      component: EmailLoginRedirect,
    },
    {
      path: '/book',
      name: 'book',
      component: Book,
      beforeEnter: async (to, from, next) => {
        store.dispatch('book/resetBookingCreated');
        store.dispatch('geolocation/reset');
        await routeGuardWithOnBoarding(to, from, next);
      },
    },
    {
      path: '/booking/:id/copy',
      name: 'booking-copy',
      component: Book,
      meta: {
        state: 'copy',
      },
    },
    {
      path: '/book/:id/edit',
      name: 'booking-edit',
      component: Book,
      meta: {
        state: 'edit',
      },
    },
    {
      path: '/ride-tracking/:bookingId',
      name: 'ride-tracking',
      component: RideTracking,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/shared/ride-tracking/:bookingId',
      name: 'shared-ride-tracking',
      component: SharedRideTracking,
    },
    {
      path: '/bookings',
      name: 'bookings',
      component: BookingList,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/bookings/payment-action',
      name: 'bookings-payment-action',
      component: BookingPaymentActionRequired,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/payment-methods',
      name: 'paymentMethods',
      component: PaymentMethods,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/payment-methods/new',
      name: 'PaymentMethodForm',
      component: PaymentMethodForm,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/favourites',
      name: 'favourites',
      component: FavouritesView,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/user-profile',
      name: 'user-profile',
      component: UserProfile,
      beforeEnter: routeGuardWithOnBoarding,
    },
    {
      path: '/oauth/callback',
      name: 'oauth-callback',
      component: OAuthCallback,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: { hasVersion: false },
    },
    {
      path: '/logged-out',
      name: 'logged-out',
      redirect: {
        name: 'get-started',
      },
    },
    {
      path: '/on-boarding',
      name: 'on-boarding',
      component: OnBoarding,
      beforeEnter: routeGuardWithoutOnBoarding,
      meta: { hasVersion: false },
    },
    {
      path: '/email/confirm',
      name: 'email-confirm',
      component: EmailConfirmation,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
