<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline black justify-center"
        primary-title
      >
        {{ $t('book.form.confirmation.title') }}
      </v-card-title>

      <div
        class="mb-1 pa-2"
        v-for="(field) in accountFields"
      >
        <div class="font-weight-bold text-uppercase">{{ field.title }} {{ fieldRequired(field) }}</div>
        <div>{{ field.description }}</div>
        <v-text-field
          v-if="isTextField(field)"
          flat
          light
          solo
          v-model="confirmationFields[field.id]"
        ></v-text-field>

        <v-select
          v-if="isSelectField(field)"
          v-model="confirmationFields[field.id]"
          :items="field.values"
          flat
          light
          solo
        ></v-select>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="submit-confirmation-dialog pa-2"
          @click="submitConfirmation()"
          :disabled="disableSubmitConfirmation"
        >
          {{ $t('book.form.submit.value') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { chain } from 'lodash';

export default {
  name: 'ic2-account-confirmation-dialog',
  created() {
    this.getAccountFields();
  },
  data() {
    return {
      dialog: false,
      confirmationFields: {},
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      accountFields: 'book/getAccountFields',
      accountFieldsLoading: 'book/getAccountFieldsLoading',
    }),
    mandatoryAccountFieldIds() {
      return chain(this.accountFields)
        .filter({ active: true, validation: { required: true } })
        .map(field => field.id)
        .value();
    },
    disableSubmitConfirmation() {
      if (this.accountFieldsLoading) return true;
      return this.mandatoryAccountFieldIds.reduce((result, id) => {
        if (result) return true;
        if (!this.confirmationFields[id]) return true;
        return false;
      }, false);
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit('dialogClosed');
      }
    },
    open(newValue) {
      this.dialog = newValue;
    },
    confirmationFields(newValue) {
      this.$emit('confirmationFieldsUpdated', newValue);
    },
  },
  methods: {
    fieldRequired(field) {
      if (field.validation.required) return '*';
      return '';
    },
    getAccountFields() {
      this.$store.dispatch('book/getAccountFields');
    },
    submitConfirmation() {
      this.$emit('submitConfirmation');
    },
    isTextField(field) {
      return field.type === 'text';
    },
    isSelectField(field) {
      return field.type === 'select';
    },
  },
};
</script>

<style lang="scss" scoped>
  .submit-confirmation-dialog {
    width: 100%;
  }
</style>
