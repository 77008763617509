const MOBILE_BREAKPOINTS = ['sm', 'xs', 'md'];
// eslint-disable-next-line import/prefer-default-export
export const isMobile = {
  computed: {
    isMobile() {
      if (!this.$vuetify.breakpoint) {
        return false;
      }
      return MOBILE_BREAKPOINTS.includes(this.$vuetify.breakpoint.name);
    },
  },
};
