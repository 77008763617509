<template>
  <svg width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" stroke="#EEEEEE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9646 21.6776H11.1779L9.08825 13.5897C8.98906 13.2177 8.77846 12.8888 8.46868 12.7338C7.69557 12.3442 6.84366 12.0342 5.91431 11.8778V11.5664H10.4035C11.023 11.5664 11.4877 12.0342 11.5652 12.5774L12.6494 18.4114L15.4348 11.5664H18.144L13.9646 21.6776ZM19.693 21.6776H17.0611L19.2283 11.5664H21.8601L19.693 21.6776ZM25.265 14.3675C25.3425 13.823 25.8072 13.5116 26.3493 13.5116C27.2012 13.4334 28.1292 13.5898 28.9037 13.978L29.3683 11.801C28.5939 11.4896 27.742 11.3333 26.9689 11.3333C24.4145 11.3333 22.5558 12.7338 22.5558 14.6776C22.5558 16.1563 23.8724 16.9327 24.8017 17.4005C25.8072 17.8669 26.1944 18.1783 26.1169 18.6447C26.1169 19.3442 25.3425 19.6556 24.5694 19.6556C23.64 19.6556 22.7107 19.4224 21.8601 19.0329L21.3954 21.2112C22.3248 21.5994 23.3302 21.7558 24.2596 21.7558C27.1238 21.8326 28.9037 20.4334 28.9037 18.3333C28.9037 15.6885 25.265 15.5335 25.265 14.3675ZM38.1144 21.6776L36.0247 11.5664H33.7801C33.3154 11.5664 32.8507 11.8778 32.6958 12.3442L28.8262 21.6776H31.5355L32.0763 20.2002H35.4051L35.7149 21.6776H38.1144ZM34.1673 14.2894L34.9404 18.1001H32.7733L34.1673 14.2894Z" fill="#172B85"/>
  </svg>
</template>

<script>
export default {
  name: 'iconVisa',
};
</script>

<style scoped>

</style>
