<template>
<v-dialog v-model="showDialog" persistent max-width="550">
  <v-card>
    <v-card-title class="headline">{{
        $t('userProfile.deletionDialog.title')
      }}</v-card-title>
    <v-card-text>
      <p class="mb-0">{{ $t('userProfile.deletionDialog.text') }}</p>
    </v-card-text>
    <v-card-actions class="delete-user-btns">
      <v-btn
        class="disable-button"
        color="grey darken-1"
        text
        @click="cancelUserDeletion"
      >
        {{ $t('userProfile.deletionDialog.cancel') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="pr-6 pl-6 disable-button" depressed @click="confirmUserDeletion">{{ $t('userProfile.deletionDialog.confirm') }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
<script>
export default {
  name: 'user-delete-dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirmUserDeletion() {
      this.$emit('confirmUserDeletion');
    },
    cancelUserDeletion() {
      this.$emit('cancelUserDeletion');
    },
  },
};
</script>
<style lang="scss">
.delete-user-btns {
  padding-bottom: 16px!important;
}
</style>
