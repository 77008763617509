<template>
  <v-menu :close-on-content-click="false" v-model="open" width="400" offset-y slide-y>
    <template v-slot:activator="{ on }">
      <div
        class="d-flex mb-2"
        :class="{ 'user-nav-wrapper': !drawer }"
        left
        v-on="on"
      >
        <account-icon id="account-icon" :class="{ 'mr-2': !drawer }"/>
        <!-- <v-icon id="account-icon" :class="{ 'mr-2': !drawer }">account_circle</v-icon> -->
        <span v-if="!drawer">{{ displayName }}</span>
      </div>
    </template>

    <v-list>
      <component v-for="item in items" :key="item.label" :is="item.componentName" :item="item"></component>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSelector from './LanguageSelector.vue';
import MenuLink from './MenuLink.vue';
import { iconLoader } from '../../helpers/iconsLoader';

export default {
  name: 'UserProfileNavigation',
  components: { LanguageSelector, MenuLink, ...iconLoader.sideMenu },
  props: {
    drawer: Boolean,
  },
  created() {
    this.$store.dispatch('userProfile/getUser');
  },
  data() {
    return {
      open: false,
    };
  },
  watch: {
    locale() {
      if (this.open) {
        this.open = false;
        this.$nextTick(() => {
          this.open = true;
        });
      }
    },
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      displayName: 'authentication/displayName',
      paymentMethodsEnabled: 'features/paymentMethods',
      privacyPolicyLinks: 'bookingChannel/privacyPolicyLinks',
      termAndConditionLinks: 'bookingChannel/termAndConditionLinks',
    }),
    locale() {
      return this.$i18n.locale;
    },
    items() {
      const menuItems = [];

      menuItems.push({
        componentName: 'menu-link',
        label: 'navigation.menu.userProfile',
        icon: 'account-icon',
        to: '/user-profile',
      });

      if (this.paymentMethodsEnabled === true) {
        menuItems.push({
          componentName: 'menu-link',
          label: 'navigation.menu.paymentMethods',
          icon: 'payment-method',
          to: '/payment-methods',
        });
      }

      menuItems.push({
        componentName: 'menu-link',
        icon: 'terms-and-conditions-icon',
        label: 'navigation.menu.termsAndConditions',
        href: this.termAndConditionLink,
        target: '_blank',
        trackingScreenName: 'about',
        trackingActionName: 'privacy_policy_selected',
      });

      menuItems.push({
        componentName: 'menu-link',
        icon: 'about-icon',
        label: 'navigation.menu.privacyPolicy',
        href: this.privacyPolicyLink,
        target: '_blank',
        trackingScreenName: 'about',
        trackingActionName: 'privacy_policy_selected',
      });

      menuItems.push({
        componentName: 'language-selector',
        icon: 'language-icon',
        label: 'navigation.menu.language',
      });

      menuItems.push({
        componentName: 'menu-link',
        label: 'navigation.menu.logout',
        icon: 'logout-icon',
        to: '/logout',
        trackingScreenName: 'user_profile',
        trackingActionName: 'log_out_selected',
      });

      return menuItems;
    },
    privacyPolicyLink() {
      if (!this.privacyPolicyLinks) return '#';
      return (
        this.privacyPolicyLinks[this.$i18n.locale] || this.privacyPolicyLinks.en
      );
    },
    termAndConditionLink() {
      if (!this.termAndConditionLinks) return '#';
      return (
        this.termAndConditionLinks[this.$i18n.locale]
        || this.termAndConditionLinks.en
      );
    },
  },
};
</script>

<style scoped>
.user-nav-active {
  font-weight: bold !important;
}

.user-nav-wrapper {
  border-radius: 8px;
  background-color: #f0eded;
  padding: 5px;
  width: 90%;
  transition: 0.3s;
  word-break: keep-all;
  align-items: center;
}
</style>
