import { render, staticRenderFns } from "./iconGoogle.vue?vue&type=template&id=b7614ba8&scoped=true&"
import script from "./iconGoogle.vue?vue&type=script&lang=js&"
export * from "./iconGoogle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7614ba8",
  null
  
)

export default component.exports