<template>
  <v-dialog
    v-model="dialog"
    width="520"
  >
    <v-card light id="booking-rating-confirmation">
      <v-card-title id="booking-rating-confirmation-title">
        <span>{{ $t('bookingRating.thankYou') }}</span>
      </v-card-title>
      <v-card-text class="center">
        <v-row justify-space-between column fill-height align-center>
          <v-col md9 id="booking-rating-confirmation-text-title">
            {{ $t('bookingRating.confirmation.title') }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions justify-end>
        <v-col md3 offset-md9>
          <v-btn @click="closeDialog"
                 block
                 hide-overlay="true"
                 color="primary"
                 class="primaryFont--text"
                 depressed
          >{{ $t('bookingRating.confirmation.ok') }}</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'booking-rating-confirmation',
  props: {
    booking: Object,
    required: true,
  },
  data() {
    return {
      dialog: true,
    };
  },
  components: {
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.$router.push('/book');
    },
  },
};
</script>

<style scoped>

  #booking-rating-confirmation {
    margin: auto;
    font-family: Inter;
  }

  #booking-rating-confirmation-title {
    color: #ffffff;
    background-color: #27AF3D;
    font-weight: bold;
  }

  #booking-rating-confirmation-text-title {
    font-weight: bold;
    font-size: 20px;
  }

</style>
