<template>
  <v-list-item class="d-flex" @click="" style="min-height: auto !important;">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-list-item-icon class="navigation-link-icon">
            <component :is="item.icon" style="color: #000000;"/>
          </v-list-item-icon>
          <v-list-item-title
            v-on="on"
            class="font-weight-light text-subtitle-1"
            style="height: 24px !important;"
          >
            {{ $t(item.label) }}
          </v-list-item-title>
        </template>
        <v-list>
          <v-list-item v-for="language in languages" :key="language.id"  @click="selectLanguage(language.id)">
            <v-list-item-content>
              <v-list-item-title class="font-weight-light text-subtitle-2">{{ language.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
import { iconLoader } from '../../helpers/iconsLoader';
import LanguageSelectorMixin from '../shared/mixins/LanguageSelectorMixin';

export default {
  name: 'LanguageSelector',
  mixins: [LanguageSelectorMixin],
  components: {
    ...iconLoader.sideMenu,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedLanguage: 'language/selectedLanguage',
    }),
  },
};
</script>

<style lang="scss" scoped>
  .v-list-item__content {
    text-align: left;
  }
  .v-list-item {
    border-bottom: none;
  }
  .navigation-link-icon {
    margin-right: 1em !important;
    min-width: 32px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .v-list-item__icon {
    padding-left: 0px;
  }

  .v-list-item__icon svg {
    color: #000000 !important;
  }
</style>
