import dayjs from 'dayjs';
import { helpers } from 'vuelidate/lib/validators';

const DAYS_CAPPING_UNRESTRICTED_TYPES = ['cash', 'businessAccount', 'IC2Account'];

export function isNotInThePast(value) {
  if (!value) {
    return true;
  }
  const now = dayjs();
  const date = dayjs(value);
  return now.isBefore(date);
}

export function isBeforeLimitTimeValues(limit, unit) {
  return helpers.withParams({ type: 'isBeforeLimitTimeValues', limit, unit },
    (value, vm) => {
      const paymentType = vm ? vm.paymentMethodType : null;
      if (paymentType
        && DAYS_CAPPING_UNRESTRICTED_TYPES.includes(paymentType)) {
        return true;
      }
      if (!value) {
        return true;
      }
      const limitDate = dayjs().add(limit, unit);
      const date = dayjs(value);
      return limitDate.isAfter(date);
    });
}

export function isAfterPickupTime(value, vm) {
  if (!value) {
    return true;
  }
  const pickup = dayjs(vm.pickup);
  const date = dayjs(value);
  return date.isAfter(pickup);
}

export function isValidString(value) {
  if (!value) {
    return true;
  }
  return /^[A-zÀ-ÿ0-9\- ']*$/.test(value);
}

export function isValidAlphanumericalString(value) {
  if (!value) {
    return true;
  }
  return /^[A-z0-9\- ]*$/.test(value);
}
