<template>
  <div class="card-input">
    <label v-if="label">{{ $t('paymentMethod.form.cardInfo') }}</label>
    <div ref="card"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StripeCardInput',
  props: {
    label: {
      type: String,
    },
    billingDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stripeStyle: {
        base: {
          fontFamily: 'Roboto, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          color: 'black',
          border: '1px solid #FFF',
        },
        invalid: {
          color: 'red',
        },
      },
    };
  },
  mounted() {
    this.initStripeElements();
  },
  computed: {
    ...mapGetters({
      clientSecret: 'paymentMethods/clientSecret',
      stripeInstance: 'paymentMethods/stripeInstance',
    }),
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.initStripeElements();
    },
  },
  methods: {
    init() {
      this.$store.dispatch('paymentMethods/retrieveSecret');
    },
    initStripeElements() {
      const locale = this.locale.substr(0, 2);
      const elements = this.stripeInstance.elements({ locale });
      this.cardElement = elements.create('card', {
        style: this.stripeStyle,
        hidePostalCode: true,
      });
      this.cardElement.mount(this.$refs.card);
    },
    async saveCard() {
      const payload = {
        clientSecret: this.clientSecret,
        cardElement: this.cardElement,
        billingDetails: this.billingDetails,
      };
      return this.$store.dispatch('paymentMethods/saveCard', payload);
    },
  },
  beforeDestroy() {
    this.cardElement.destroy(this.$refs.card);
    this.$store.dispatch('paymentMethods/resetSecret');
  },
};
</script>

<style lang="scss">
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #e4e4e4 !important;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.card-input {
  label {
    top: -1px;
    margin-left: 2px;
    text-transform: uppercase;
    font-size: 09px;
    color: black;
    height: 20px;
    line-height: 20px;
  }
}
</style>
