<template>
  <v-col cols="12" md="12" xs="12" class="flex pa-0 map-container">
    <Map
      ref="map"
      :pickupDraggable="true"
      :pickup="pickup"
      :waypoints="waypoints"
      :destination="destination"
      :centerLatLng="centerLatLng"
      @pickupUpdate="emitPickupUpdate"
    ></Map>
  </v-col>
</template>
<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import Map from '../maps/Map.vue';

export default {
  name: 'booking-map',
  components: { Map },
  computed: {
    ...mapGetters({
      defaultLocation: 'bookingChannel/defaultLocation',
      destinationData: 'geolocation/destination',
      pickupData: 'geolocation/pickup',
      waypointsData: 'geolocation/waypoints',
    }),
    pickup() {
      const { coordinates, description } = this.pickupData;
      if (coordinates && coordinates.latitude && coordinates.longitude) {
        return {
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          description,
        };
      }
      return null;
    },
    destination() {
      const { coordinates, description } = this.destinationData;
      if (coordinates && coordinates.latitude && coordinates.longitude) {
        return {
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
          description,
        };
      }
      return null;
    },
    waypoints() {
      const waypointsCoordinates = [];
      this.waypointsData.forEach(({ coordinates, description }) => {
        if (coordinates && coordinates.latitude && coordinates.longitude) {
          waypointsCoordinates.push({
            longitude: coordinates.longitude,
            latitude: coordinates.latitude,
            description,
          });
        }
      });

      return waypointsCoordinates;
    },
    centerLatLng() {
      const { latitude, longitude } = this.defaultLocation;
      if (latitude && longitude) {
        return {
          longitude,
          latitude,
        };
      }
      return null;
    },
  },
  methods: {
    emitPickupUpdate: debounce(async function (coordinates) {
      try {
        await this.$store.dispatch('geolocation/geocode', {
          lang: this.$i18n.locale,
          location: { latitude: coordinates.latitude, longitude: coordinates.longitude },
        });
      } catch (e) {
        this.$store.dispatch('globalError/setSnackbar', {
          message: this.$t('errors.geolocation'),
          timeout: 5000,
          y: 'top',
          absolute: true,
        });
      }
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
.map-container {
  z-index: 0;
  height: 100vh;
}

@media screen and (max-width: 760px) {
  .map-container {
    height: 30vh;
    max-height: 30vh;
  }
}
</style>
