<template>
  <v-list-item
    v-bind:key="item.to"
    v-bind:href="item.href"
    v-bind:target="item.target"
    dense
    @click=""
    v-bind:to="item.to"
    :active-class="activeClass"
  >
    <v-list-item-icon class="navigation-link-icon">
      <component :is="item.icon" style="color: #000000;"/>
    </v-list-item-icon>
    <v-list-item-title class="font-weight-light" v-text="$t(item.label)"></v-list-item-title>
  </v-list-item>
</template>

<script>
import { iconLoader } from '@/helpers/iconsLoader';

export default {
  name: 'MenuLink',
  components: {
    ...iconLoader.sideMenu,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    activeClass: {
      type: String,
      default: 'user-nav-active',
    },
  },
};
</script>

<style lang="scss">
.user-nav-active-drawer {
  color: #333333 !important;
}
</style>

<style lang="scss" scoped>
  .v-list-item__content {
    text-align: left;
  }
  .v-list-item__title {
    font-size: 17px !important;
    height: 25px;
    color: #333333 !important;
  }
  .v-list-item {
    border-bottom: none;
  }
  .navigation-link-icon {
    margin-right: 1em !important;
    min-width: 32px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .v-list-item__icon {
    padding-left: 0px;
  }

  .v-list-item__icon svg {
    color: #000000 !important;
  }
</style>
