<template>
  <div>
    <alert-error
      v-for="(error, index) in errors"
      :key="`alert-${index}`"
      :error="error"
      v-on:dismissed="onDismissed"
      :color="errorColor === '#ffffff' ? 'red' : errorColor"
      type="error"
    ></alert-error>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GlobalErrors',
  computed: {
    ...mapGetters({
      errorColor: 'bookingChannel/errorColor',
      errors: 'globalError/errors',
    }),
  },
  methods: {
    onDismissed(errors) {
      this.$store.dispatch('globalError/popError', errors);
    },
  },
};
</script>

<style scoped>

</style>
