<template>
  <v-card class="dialog-content">
    <v-card-title class="text-h5 mb-3">
      <span style="width: auto;">{{ $t(`dialogContainer.${type}.title`) }}</span>
      <v-spacer></v-spacer>
      <v-icon @click="$emit('closeDialog')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text v-if="!loading">
      <p class="dialog-content mb-0">
        {{ $t(`dialogContainer.${type}.content.cancel`) }}
        <span v-if="fee && fee.cancellationFee > 0">
          {{ $t(`dialogContainer.${type}.content.fee`, { fee: `${currencySymbol}${fee.cancellationFee}` }) }}
        </span>
      </p>
      <span class="dialog-content-subitem">
        {{ $t(`dialogContainer.${type}.content.undo`) }}
      </span>
    </v-card-text>
    <v-card-actions v-if="!loading" class="px-4 pb-4">
      <v-btn
        class="disable-button"
        color="grey darken-1"
        text
        @click="$emit('closeDialog')"
      >
        {{ $t(`dialogContainer.${type}.button.cancel`) }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :class="`cancel-button`"
        class="white--text"
        color="#EB5757"
        depressed
        @click="handleAction"
      >
        {{ $t(`dialogContainer.${type}.button.agree`) }}
      </v-btn>
    </v-card-actions>
    <v-layout class="loading-container" v-else justify-center align-center>
      <v-progress-circular
        indeterminate
        color="primary">
      </v-progress-circular>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { entityLoader } from '../../mixins/entityLoader.mixins';

export default {
  name: 'dialog-wrapper',
  mixins: [entityLoader],
  props: {
    type: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    fee: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    isOpen: false,
    loading: false,
    storeAction: null,
  }),
  computed: {
    ...mapGetters({
      currencySymbol: 'bookingChannel/currencySymbol',
    }),
    getStoreAction() {
      let url = null;

      switch (this.type) {
        case 'delete-payment-method':
          url = 'paymentMethods/deleteCard';
          break;
        case 'cancel-booking':
          url = 'book/cancel';
          break;
        default:
          break;
      }

      return url;
    },
  },
  methods: {
    async handleAction() {
      this.loading = !this.loading;

      const result = await this.actionPost(this.getStoreAction, this.id);
      this.loading = !this.loading;

      this.$emit('closeDialog', result ? { id: this.id } : null);
    },
  },
};
</script>

<style lang="scss">
.loading-container {
  min-height: 130px;
  background-color: #ffffff;
}
.dialog-content {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #333333;
}

.dialog-content-subitem {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #888888;
}

.disable-button {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-transform: capitalize;
}

.cancel-button {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-transform: capitalize;
}
</style>

<i18n>
{
  "fr": {
    "dialogContainer": {
      "delete-user": {
        "title": "Supprimer utilisateur",
        "content": {
          "cancel": "Vous voulez vraiment supprimer cet utilisateur?",
          "undo": "Cette action ne peut pas être défaite."
        },
        "button": {
          "cancel": "Annuler",
          "agree": "Supprimer"
        }
      },
      "delete-business-user": {
        "title": "Delete user",
        "content": {
          "cancel": "Vous voulez vraiment supprimer cet utilisateur?",
          "undo": "Cette action ne peut pas être défaite."
        },
        "button": {
          "cancel": "Annuler",
          "agree": "Supprimer"
        }
      },
      "cancel-booking": {
        "title": "Annuler réservation",
        "content": {
          "cancel": "Vous voulez vraiment annuler cette réservation?",
          "undo": "Cette action ne peut pas être défaite."
        },
        "button": {
          "cancel": "Ne pas annuler",
          "agree": "Annuller"
        }
      },
      "delete-account": {
        "title": "Supprimer compte",
        "content": {
          "cancel": "Vous voulez vraiment supprimer ce compte?",
          "undo": "Cette action ne peut pas être défaite."
        },
        "button": {
          "cancel": "Annuler",
          "agree": "Supprimer"
        }
      },
      "delete-fleet-account": {
        "title": "Supprimer compte lié?",
        "content": {
          "cancel": "Vous voulez vraiment supprimer ce compte lié?",
          "undo": "Cette action ne peut pas être défaite."
        },
        "button": {
          "cancel": "Annuler",
          "agree": "Supprimer"
        }
      },
      "delete-payment-method": {
        "title": "Supprimer carte",
        "content": {
          "cancel": "Vous voulez vraiment supprimer cette carte?",
          "undo": "Cette action ne peut pas être défaite."
        },
        "button": {
          "cancel": "Annuler",
          "agree": "Supprimer"
        }
      }
    }
  },
  "fi": {
    "dialogContainer": {
      "delete-user": {
        "title": "Poista käyttäjä",
        "content": {
          "cancel": "Oletko varma että haluat poistaa tämän käyttäjän?",
          "undo": "Tätä toimintoa ei voi perua."
        },
        "button": {
          "cancel": "Peruuta",
          "agree": "Poista"
        }
      },
      "delete-business-user": {
        "title": "Poista käyttäjä",
        "content": {
          "cancel": "Oletko varma että haluat poistaa tämän käyttäjän?",
          "undo": "Tätä toimintoa ei voi perua."
        },
        "button": {
          "cancel": "Peruuta",
          "agree": "Poista"
        }
      },
      "cancel-booking": {
        "title": "Peruuta tilaus",
        "content": {
          "cancel": "Oletko varma että haluat peruuttaa tämän tilauksen?",
          "undo": "Tätä toimintoa ei voi perua."
        },
        "button": {
          "cancel": "Ei, älä peruuta",
          "agree": "Kyllä, peruuta"
        }
      },
      "delete-account": {
        "title": "Poista tili",
        "content": {
          "cancel": "Oletko varma että haluat poistaa tämän tilin?",
          "undo": "Tätä toimintoa ei voi perua."
        },
        "button": {
          "cancel": "Peruuta",
          "agree": "Poista"
        }
      },
      "delete-fleet-account": {
        "title": "Poista liitetty tili?",
        "content": {
          "cancel": "Oletko varma että haluat poistaa tämän tilin liitoksen?",
          "undo": "Tätä toimintoa ei voi perua."
        },
        "button": {
          "cancel": "Peruuta",
          "agree": "Poista"
        }
      },
      "delete-payment-method": {
        "title": "Poista maksukortti",
        "content": {
          "cancel": "Oletko varma että haluat poistaa tämän maksukortin?",
          "undo": "Tätä toimintoa ei voi perua."
        },
        "button": {
          "cancel": "Peruuta",
          "agree": "Poista"
        }
      }
    }
  },
  "sv": {
    "dialogContainer": {
      "delete-user": {
        "title": "Delete user",
        "content": {
          "cancel": "Are you sure you want to delete this user?",
          "undo": "This action cannot be undone."
        },
        "button": {
          "cancel": "Cancel",
          "agree": "Delete"
        }
      },
      "delete-business-user": {
        "title": "Delete user",
        "content": {
          "cancel": "Are you sure you want to delete this user?",
          "undo": "This action cannot be undone."
        },
        "button": {
          "cancel": "Cancel",
          "agree": "Delete"
        }
      },
      "cancel-booking": {
        "title": "Cancel booking",
        "content": {
          "cancel": "Are you sure you want to cancel this booking?",
          "undo": "This action cannot be undone."
        },
        "button": {
          "cancel": "Don't cancel",
          "agree": "Cancel"
        }
      },
      "delete-account": {
        "title": "Delete account",
        "content": {
          "cancel": "Are you sure you want to delete this account?",
          "undo": "This action cannot be undone."
        },
        "button": {
          "cancel": "Cancel",
          "agree": "Delete"
        }
      },
      "delete-fleet-account": {
        "title": "Delete linked account?",
        "content": {
          "cancel": "Are you sure you want to delete this linked account?",
          "undo": "This action cannot be undone."
        },
        "button": {
          "cancel": "Cancel",
          "agree": "Delete"
        }
      },
      "delete-payment-method": {
        "title": "Delete card",
        "content": {
          "cancel": "Are you sure you want to delete this card?",
          "undo": "This action cannot be undone."
        },
        "button": {
          "cancel": "Cancel",
          "agree": "Delete"
        }
      }
    }
  }
}
</i18n>
