<template>
  <div class="ride_tracking_summary_container">
    <v-card
      elevation="2"
    >
      <v-card-title>{{ informationBoxTitle() }}</v-card-title>
      <v-card-text>
        <v-divider class="top_divider"></v-divider>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">

              <eta v-if="estimatedTimeArrival()" :eta="estimatedTimeArrival()" style="width: 120px;"></eta>

              <div v-if="booking.driver && booking.vehicle" class="driver_and_vehicle_info ml-2">
                <div v-if="booking.driver.name">{{ $t('publicRideTracking.booking.driver') }}: {{ booking.driver.name }}</div>
                <div v-if="booking.vehicle.plate">{{ $t('publicRideTracking.booking.carId') }}: {{ booking.vehicle.licenceRegistration }}</div>
                <div v-if="booking.driver.ref">{{ $t('publicRideTracking.booking.driverId') }}: {{ booking.driver.ref }}</div>
                <div>{{ booking.vehicle.make }} {{ booking.vehicle.model }} - {{ booking.vehicle.colour }}</div>
              </div>
              <div v-else class="ml-2 my-4">
                <h3>{{ $t("publicRideTracking.driverNotAssigned") }}</h3>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider class="bottom_divider"></v-divider>
        <v-row v-if="publicAppConfiguration">
          <v-col cols="12" id="download_app_section">
            <p class="hidden-sm-and-down">
              <span class="download_app_title">{{ $t('publicRideTracking.appDownload.title') }}</span>
              <span class="download_app_desc">{{ $t('publicRideTracking.appDownload.description') }}.</span>
            </p>
            <p class="hidden-md-and-up">{{ $t('publicRideTracking.appDownload.title') }}. {{ $t('publicRideTracking.appDownload.description') }}.</p>
            <div id="store_logo">
              <a :href="iosStoreUrl()" v-if="!(userAgent() === 'Android')" target="_blank"><img src="/img/apple-store-btn.png" :alt="$t('publicRideTracking.appDownload.alt.appStore')" height="40"></a>
              <a :href="playStoreUrl()" v-if="!(userAgent() === 'iOS')" target="_blank"><img src="/img/play-store-btn.png" :alt="$t('publicRideTracking.appDownload.alt.googlePlay')" height="40"></a>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { BookingHelper } from '@/views/sharedRideTracking/shared/bookingHelper';
import ETA from './ETA.vue';

export default {
  name: 'ride-tracking-summary',
  components: {
    eta: ETA,
  },
  props: {
    booking: Object,
  },
  methods: {
    userAgent() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    },
    iosStoreUrl() {
      return this.publicAppConfiguration.iosStoreUrl;
    },
    playStoreUrl() {
      return this.publicAppConfiguration.playStoreUrl;
    },
    estimatedTimeArrival() {
      return this.booking.estimatedTimeArrival;
    },
    informationBoxTitle() {
      if (BookingHelper.isBookingCancelled(this.booking)) {
        return this.$t('publicRideTracking.informationBoxTitle.journeyCancelled', { name: this.booking.userInfo.givenName });
      }
      if (BookingHelper.isBookingCompleted(this.booking)) {
        return this.$t('publicRideTracking.informationBoxTitle.passengerArrived', { name: this.booking.userInfo.givenName });
      }
      return this.$t('publicRideTracking.informationBoxTitle.followPassengersJourney', { name: this.booking.userInfo.givenName });
    },
  },
  computed: {
    ...mapGetters({
      bookingChannelId: 'bookingChannel/bookingChannelId',
      primaryColor: 'bookingChannel/primaryColor',
      publicAppConfiguration: 'customerConfiguration/getPublicAppConfiguration',
    }),
  },
};
</script>

<style lang="scss" scoped>
.ride_tracking_summary_container {
  margin-left: 10%;
}
  .ride_tracking_summary_container .v-card{
    border-radius: 8px 8px 0 0;
    padding: 18px 24px;
  }
  .ride_tracking_summary_container .v-card__title{
    color: #333;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    word-break: inherit;
    padding: 0;
  }
  .ride_tracking_summary_container .v-card__text{
    padding: 0;
  }
  .ride_tracking_summary_container .v-card__text .top_divider{
    margin: 14px 0 15px;
  }
  .ride_tracking_summary_container .v-card__text .bottom_divider{
    margin: 19px 0 17px;
  }
  .driver_and_vehicle_info div{
    margin: 6px 0;
    font-size: 15px;
    line-height: 21px;
    color: #333;
    font-weight: 400;
  }
  #download_app_section{
    padding-top: 17px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  #download_app_section p{
    padding: 0 1px 0 0;
    margin-bottom: 19px;
    font-size: 13px;
    line-height: 18px;
  }
  .download_app_title{
    display: block;
    color: #333;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
  .download_app_desc{
    display: block;
    color: #333;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 5px;
  }
  #store_logo{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #store_logo a{
    margin-right: 5px;
  }
  #store_logo a:last-child{
    margin-right: 0;
  }
  #store_logo img{
    width: 115px;
  }
  /*Small to medium tablet (600px and up)*/
  @media (min-width: 600px) {
    .ride_tracking_summary_container .v-card{
      border-radius: 8px;
    }
  }

  /*Large tablet to laptop(960px and up)*/
  @media (min-width: 960px) {
    .ride_tracking_summary_container .v-card{
      padding: 32px;
    }
    .ride_tracking_summary_container .v-card__title{
      font-size: 24px;
    }
    #download_app_section{
      padding: 30px 10px;
      flex-direction: column;
    }
    .download_app_desc{
      margin-top: 5px;
    }
    #store_logo{
      flex-direction: row;
      justify-content: start;
      align-items: start;
    }
  }

  /*Desktop (1264px and up)*/
  @media (min-width: 1264px) {

  }

  /*4k and ultra-wide (1904px and up)*/
  @media (min-width: 1904px) {

  }
</style>
