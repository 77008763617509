<template>
  <v-dialog
    v-model="dialog"
    width="520"
  >
      <v-card light id="booking-rating-form">
        <v-card-title id="booking-rating-form-title">
          <span>{{ $t('bookingRating.arrivedToDestination') }}</span>
        </v-card-title>
        <v-card-text class="center card-container">
          <loader :is-loading="bookingRatingInCreation" :margin="15" color="primary">
            <div>
              <div class="title-container" id="booking-rating-form-text-title">
                {{ $t('bookingRating.form.title') }}
              </div>
              <div>
                <validated-component name="rate" v-model="rate" :validator="$v.rating.rate">
                  <star-rating :increment="0.5" :show-rating="false" v-model="rate"></star-rating>
                </validated-component>
              </div>
              <div>
                <validated-component name="comment" v-model="comment" :validator="$v.rating.comment">
                  <v-textarea
                    id="booking-rating-comment"
                    solo
                    outline
                    v-model="comment"
                    style="resize: none;"
                    :label="$t('bookingRating.form.comment')"
                  ></v-textarea>
                </validated-component>
              </div>
            </div>
          </loader>
        </v-card-text>
        <v-card-actions justify-end>
          <v-col md="3" offset-md-5>
            <v-btn @click="closeDialog"
                   block
                   hide-overlay="true"
                   color="#fff"
                   depressed
            >{{ $t('bookingRating.form.close') }}</v-btn>
          </v-col>
          <v-col md-3 offset-md-1>
            <v-btn @click="submitRating"
                   color="primary"
                   block
                   class="primaryFont--text"
                   hide-overlay="true"
                   :disabled="disableSubmit"
                   depressed
            >{{ $t('bookingRating.form.submit') }}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import StarRating from 'vue-star-rating';
import ratingValidation from '../../validation/ratingValidationData';

export default {
  name: 'booking-rating-form',
  props: {
    booking: Object,
    isRated: Boolean,
  },
  data() {
    return {
      dialog: false,
      validationData: ratingValidation,
      comment: null,
      rate: 0,
    };
  },
  validations() {
    return ratingValidation;
  },
  components: {
    StarRating,
  },
  computed: {
    ...mapGetters({
      bookingRatingInCreation: 'rating/getBookingRatingInCreation',
      bookingRatings: 'rating/getBookingRatings',
    }),
    disableSubmit() {
      return this.$v.$invalid || this.bookingRatingInCreation;
    },
    rating() {
      const rating = {
        rate: this.rate,
      };
      if (this.booking) {
        rating.userId = this.booking.userId;
      }
      if (this.comment) rating.comment = this.comment;
      return rating;
    },
  },
  methods: {
    submitRating() {
      this.$store.dispatch('rating/createBookingRating', { bookingId: this.booking.id, rating: this.rating });
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  watch: {
    bookingRatings(newValue) {
      if (newValue && newValue.length === 0) this.dialog = true;
    },
  },
};
</script>

<style scoped>

  #booking-rating-form {
    margin: auto;
    font-family: Inter;
  }

  #booking-rating-form-title {
    color: #ffffff;
    background-color: #27AF3D;
    font-weight: bold;
  }

  #booking-rating-form-text-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  #booking-rating-comment {
    border: darkgrey;
  }

  .title-container {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000000;
    text-align: center;
  }

  .card-container {
    padding: 0 10px 10px 10px !important;
  }

</style>
