<template>
    <v-row no-gutters>
      <v-col pa-1 class="date-select-field">
        <h5 class="section-title">{{ $t('book.form.sectionDate') }}</h5>
        <v-menu
          v-model="pickupDateButton"
          :close-on-content-click="false"
          :nudge-bottom="48"
          max-width="290"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="computeDate"
              flat
              light
              solo
              readonly
              hide-details
              class="show-border time-field"
              v-bind:class="{ highlight: highlightDateReset }"
              color="primary"
              height="44px"
              @click="resetHighlight"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            no-title
            show-adjacent-months
            class="show-border"
            :min="minimumDate"
            :max="maximumDate"
            color="primary"
            :locale="$i18n.locale"
            @change="pickupDateButton = false"
          ></v-date-picker>
        </v-menu>
        <!-- <v-col class="tooltip">
          <v-messages color="red" v-show="highlightDateReset" v-model="tooltip"></v-messages>
        </v-col> -->
      </v-col>
      <v-col pa-1 class="hour-select-field">
        <h5 class="section-title">{{ $t('book.form.sectionTime') }}</h5>
        <v-text-field
          outlined
          filled
          flat
          light
          solo
          hide-details
          color="primary"
          height="50px"
          class="show-border"
          v-model="timeMask"
          @focus="handleTimeFocus"
          @blur="handleTimeBlurEvent"
        />
      </v-col>
    </v-row>
</template>

<script>
import moment from 'moment';
import { Mask } from 'maska';
import dayjs from '../../../daysjs';

const TIME_ASAP = 'ASAP';

export default {
  name: 'pickupTime',

  data: () => ({
    selectedHour: null,
    selectedDate: null,
    pickupDateButton: false,
    pickupTimeButton: false,
    highlightDateReset: false,
    hours: [],
    maximumDate: dayjs().add(1, 'year').format('YYYY-MM-DDTHH:mm:ss'),
    timeMask: TIME_ASAP,
  }),

  props: {
    minimumDate: {
      type: String,
      default() {
        return dayjs().format('YYYY-MM-DDTHH:mm:ss');
      },
    },
    pickupTime: String,
    return: {
      type: Boolean,
    },
  },

  mounted() {
    if (this.return) {
      this.selectedDate = dayjs(this.minimumDate).format('YYYY-MM-DD');
    } else {
      this.selectedDate = dayjs().format('YYYY-MM-DD');
    }
    this.hours = this.getHours();
    this.selectedHour = this.pickupTime
      ? dayjs(this.pickupTime).format('HH:mm')
      : this.hours[0];
  },

  watch: {
    pickupTime: {
      immediate: true,
      handler(val) {
        if (!val) return;

        const date = dayjs(val);
        this.selectedDate = date.format('YYYY-MM-DD');
        this.selectedHour = date.format('HH:mm');
        this.timeMask = date.format('HH:mm');
      },
    },
    locale() {
      this.$forceUpdate();
      this.hours = this.getHours();
      if (!this.isSelectedHourInHours()) {
        this.selectedHour = this.pickupTime
          ? dayjs(this.pickupTime).format('HH:mm')
          : this.hours[0];
      }
    },
    computedValue: {
      handler(newValue) {
        this.$emit('pickupTimeUpdated', newValue);
      },
      immediate: true,
    },
    selectedDate() {
      this.hours = this.getHours();
      if (!this.isSelectedHourInHours()) {
        this.selectedHour = this.pickupTime
          ? dayjs(this.pickupTime).format('HH:mm')
          : this.hours[0];
      }
    },
    minimumDate(newValue) {
      const newSelectedDate = this.getNewSelectedDate(newValue);
      if (this.return && dayjs(this.selectedDate).isSameOrBefore(newSelectedDate)) {
        this.selectedDate = newSelectedDate;
        this.hours = this.getHours();
        const newValueFirstValidDay = dayjs(newValue).add(1, 'day');
        if (this.isSelectedHourAfterNewValue(newValueFirstValidDay) || !this.isSelectedHourInHours()) {
          this.selectedHour = this.pickupTime
            ? dayjs(this.pickupTime).format('HH:mm')
            : this.hours[0];
        }
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    computeDate() {
      return this.getFormattedSelectedDate(this.selectedDate).locale(this.$i18n.locale).format('LL');
    },
    computeHour() {
      return this.$t('book.form.bookingTime.now');
    },
    computedValue() {
      if (this.isSelectedHourNotNow()) {
        return this.getComputedValueFromDate(this.selectedDate, this.selectedHour);
      }
      return null;
    },
    tooltip() {
      return [this.$t('book.form.bookingTime.reselectReturn')];
    },
  },
  methods: {
    getFormattedSelectedDate(date) {
      return dayjs(date, 'YYYY-MM-DD');
    },
    getHours() {
      const timeStops = this.getDefaultTimeStops();
      const minHour = this.getMinHour();
      const minMinutes = this.getMinMinutes();

      let cursor = dayjs()
        .hour(minHour)
        .minute(minMinutes);

      // Continue until the end of the day
      const endOfDay = dayjs().endOf('day');
      while (cursor.isBefore(endOfDay)) {
        timeStops.push(cursor.format('HH:mm'));
        cursor = cursor.add(5, 'minute');
      }

      return timeStops;
    },
    getDefaultTimeStops() {
      return this.isDaySelected(dayjs()) && !this.return ? [this.$t('book.form.bookingTime.now')] : [];
    },
    isSelectedHourNotNow() {
      return this.selectedHour && this.selectedHour !== this.$t('book.form.bookingTime.now');
    },
    isSelectedHourInHours() {
      return this.hours.indexOf(this.selectedHour) >= 0;
    },
    getMinHour() {
      if (!this.isDaySelected(dayjs(this.minimumDate).add(1, 'day'))) {
        return 0;
      }
      const minDate = dayjs(this.minimumDate);
      const minHour = parseInt(minDate.hour(), 10);
      const mintMinutes = parseInt(minDate.minute(), 10);
      const computedMinHour = mintMinutes > 55 ? minHour + 1 : minHour;
      return computedMinHour === 24 ? 0 : computedMinHour;
    },
    getMinMinutes() {
      if (!this.isDaySelected(dayjs(this.minimumDate).add(1, 'day'))) {
        return 0;
      }
      const minDate = dayjs(this.minimumDate);
      const currentMinutes = parseInt(minDate.minute(), 10);
      if (currentMinutes > 55) {
        return 0;
      }
      return Math.ceil(currentMinutes / 5) * 5;
    },
    isDaySelected(referenceDay) {
      if (!this.selectedDate) {
        return true;
      }
      const day = parseInt(this.getFormattedSelectedDate(this.selectedDate).date(), 10);
      return day === parseInt(referenceDay.date(), 10);
    },
    getNewSelectedDate(newValue) {
      return dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss').substr(0, 10);
    },
    isSelectedHourAfterNewValue(value) {
      return value.valueOf() > this.getComputedValueFromDate(this.selectedDate, this.selectedHour);
    },
    getComputedValueFromDate(dateString, hourString) {
      const date = this.getFormattedSelectedDate(dateString);
      const hourData = hourString ? hourString.split(':') : [];
      const dateWithHour = date.set('hour', parseInt(hourData[0], 10));
      const dateWithMinutes = dateWithHour.set('minute', parseInt(hourData[1], 10));
      return dateWithMinutes.valueOf();
    },
    resetHighlight() {
      this.highlightDateReset = false;
    },
    maskInput(ele) {
      if (ele.keyCode === 32) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }
      const val = ele.target.value.toUpperCase().trim();
      if (!val) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        return;
      }

      const mask = new Mask({ mask: '##:##' });
      if (val !== TIME_ASAP && val.length >= 4) {
        this.time = mask.masked(val);
        this.timeMask = mask.masked(val);
        this.selectedHour = mask.masked(val);
      } else if (val.length === 1) {
        this.time = mask.masked(`0${val}:00`);
        this.timeMask = mask.masked(`0${val}:00`);
        this.selectedHour = mask.masked(`0${val}:00`);
      } else if (val.length === 2) {
        this.time = mask.masked(`${val}:00`);
        this.timeMask = mask.masked(`${val}:00`);
        this.selectedHour = mask.masked(`${val}:00`);
      }

      if (!moment(this.time, 'HH:mm', true).isValid()) {
        this.time = TIME_ASAP;
        this.timeMask = TIME_ASAP;
        this.selectedHour = TIME_ASAP;
      }
    },
    handleTimeFocus(ele) {
      if (ele.target.value === TIME_ASAP) {
        this.timeMask = '';
        this.selectedHour = '';
      } else {
        ele.target.select();
      }
    },
    handleTimeBlurEvent(ele) {
      if (ele.target.value.toUpperCase() === '') {
        this.timeMask = TIME_ASAP;
        this.selectedHour = TIME_ASAP;
      }
      this.maskInput(ele);
    },
  },
};
</script>

<style scoped lang="scss">
  .date-select-field {
    padding: 0 10px 0 0 !important;
    min-height: 44px !important;
  }

  .hour-select-field {
    padding: 0 !important;
  }

  .tooltip {
    margin-top: 10px;
  }

  .v-input__control {
    margin: 0 !important;
    .v-input__slot {
      border: 1px solid #E4E4E4 !important;
      padding: 2px !important;
    }
  }

  .highlight {
    border: 1px solid red;
    border-radius: 5px;
    .v-input__control {
      .v-input__slot {
        margin-bottom: 0 !important;
      }
    }
  }
</style>
