var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"bookingsTable",staticClass:"desctop-bs-table elevation-0",attrs:{"calculate-widths":"","hide-default-footer":"","loading":_vm.bookingsLoading,"headers":_vm.headers,"single-expand":true,"expanded":_vm.expanded,"no-data-text":_vm.$t('bookings.notData'),"no-results-text":_vm.$t('bookings.notData'),"items":_vm.list,"items-per-page":_vm.itemsPerPage},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return _vm.openExpandItem(item, slot); }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [(!_vm.bookingDetailLoading)?_c('expand-item',{attrs:{"headers":headers,"data":_vm.bookingDetail}}):_vm._e()]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyName)+" ")]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.estimatedTimeArrival)?_c('span',{staticClass:"eta-tooltip"},[_vm._v(_vm._s(_vm.$t("bookings.eta", { amount: item.estimatedTimeArrival })))]):_vm._e(),_c('span',{staticClass:"text-overflow"},[_vm._v(_vm._s(item.origin))])])]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.destinationEtaMinutes)?_c('span',{staticClass:"eta-tooltip"},[_vm._v(_vm._s(_vm.$t("bookings.eta", { amount: item.destinationEtaMinutes })))]):_vm._e(),_c('span',{staticClass:"text-overflow"},[_vm._v(_vm._s(item.destination))])])]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c(_vm.creditCardIcon(_vm.getPaymentIcon(item.payment) || 'cash'),{tag:"component"})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getPaymentDetails(item.payment))+" ")])])]}},{key:"item.status",fn:function(ref){
var status = ref.item.status;
return [_c('div',{class:("status-container py-1 px-2 status-" + (status.toUpperCase()))},[_vm._v(" "+_vm._s(_vm.$t(("rideTracking.summary.booking.status." + status)))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }