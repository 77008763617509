<template>
  <v-row row wrap class="ma-0 pa-0 mobile-column-reverse">
    <ride-tracking-summary :booking="booking" screenName="driver_on_the_way_to_pickup"></ride-tracking-summary>
    <ride-tracking-map :booking="booking"></ride-tracking-map>
  </v-row>
</template>

<script>
import RideTrackingMap from '../shared/RideTrackingMap.vue';
import RideTrackingSummary from '../shared/RideTrackingSummary.vue';

export default {
  name: 'ride-tracking-enroute',
  components: {
    RideTrackingSummary,
    RideTrackingMap,
  },
  props: {
    booking: Object,
  },
  mounted() {
    this.$emit('suggestedBookingRefreshInterval', 2000);
  },
  created() {
    this.$emit('trackByStatusPageView', { screenName: 'driver_on_the_way_to_pickup', actionName: 'search_driver_viewed' });
  },
};
</script>

<style scoped>
</style>
