import { minLength, maxLength, required } from 'vuelidate/lib/validators';
import { isValidString } from './validationMethods';

export default {
  rating: {
    comment: {
      minLength: minLength(1),
      maxLength: maxLength(1000),
      isValidString,
    },
    rate: {
      required,
      min: 0.01,
      max: 5,
    },
    userId: {
      required,
    },
  },
};
