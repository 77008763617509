<template>
  <v-dialog
    v-model="dialog"
    width="375"
  >
     <v-card>
      <v-card-title
        class="headline black justify-center title-employee-group"
        primary-title
      >
        {{ $t('book.form.departmentConfirmation.title') }}
      </v-card-title>
      <div
        class="mb-1 pa-2 employee-group-container"
        v-for="(employeeGroup) in employeeGroups"
        @click="selectItem(employeeGroup)"
      >
        <span class="group-name-span">{{ employeeGroup.name }}</span><v-icon medium>{{ isSelected(employeeGroup) }}</v-icon>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="submit-confirmation-dialog pa-2"
          @click="submitConfirmation()"
          :disabled="disableSubmitConfirmation"
          block
          depressed
        >
          {{ $t('book.form.submit.value') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'businessAccount-department-confirmation-dialog',
  created() {
    this.getEmployeeGroups();
  },
  data() {
    return {
      dialog: false,
      selected: null,
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      employeeGroups: 'employeeGroups/employeeGroups',
      employeeGroupsLoading: 'employeeGroups/employeeGroups',
    }),
    disableSubmitConfirmation() {
      return !this.selected;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit('dialogClosed');
      }
    },
    open(newValue) {
      this.dialog = newValue;
    },
    employeeGroups(newValue) {
      if (newValue && newValue.length === 1) {
        this.selectItem(newValue[0]);
      }
    },
  },
  methods: {
    getEmployeeGroups() {
      this.$store.dispatch('employeeGroups/listMe', { page: 0, maxPerPage: 10 });
    },
    submitConfirmation() {
      this.$emit('submitConfirmation');
    },
    isSelected(item) {
      return this.selected && this.selected.id === item.id ? '$icons_system_checkbox' : '$icons_system_checkbox_empty';
    },
    selectItem(item) {
      this.selected = item;
      this.$emit('employeeGroupSelected', item);
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-employee-group {
    text-transform: none !important;
  }
  .submit-confirmation-dialog {
    width: 100%;
    margin-top: 20px;
  }
  .employee-group-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    border-bottom: 1px solid #D4D4D4;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 17px;
    text-overflow: ellipsis;
    .group-name-span {
      max-width: 300px;
    }
  }
</style>
