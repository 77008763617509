<template>
  <v-navigation-drawer
    v-model="isItemSelected"
    width="500"
    right
    temporary
    stateless
    absolute>
    <v-row justify-center>
      <v-col lg11 xl11 md-11>
        <slot name="detail-content"></slot>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'DesktopDetail',
  props: {
    isItemSelected: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>

</style>
