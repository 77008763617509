<template>
  <div class="mobile-nav">
    <v-navigation-drawer v-model="open" :width="$vuetify.breakpoint.width" fixed app clipped  class="drawer-style pa-4">
      <mobile-back-icon @click="toggleMenu" style="cursor: pointer;" />

      <div class="mt-4 mb-2">
        <p class="mb-0 text-h6">{{ displayName }}</p>
        <div class="d-flex align-center justify-space-between" style="cursor: pointer;" @click="$router.push('/user-profile')">
          <a class="grey--text lighten-4 text-decoration-none text-caption subtitle-mob">{{ $t('navigation.menu.viewUserProfile') }}</a>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </div>

      <v-divider class="mb-2" />

      <component
        v-for="item in items"
        :key="item.label"
        :is="item.componentName"
        :item="item"
        :activeClass="'user-nav-active-drawer'"
        class="px-0"
        @click="toggleMenu"
      ></component>
    </v-navigation-drawer>
    <v-app-bar app text absolute color="white" class="toolbar-nav">
      <div id="logo-container">
        <img class="logo-img" :src="logoURL" />
      </div>

      <div class="left-toolbar">
        <button @click="toggleMenu"><v-icon>menu</v-icon></button>
      </div>
    </v-app-bar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { iconLoader } from '@/helpers/iconsLoader';
import MenuLink from './MenuLink.vue';
import LanguageSelector from './LanguageSelector.vue';
import UserProfileNavigation from './UserProfileNavigation.vue';

export default {
  name: 'MobileNav',
  components: { UserProfileNavigation, MenuLink, LanguageSelector, ...iconLoader.sideMenu },
  props: {
    logoURL: {
      type: String,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  watch: {
    locale() {
      if (this.open) {
        this.open = false;
        this.$nextTick(() => {
          this.open = true;
        });
      }
    },
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
    },
  },
  computed: {
    ...mapGetters({
      displayName: 'authentication/displayName',
      paymentMethodsEnabled: 'features/paymentMethods',
      privacyPolicyLinks: 'bookingChannel/privacyPolicyLinks',
    }),
    locale() {
      return this.$i18n.locale;
    },
    privacyPolicyLink() {
      if (!this.privacyPolicyLinks) return '#';
      return (
        this.privacyPolicyLinks[this.$i18n.locale] || this.privacyPolicyLinks.en
      );
    },
    items() {
      const menuItems = [];

      menuItems.push({
        componentName: 'menu-link',
        label: 'navigation.menu.book',
        icon: 'bookings-list',
        to: '/book',
      });

      menuItems.push({
        componentName: 'menu-link',
        label: 'navigation.menu.bookings',
        icon: 'book-trip',
        to: '/bookings',
      });

      if (this.paymentMethodsEnabled === true) {
        menuItems.push({
          componentName: 'menu-link',
          label: 'navigation.menu.paymentMethods',
          icon: 'payment-method',
          to: '/payment-methods',
        });
      }

      menuItems.push({
        componentName: 'menu-link',
        label: 'navigation.menu.favouriteLocations',
        icon: 'favourites-icon',
        to: '/favourites',
      });

      menuItems.push({
        componentName: 'menu-link',
        icon: 'about-icon',
        label: 'navigation.menu.privacyPolicy',
        href: this.privacyPolicyLink,
        target: '_blank',
        trackingScreenName: 'about',
        trackingActionName: 'privacy_policy_selected',
      });

      menuItems.push({
        componentName: 'language-selector',
        icon: 'language-icon',
        label: 'navigation.menu.language',
      });

      menuItems.push({
        componentName: 'menu-link',
        label: 'navigation.menu.logout',
        icon: 'logout-icon',
        to: '/logout',
        trackingScreenName: 'user_profile',
        trackingActionName: 'log_out_selected',
      });

      return menuItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle-mob {
  color: #888888 !important;
  font-size: 15px !important;
}
.drawer-style {
  z-index: 100000;

  .nav-name-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.25px;
    color: #000000;
  }

  .nav-profile-link {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    a {
      color: #247DFF !important;
    }
  }

  .nav-profile-container {
    margin-top: -15px;
  }

  .nav-divider {
    margin: 0 0 15px 0;
  }
}

.toolbar-nav {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
  z-index: 10000;
}

#account-icon {
  font-size: 34px;
}

.logo-img {
  max-height: 40px;
}

#logo-container {
  width: 50%;
  text-align: left;
}
.left-toolbar {
  width: 50%;
  text-align: right
};

.right-toolbar {
  width: 35%;
  text-align: right;
}
</style>

<style lang="scss">
.drawer-style {
  .v-list__tile__title {
    font-size: 18px;
  }
}
</style>
