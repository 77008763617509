import { BookingHistoryServiceClient, ReceiptServiceClient, BookingEtaClient } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

const bookingHistoryServiceClient = new BookingHistoryServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

bookingHistoryServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
bookingHistoryServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const receiptServiceClient = new ReceiptServiceClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

const bookingEtaClient = new BookingEtaClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});

bookingEtaClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
bookingEtaClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

receiptServiceClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
receiptServiceClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const defaultState = {
  bookings: [],
  bookingsRequirePaymentAction: [],
  bookingDetail: null,
  bookingsLoading: false,
  bookingDetailLoading: false,
  totalCount: 0,
  pickUpEta: 0,
  bookingTab: 'current',
};

const getters = {
  bookingTab: state => state.bookingTab,
  bookings: state => state.bookings,
  bookingDetail: state => state.bookingDetail,
  bookingsLoading: state => state.bookingsLoading,
  bookingDetailLoading: state => state.bookingDetailLoading,
  totalCount: state => state.totalCount,
  getPickUpEta: state => state.pickUpEta,
  bookingsRequirePaymentAction: state => state.bookingsRequirePaymentAction,
};

const actions = {
  async list(context, { filter, page, maxPerPage, sort }) {
    try {
      context.commit('setBookingsLoading', true);
      const { data: bookingHistory, headers } = await bookingHistoryServiceClient.getMyHistory({
        filter,
        page,
        maxPerPage,
        sort,
      });
      context.commit('setTotalCount', headers['x-total-count'] || 0);
      context.commit('setBookings', bookingHistory || []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.dispatch('globalError/addError', {
        message: e.displayMessage,
        code: e.httpStatusCode,
        title: e.displayTitle,
      }, { root: true });
    } finally {
      context.commit('setBookingsLoading', false);
    }
  },
  async listBookingsRequirePaymentAction(context, { filter, page, maxPerPage, sort }) {
    try {
      context.commit('setBookingsLoading', true);
      const { data: bookingHistory, headers } = await bookingHistoryServiceClient.getMyHistory({
        filter,
        page,
        maxPerPage,
        sort,
        bookingPaymentRequiresAction: true,
      });
      context.commit('setTotalCount', headers['x-total-count'] || 0);
      context.commit('setBookingsRequirePaymentAction', bookingHistory || []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.dispatch('globalError/addError', {
        message: e.displayMessage,
        code: e.httpStatusCode,
        title: e.displayTitle,
      }, { root: true });
    } finally {
      context.commit('setBookingsLoading', false);
    }
  },
  async getBookingDetail(context, { id }) {
    try {
      context.commit('setBookingDetail', null);
      context.commit('setBookingDetailLoading', true);
      const detail = await bookingHistoryServiceClient.getHistoryDetails({ id });
      context.commit('setBookingDetail', detail);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.dispatch('globalError/addError', {
        message: e.displayMessage,
        code: e.httpStatusCode,
        title: e.displayTitle,
      }, { root: true });
    } finally {
      context.commit('setBookingDetailLoading', false);
    }
  },

  async getReceiptByBookingId(context, { bookingId }) {
    try {
      context.commit('setBookingDetailLoading', true);
      const lang = context.rootGetters['language/selectedLanguage'];
      const receipt = await receiptServiceClient.getReceiptByBookingId({ bookingId, lang });
      context.commit('setBookingDetailLoading', false);
      return receipt;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      context.commit('setBookingDetailLoading', false);
      context.dispatch('globalError/addError', {
        message: e.displayMessage,
        code: e.httpStatusCode,
        title: e.displayTitle,
      }, { root: true });
      return null;
    }
  },
  async getEtaPickup(context, { latitude, longitude }) {
    try {
      const { data } = await bookingEtaClient.getETAPickup({ latitude, longitude });
      context.commit('setPickUpEta', data);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', {
        message: error.displayMessage,
        code: error.httpStatusCode,
        title: error.displayTitle,
      }, { root: true });
      return null;
    }
  },
};
const mutations = {
  setBookingTab(state, tab) {
    state.bookingTab = tab;
  },
  setBookingDetail(state, bookingDetail) {
    state.bookingDetail = bookingDetail;
  },
  setBookingDetailLoading(state, bookingDetailLoading) {
    state.bookingDetailLoading = bookingDetailLoading;
  },
  setBookings(state, bookings) {
    state.bookings = bookings;
  },
  setBookingsLoading(state, bookingsLoading) {
    state.bookingsLoading = bookingsLoading;
  },
  setTotalCount(state, totalCount) {
    state.totalCount = totalCount;
  },
  setPickUpEta(state, eta) {
    state.pickUpEta = eta.duration;
  },
  setBookingsRequirePaymentAction(state, bookings) {
    state.bookingsRequirePaymentAction = bookings;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
