/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
import store from '../store';

export default async function handleOnBoarding(next) {
  const displayName = store.getters['authentication/displayName'];
  const acrValue = store.getters['authentication/acrValue'];
  if (!displayName
    && !(acrValue === process.env.VUE_APP_LEGACY_BUSINESS_ACR_VALUES
    || acrValue === process.env.VUE_APP_BUSINESS_ACR_VALUES)) {
    location.replace('/on-boarding');
    return;
  }
  next();
}
