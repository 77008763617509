import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const defaultState = {
  firebaseAnalytics: null,
};

const getters = {
  firebaseAnalytics: state => state.firebaseAnalytics,
};

const actions = {};

const mutations = {
  initializeFirebase(state, { firebaseWebbookerAnalytics }) {
    if (!firebaseWebbookerAnalytics) {
      return;
    }

    const firebaseConfig = {
      apiKey: firebaseWebbookerAnalytics.apiKey,
      projectId: firebaseWebbookerAnalytics.projectId,
      messagingSenderId: firebaseWebbookerAnalytics.messagingSenderId,
      appId: firebaseWebbookerAnalytics.appId,
    };

    firebase.initializeApp(firebaseConfig);
    state.firebaseAnalytics = firebase.analytics();
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
