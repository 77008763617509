<template>
  <loader :is-loading="loading" color="primary" />
</template>

<script>
import { mapGetters } from 'vuex';
import { getLoginUrl } from '../../authentication/authenticationManager';
import LanguageSelectorMixin from '../shared/mixins/LanguageSelectorMixin';

/* eslint-disable */
export default {
  name: 'email-login-redirect',
  data() {
      return {
          invalidRedirect: false,
          loading: false,
      };
  },
  components: {
  },
  async created() {
    this.loading = true;
    try {
      const { acrValues, audience, requestUrl, locale } = this.$route.query;
      await this.executeLoginRedirect({ acrValues, audience, requestUrl, locale });
    } catch (error) {
      await this.$store.dispatch('globalError/setAuthError', { message: error.message });
      this.$router.push('/');
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      selectedLanguage: 'language/selectedLanguage',
    }),
  },

  mixins: [LanguageSelectorMixin],
  methods: {
    async executeLoginRedirect({ acrValues, audience, requestUrl, locale }) {
      if (locale) {
        await this.selectLanguage(locale);
      }
      if (!acrValues || !audience || !requestUrl ) {
        throw new Error(this.$i18n.t('emailLoginRedirect.invalidRequest'));
      }
      location.replace(await this.getLoginUrl({ acrValues, audience, requestUrl }));
    },
    async getLoginUrl({ acrValues, audience, requestUrl }) {
      const loginUrl = await getLoginUrl({ path: '/book' }, audience, acrValues, requestUrl);
      return loginUrl;
    }
  },
};
</script>
