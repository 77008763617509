import uuid from 'uuid';

const defaultState = {
  errors: [],
  authError: null,
};

const getters = {
  errors: state => state.errors,
  authError: state => state.authError,
};
const actions = {
  addError(context, { message, title, code }) {
    const newError = { message, title, code, id: uuid() };
    const errors = [...context.state.errors, newError];
    context.commit('setErrors', errors);
  },
  popError(context, { id }) {
    const errors = context.state.errors.filter(error => error.id !== id);
    context.commit('setErrors', errors);
  },
  setAuthError(context, { message }) {
    context.commit('setAuthError', { message });
  },
};
const mutations = {
  setErrors(state, errors) {
    state.errors = errors;
  },
  setAuthError(state, authError) {
    state.authError = authError;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
