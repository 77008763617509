import axios from 'axios';
import qs from 'qs';
import jwt from 'jsonwebtoken';

// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, getTokenExpirationFromDuration } from '../../authentication';

const TOKEN_KEY = 'access_token';
const ID_TOKEN_KEY = 'id_token';
const REFRESH_TOKEN = 'refresh_token';
const LOGIN_CONTEXT = 'login_context';
const TOKEN_EXPIRED_AT = 'token_expired_at';
const DISPLAY_NAME = 'display_name';

const authenticationClient = axios.create({
  baseURL: process.env.VUE_APP_AUTHENTICATION_BASE_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});
authenticationClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));

const defaultState = {
  token: null,
  tokenExpiredAt: null,
  idToken: null,
  refreshToken: null,
  isRefreshingToken: false,
  loginContext: null,
  displayName: null,
  analyticsId: null,
  acrValue: null,
};

const getters = {
  token: state => state.token || localStorage.getItem(TOKEN_KEY),
  idToken: state => state.idToken || localStorage.getItem(ID_TOKEN_KEY),
  tokenExpiredAt: state => state.tokenExpiredAt || localStorage.getItem(TOKEN_EXPIRED_AT),
  refreshToken: state => state.refreshToken || localStorage.getItem(REFRESH_TOKEN),
  isRefreshingToken: state => state.isRefreshingToken,
  loginContext: state => state.loginContext || JSON.parse(localStorage.getItem(LOGIN_CONTEXT)),
  displayName: state => state.displayName || localStorage.getItem('display_name'),
  analyticsId: state => state.analyticsId || localStorage.getItem(process.env.VUE_APP_ANALYTICS_USER_ID_KEY),
  acrValue: state => state.acrValue || localStorage.getItem(process.env.VUE_APP_ACR_VALUE_KEY),
};

const actions = {
  async exchangeCodeForToken(context, payload) {
    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    try {
      const { data } = await authenticationClient.post('/oauth2/token', qs.stringify(payload), config);
      const tokenExpiredAt = getTokenExpirationFromDuration(data.expires_in * 1000);
      localStorage.setItem(TOKEN_KEY, data.access_token);
      localStorage.setItem(ID_TOKEN_KEY, data.id_token);
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
      localStorage.setItem(TOKEN_EXPIRED_AT, tokenExpiredAt);
      context.commit('setToken', data.access_token);
      context.commit('setIdToken', data.id_token);
      context.commit('setRefreshToken', data.refresh_token);
      context.commit('setTokenExpiredAt', tokenExpiredAt);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    }
  },
  async refreshToken(context, { redirectTo } = {}) {
    const refreshToken = context.rootGetters['authentication/refreshToken'];
    if (context.rootGetters['authentication/isRefreshingToken'] || !refreshToken) return;
    context.commit('setIsRefreshingToken', true);
    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    try {
      const { data } = await authenticationClient.post('/oauth2/token', qs.stringify({ refresh_token: refreshToken, grant_type: 'refresh_token', redirect_uri: context.rootGetters['bookingChannel/authCallbackUrl'], client_id: context.rootGetters['bookingChannel/oidcClientId'] }), config);
      // transform from s to ms
      const tokenExpiredAt = getTokenExpirationFromDuration(data.expires_in * 1000);
      localStorage.setItem(TOKEN_KEY, data.access_token);
      localStorage.setItem(ID_TOKEN_KEY, data.id_token);
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
      localStorage.setItem(TOKEN_EXPIRED_AT, tokenExpiredAt);
      context.commit('setToken', data.access_token);
      context.commit('setIdToken', data.id_token);
      context.commit('setRefreshToken', data.refresh_token);
      context.commit('setTokenExpiredAt', tokenExpiredAt);
      if (redirectTo) window.location.replace(redirectTo);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    context.commit('setIsRefreshingToken', false);
  },
  async setLoginContext(context, loginContext) {
    localStorage.setItem(LOGIN_CONTEXT, JSON.stringify(loginContext));
    context.commit('setLoginContext', loginContext);
  },
  async clearToken(context) {
    localStorage.removeItem(TOKEN_KEY);
    context.commit('setToken', null);
  },
  async clearLoginContext(context) {
    localStorage.removeItem(LOGIN_CONTEXT);
    context.commit('setLoginContext', null);
  },
  async clearDisplayName(context) {
    localStorage.removeItem(DISPLAY_NAME);
    context.commit('setDisplayName', null);
  },
  async revokeToken(context, payload) {
    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    try {
      await authenticationClient.post('/oauth2/revoke', qs.stringify(payload), config);
      localStorage.removeItem(TOKEN_KEY);
      // TODO handle properly with id_token
      localStorage.removeItem('display_name');
      localStorage.removeItem(process.env.VUE_APP_ANALYTICS_USER_ID_KEY);
      localStorage.removeItem(process.env.VUE_APP_ACR_VALUE_KEY);
      context.commit('setToken', null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'authentication.tokenRevocationError' }, { root: true });
    }
  },
  async setDisplayName(context) {
    if (context.rootGetters['userProfile/user'].givenName && context.rootGetters['userProfile/user'].familyName) {
      const displayName = `${context.rootGetters['userProfile/user'].givenName} ${context.rootGetters['userProfile/user'].familyName}`;
      localStorage.setItem('display_name', displayName);
      context.commit('setDisplayName', displayName);
    }
  },
  async setFieldsFromIdToken(context) {
    if (context.rootGetters['authentication/idToken']) {
      const { acr, name } = jwt.decode(context.rootGetters['authentication/idToken']);

      if (acr) {
        localStorage.setItem(process.env.VUE_APP_ACR_VALUE_KEY, acr);
        context.commit('setAcrValue', acr);
      }
      if (name) {
        localStorage.setItem('display_name', name);
        context.commit('setDisplayName', name);
      }
    }
  },
  async setAnalyticsId(context) {
    if (context.rootGetters['userProfile/user']) {
      const { analyticsId } = context.rootGetters['userProfile/user'];
      localStorage.setItem(process.env.VUE_APP_ANALYTICS_USER_ID_KEY, analyticsId);
      context.commit('setAnalyticsId', analyticsId);
    } else {
      localStorage.removeItem(process.env.VUE_APP_ANALYTICS_USER_ID_KEY);
      context.commit('setAnalyticsId', null);
    }
  },
};
const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setIdToken(state, idToken) {
    state.idToken = idToken;
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  setIsRefreshingToken(state, isRefreshingToken) {
    state.isRefreshingToken = isRefreshingToken;
  },
  setTokenExpiredAt(state, tokenExpiredAt) {
    state.tokenExpiredAt = tokenExpiredAt;
  },
  setLoginContext(state, loginContext) {
    state.loginContext = loginContext;
  },
  setDisplayName(state, displayName) {
    state.displayName = displayName;
  },
  setAnalyticsId(state, analyticsId) {
    state.analyticsId = analyticsId;
  },
  setAcrValue(state, acrValue) {
    state.acrValue = acrValue;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
