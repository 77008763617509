import { BookingQueryClient } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

let interval;

const bookingQueryClient = new BookingQueryClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});
bookingQueryClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
bookingQueryClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));


const defaultState = {
  booking: null,
  bookingFirstLoadInProgress: false,
};

const getters = {
  getBooking: state => state.booking,
  getBookingFirstLoadInProgress: state => state.bookingFirstLoadInProgress,
};

const actions = {
  async getById({ state, commit, dispatch }, { bookingId, firstLoading = false }) {
    try {
      commit('setBookingFirstLoadInProgress', firstLoading);
      const booking = await bookingQueryClient.getById({ id: bookingId });
      commit('setBooking', booking);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      if (state.bookingFirstLoadInProgress && (error.response && error.response.status !== 404)) {
        dispatch('globalError/addError', { message: 'rideTracking.retrievalError' }, { root: true });
      }
    } finally {
      commit('setBookingFirstLoadInProgress', false);
    }
  },
  async startGetBooking({ dispatch, commit }, { bookingId, firstLoading = false }) {
    clearInterval(interval);
    if (firstLoading) {
      commit('setBooking', null);
    }

    dispatch('getById', { bookingId, firstLoading });
    // if (refreshInterval) {
    //   interval = setInterval(() => {
    //     dispatch('getById', { bookingId });
    //   }, refreshInterval);
    // }
  },
  async stopGetBooking() {
    clearInterval(interval);
  },
};
const mutations = {
  setBookingFirstLoadInProgress(state, bookingFirstLoadInProgress) {
    state.bookingFirstLoadInProgress = bookingFirstLoadInProgress;
  },
  setBooking(state, booking) {
    state.booking = booking;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
