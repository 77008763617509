<template>
  <v-container fluid grid-list-xl text-center class="onboarding-container">
    <loader :is-loading="userLoading" :margin="20" color="primary">
      <v-layout
        align-center
        class="mx-auto"
        text-center
        wrap
      >
        <v-flex lg12 xs12>
          <img class="company-logo"
               width="60px" :src="logoURL" :alt="companyName"/>
          <v-card
            outlined
            class="mx-auto pa-10 onboarding-card"
            color="white"
          >
            <div class="onboarding-title">{{ $t('onboarding.title') }}</div>
            <div class="onboarding-subtitle">{{ $t('onboarding.subtitle') }}</div>
            <v-form class="onboarding-form">
              <v-card-text class="px-0 py-1">
                <p class="user-profile-label">{{ $t('onboarding.form.givenName') }}</p>
                <validated-component
                  name="givenName"
                  :classes="['user-profile-error']"
                  v-model="userProfile.givenName"
                  :validator="$v.userProfile.givenName">
                  <v-text-field
                    class="profile-field mt-0 show-border"
                    height="44"
                    v-model="userProfile.givenName"
                    text
                    hide-details
                    light
                    outline
                    solo
                    flat
                    no-filter>
                  </v-text-field>
                </validated-component>
              </v-card-text>

              <v-card-text class="px-0 py-1">
                <p class="user-profile-label">{{ $t('onboarding.form.familyName') }}</p>
                <validated-component
                name="familyName"
                :classes="['user-profile-error']"
                v-model="userProfile.familyName"
                :validator="$v.userProfile.familyName">
                <v-text-field
                  class="profile-field mt-0"
                  height="44"
                  v-model="userProfile.familyName"
                  text
                  hide-details
                  light
                  outline
                  solo
                  flat
                  no-filter>
                </v-text-field>
              </validated-component>
              </v-card-text>
            </v-form>
            <v-btn
              height="50"
              width="100%"
              block
              depressed
              color="primary"
              :disabled="disablePostUserProfile"
              @click="updateUserProfile"
              class="onboarding-submit primaryFont--text mt-2"
            >
              {{ $t('onboarding.form.submit') }}
            </v-btn>
          </v-card>
        </v-flex>
      </v-layout>
    </loader>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import onboardingValidationData from '../../validation/onboardingValidationData';

export default {
  name: 'OnBoarding',
  validations() {
    return onboardingValidationData;
  },
  created() {
    this.checkDisplayName();
  },
  data() {
    return {
      screenName: 'enter_name',
      userProfile: {
        givenName: '',
        familyName: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      userLoading: 'userProfile/userLoading',
      displayName: 'authentication/displayName',
      acrValue: 'authentication/acrValue',
      logoURL: 'bookingChannel/logo',
      companyName: 'bookingChannel/name',
    }),
    disablePostUserProfile() {
      return this.$v.$invalid || this.userLoading;
    },
  },
  methods: {
    async updateUserProfile() {
      try {
        await this.$store.dispatch('userProfile/updateUser', this.userProfile);
        await this.$store.dispatch('authentication/setDisplayName');
        this.$router.push('/book');
      } catch (error) {
        throw error;
      }
    },
    checkDisplayName() {
      if (this.displayName
        || this.acrValue === process.env.VUE_APP_LEGACY_BUSINESS_ACR_VALUES
        || this.acrValue === process.env.VUE_APP_BUSINESS_ACR_VALUES) {
        this.$router.push('/book');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-logo {
  margin-bottom: 25px;
}

.profile-field {
  border: 1px solid #EEEEEE;
  background-color: #F8F8F8;
  border-radius: 6px;
}

.user-profile-label {
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 11px;
  line-height: 19px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #888888;
  margin-bottom: 5px;
}

.onboarding-container {
  height: 100vh;
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-card {
  max-width: 480px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1) !important;
}

.onboarding-title {
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.01em;
}

.onboarding-subtitle {
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  font-style: normal;
  letter-spacing: -0.01em;
  padding-top: 5px;
}

.onboarding-submit {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 6px;
}

.onboarding-form {
  margin-top: 25px;
}
</style>

<style lang="scss">
</style>
