
export default {
  computed: {
    languages() {
      return this.$i18n.availableLocales
        .filter(lg => lg !== 'en')
        .map(language => ({ id: language, name: this.$i18n.t(`language.${language}`) }));
    },
  },
  methods: {
    async selectLanguage(language) {
      this.$store.dispatch('language/setSelectedLanguage', (this.languageIsAvailable(language) ? language.toLowerCase() : this.getDefaultLanguage()));
    },
    async getLanguageFromNavigator() {
      if (navigator && navigator.language) {
        if (this.languageIsAvailable(navigator.language)) return navigator.language.toLowerCase();
        const masterLanguage = navigator.language.split('-')[0];
        if (masterLanguage && this.languageIsAvailable(masterLanguage)) return masterLanguage.toLowerCase();
      }
      return this.getDefaultLanguage();
    },
    languageIsAvailable(language) {
      if (this.languages.map(l => l.id).includes(language.toLowerCase())) return true;
      return false;
    },
    getDefaultLanguage() {
      return 'en-uk';
    },
  },
};
