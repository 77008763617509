import { RatingCommandClient, RatingQueryClient } from '@icabbi/api-gateway-client';
// eslint-disable-next-line import/no-cycle
import { authenticationFailureInterceptor, bearerTokenInterceptor } from '../../authentication';

const ratingCommandClient = new RatingCommandClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});
ratingCommandClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
ratingCommandClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const ratingQueryClient = new RatingQueryClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  timeout: process.env.VUE_APP_HTTP_REQUEST_TIMEOUT,
});
ratingQueryClient.httpClient.interceptors.response.use(undefined, error => authenticationFailureInterceptor(error));
ratingQueryClient.httpClient.interceptors.request.use(config => bearerTokenInterceptor(config));

const defaultState = {
  bookingRatings: null,
  bookingRatingInCreation: false,
  bookingRatingCreated: false,
};

const getters = {
  getBookingRatings: state => state.bookingRatings,
  getBookingRatingInCreation: state => state.bookingRatingInCreation,
  getBookingRatingCreated: state => state.bookingRatingCreated,
};

const actions = {
  async createBookingRating(context, { bookingId, rating }) {
    try {
      context.commit('setBookingRatingCreated', false);
      context.commit('setBookingRatingInCreation', true);
      const data = Object.assign(
        { bookingChannelId: context.rootGetters['bookingChannel/bookingChannelId'] },
        rating
      );
      await ratingCommandClient.createBookingRating(bookingId, { data });
      context.commit('setBookingRatingCreated', true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      context.dispatch('globalError/addError', { message: 'bookingRating.creationError' }, { root: true });
    } finally {
      context.commit('setBookingRatingInCreation', false);
    }
  },
  async getBookingRatings(context, { bookingId }) {
    try {
      const bookingRatings = await ratingQueryClient.getRatings({
        entityType: 'booking',
        entityId: bookingId,
      });
      context.commit('setBookingRatings', bookingRatings);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
};

const mutations = {
  setBookingRatingInCreation(s, bookingRatingInCreation) {
    s.bookingRatingInCreation = bookingRatingInCreation; // eslint-disable-line
  },
  setBookingRatingCreated(s, bookingRatingCreated) {
    s.bookingRatingCreated = bookingRatingCreated; // eslint-disable-line
  },
  setBookingRatings(s, bookingRatings) {
    s.bookingRatings = bookingRatings; //eslint-disable-line
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
