<template>
  <div>
    <desktop-nav v-if="isDesktop" :logoURL="logoURL" @drawer="(val) => $emit('drawer', val)"></desktop-nav>
    <mobile-nav v-if="isMobile" :logoURL="logoURL"></mobile-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DesktopNav from './DesktopNav.vue';
import MobileNav from './MobileNav.vue';

export default {
  name: 'navigation',
  components: { DesktopNav, MobileNav },
  props: {},
  computed: {
    ...mapGetters({
      logoURL: 'bookingChannel/logo',
    }),
    isMobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
    },
    isDesktop() {
      return ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-btn-active {
  font-weight: bold;
  border-bottom: solid black 4px;
  border-radius: 0;
}
.toolbar-nav {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
  z-index: 10000;
}

#account-icon {
  font-size: 34px;
}

.logo-img {
  max-height: 40px;
}

.mobile-logo-container {
  height: 55px;
  display: flex;
  justify-content: center;
  .logo-img {
    max-height: 40px;
    margin-top: 15px;
  }
}

.mobile-nav {
  top: 55px !important;
}
</style>
