<template>
  <v-list class="mobile-list">
    <v-list-item
      v-for="item in list"
      :key="item[listKey]"
      class="list-item"
      @click="() => selectItem(item)">
      <v-list-item-content>
        <div class="d-flex justify-space-between align-center">
          <v-list-item-title>{{ `${item.pickupDate} - ${item.pickupTime}` }}</v-list-item-title>
          <div :class="`status-container py-1 px-2 status-${item.status.toUpperCase()}`">
            {{ $t(`rideTracking.summary.booking.status.${item.status}`) }}
          </div>
        </div>

        <div class="mt-2">
          <v-list-item-title class="mb-1">{{ 'Origin' }}</v-list-item-title>
          <div class="d-flex flex-column">
            <span v-if="item.estimatedTimeArrival" class="eta-tooltip">{{ $t(`bookings.eta`, { amount: item.estimatedTimeArrival }) }}</span>
            <p class="mb-0">{{ item.origin }}</p>
          </div>
        </div>

        <div v-if="item.waypoints.length">
          <div v-for="(waypoint, index) in item.waypoints" :key="index" class="mt-2">
            <v-list-item-title class="mb-1">{{ 'Waypoint' }}</v-list-item-title>
            <p class="mb-0">{{ waypoint.origin }}</p>
          </div>
        </div>

        <div class="mt-2">
          <v-list-item-title class="mb-1">{{ 'Destination' }}</v-list-item-title>
          <div class="d-flex flex-column">
            <span v-if="item.destinationEtaMinutes" class="eta-tooltip">{{ $t(`bookings.eta`, { amount: item.destinationEtaMinutes }) }}</span>
            <p class="mb-0">{{ item.destination }}</p>
          </div>
        </div>

        <div class="d-flex justify-end my-2" style="width: 100%;">
          <p class="mb-0 font-weight-bold text-body-2">{{ `${item.price}` }}</p>
        </div>
      </v-list-item-content>
      <v-list-item-action v-if="item.rightElement">
        {{ item.rightElement }}
      </v-list-item-action>
    </v-list-item>
    <v-list-item class="not-data-tile" v-if="!list.length">
      {{ $t('bookings.notData') }}
    </v-list-item>
  </v-list>
</template>

<script>
import listMixin from './mixin/listMixin';

export default {
  name: 'MobileList',
  mixins: [listMixin],
  props: {
    list: {
      type: Array,
      required: true,
    },
    listKey: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .mobile-list {
    padding-top: 0 !important;

    .list-item {
      text-align: left;
    }

    .v-list-item__content {
      text-align: left;
      min-height: 10rem;
      cursor: pointer;
    }

    .v-list-item__title {
      margin-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
    }
  }

  .not-data-tile {
    .v-list__tile {
      justify-content: center !important;
    }
  }
</style>
