const bookingCancelledStatus = [
  'BOOKING-DISPATCH-CANCELLED', 'CUSTOMER_CANCELLED', 'DISPATCH_CANCELLED', 'NOSHOW',
];

const bookingCompletedStatus = ['COMPLETED'];

class BookingHelper {
  static isBookingCancelled(booking) {
    return bookingCancelledStatus.includes(booking.status);
  }

  static isBookingCompleted(booking) {
    return bookingCompletedStatus.includes(booking.status);
  }
}

module.exports = {
  BookingHelper,
};
