<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    :width="220"
    :mini-variant-width="64"
    :permanent="true"
    :mini-variant="drawer"
    class="navigation-drawer"
    style="background-color: #ffffff"
  >
    <div class="d-flex align-center justify-center" style="width: 100%">
      <img :src="logoURL" class="nav-logo mr-2" />
      <span v-if="!drawer" class="nav-label">{{ getHumanReadableId }}</span>
    </div>
    <v-list
      nav
      dense
      class="booking-menu-navigation"
    >
      <v-list-item
        active-class="active-border"
        :class="{ optionalMargin: activeItem !== 'book' }"
        link
        :to="$router.resolve({ name: 'book' }).href"
        @click="activeItem = 'book'"
      >
        <v-list-item-icon class="navigation-link-icon">
          <bookings-list :style="{ color: activeItem === 'book' ? '#333333 !important' : '#AAAAAA !important' }"/>
        </v-list-item-icon>

        <v-list-item-title>
          {{ $t('navigation.menu.book') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        active-class="active-border"
        :class="{ 'optionalMargin': activeItem !== 'bookings' }"
        link
        :to="$router.resolve({ name: 'bookings' }).href"
        @click="activeItem = 'bookings'"
      >
        <v-list-item-icon class="navigation-link-icon">
          <book-trip :style="{ color: activeItem === 'bookings' ? '#333333 !important' : '#AAAAAA !important' }"/>
        </v-list-item-icon>

        <v-list-item-title>
          {{ $t('navigation.menu.bookings') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        active-class="active-border"
        :class="{ optionalMargin: activeItem !== 'paymentMethods' }"
        link
        :to="$router.resolve({ name: 'paymentMethods' }).href"
        @click="activeItem = 'paymentMethods'"
      >
        <v-list-item-icon class="navigation-link-icon">
          <payment-method :style="{ color: activeItem === 'paymentMethods' ? '#333333 !important' : '#AAAAAA !important' }"/>
        </v-list-item-icon>

        <v-list-item-title>
          {{ $t('navigation.menu.paymentMethods') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        active-class="active-border"
        :class="{ optionalMargin: activeItem !== 'favourites' }"
        link
        :to="$router.resolve({ name: 'favourites' }).href"
        @click="activeItem = 'favourites'"
      >
        <v-list-item-icon class="navigation-link-icon">
          <favourites-icon style="width: 24px" :style="{ color: activeItem === 'favourites' ? '#333333 !important' : '#AAAAAA !important' }"/>
        </v-list-item-icon>

        <v-list-item-title>
          {{ $t('navigation.menu.favouriteLocations') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <div class="d-flex flex-column justify-start align-center w-full pb-5">
      <user-profile-navigation :drawer="drawer" class="mb-5" />
      <expand-icon-close v-if="drawer" class="expand-icon" @click="drawer = !drawer"/>
      <expand-icon-open v-else class="expand-icon" @click="drawer = !drawer"/>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import { iconLoader } from '@/helpers/iconsLoader';
import UserProfileNavigation from './UserProfileNavigation.vue';

export default {
  name: 'DestkopNav',
  components: {
    UserProfileNavigation,
    ...iconLoader.sideMenu,
  },
  props: {
    logoURL: {
      type: String,
    },
  },
  data() {
    return {
      drawer: false,
      activeItem: 'book',
      styleIconColor: {
        color: '#333333 !important',
      },
    };
  },
  watch: {
    drawer: {
      handler(val) {
        this.$emit('drawer', val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getHumanReadableId: 'bookingChannel/getHumanReadableId',
    }),
  },
};
</script>

<style lang="scss" scoped>
  .navigation-drawer {
    .v-list {
      padding-left: 0px;
      padding-right: 0px;
      color: black;
    }
    .v-list-item {
      border-top: none !important;
      border-bottom: none !important;
      border-right: none !important;
    }
    .v-list-item__icon {
      padding-left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-list-item::before {
      border-radius: 0;
    }
    .v-list-item {
      color: black !important;
    }
    .v-list-item__title {
      font-family: "Inter" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 15px !important;
      display: flex;
      align-items: center;
      letter-spacing: -0.005em;
      color: #333333;
    }
    .v-list--nav .v-list-item, .v-list--nav .v-list-item::before {
      border-radius: 0 !important;
    }
  }

  .optionalMargin {
    margin-left: 3px;
  }

  .expand-icon {
    width: 35px;
    cursor: pointer;
    transition: 0.2s;
  }

  .navigation-link-icon {
    margin-right: 1em !important;
    min-width: 32px !important;
  }

  .header-bar {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  }
  .booking-menu-navigation {
    width: 100%;
    height: 50%;
  }
</style>

<style lang="scss">
.v-navigation-drawer--mini-variant {
  .navigation-link-icon {
    margin-right: 0px !important;
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 56px !important;
}

.active-border {
  border-left: 3px solid #47D061 !important;
  .v-list-item__title {
    color: black !important;
  }
  svg {
    color: #333333 !important;
  }
}
.nav-logo {
  height: 40px;
  margin-left: 8px;
  margin-top: 8px;
}
.nav-label {
  color: #000000;
}
.navigation-drawer .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}
.navigation-drawer .v-navigation-drawer__border {
  display: none;
}

.v-list-item--active, .v-list-group__header:hover, .v-list-item:hover {
  border-radius: 0 !important;
}
</style>
