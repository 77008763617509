<template>
  <div class="ride-tracking-summary-container">
    <div v-if="!booking">{{ $t('rideTracking.summary.noAvailableBooking') }}</div>
    <div v-if="booking">
      <div
        class="d-flex align-center ride-tracking-summary-header"
      >
        <trip-confirmed class="mr-3" />
        <h2>{{ $t('rideTracking.summary.tripConfirmed') }}</h2>
      </div>
      <div cols="12" md12 class="ride-tracking-summary-body">
        <!-- <section class="ride-tracking-summary-container-group driver-vehicle">
          <driver-vehicle :driverInfo="driver" :vehicleInfo="vehicle"></driver-vehicle>
        </section> -->
        <section class="ride-tracking-summary-container-group addresses">
          <div class="single-address-container mb-6">
            <h3>{{ $t('rideTracking.summary.booking.pickup') }}</h3>
            {{ pickupAddress }}
          </div>
          <div class="vias-container">
            <div class="single-address-container mb-6" v-for="(waypoint, index) in booking.waypoints" :key="`waypoint-${index}`">
              <h3>{{ $t('rideTracking.summary.booking.waypoint') }} #{{index+1}}</h3>
              {{ waypoint.address }}
            </div>
          </div>
          <div class="single-address-container">
            <h3>{{ $t('rideTracking.summary.booking.destination') }}</h3>
            {{ destinationAddress }}
          </div>
        </section>
        <section v-if="departureDate" class="ride-tracking-summary-container-group">
          <div>
            <h3>{{ $t('rideTracking.summary.booking.departureDate') }}</h3>
            {{ departureDate }}
          </div>
          <div v-if="returnDate">
            <h3>{{ $t('rideTracking.summary.booking.returnDate') }}</h3>
            {{ returnDate }}
          </div>
        </section>
        <section class="ride-tracking-summary-container-group">
          <div>
            <h3>{{ $t('rideTracking.summary.booking.passenger') }}</h3>
            {{ passenger }}
          </div>
          <div v-if="tripsDistance">
            <h3>{{ $t('rideTracking.summary.booking.tripsDistance') }}</h3>
            {{ tripsDistance }}
          </div>
        </section>

        <v-divider class="mb-6" />

        <section class="ride-tracking-summary-container-group">
          <div>
            <h3>{{ $t('rideTracking.summary.booking.paymentMethod.title') }}</h3>
            {{ paymentMethod }}
          </div>
        </section>

        <loader :is-loading="isFareLoading" :justify-center="false">
          <section v-if="fareEstimation" class="ride-tracking-summary-container-group">
            <div>
              <h3>{{ $t('rideTracking.summary.booking.paymentMethod.Price') }}</h3>
              <span>
                {{ `${currencySymbol}` }} {{ getFormattedFare }}
              </span>
            </div>
          </section>
        </loader>
      </div>
      <div class="ride-tracking-summary-actions mt-6">
        <button
          v-if="getReturnBooking && getReturnBooking.id"
          v-on:click="viewReturnBooking"
        >{{ $t('rideTracking.summary.returnBooking.value') }}</button>
      </div>
      <div class="ride-tracking-summary-actions mt-6">
        <button
          v-if="isBookingCancellable"
          v-on:click="cancelBooking()"
        >{{ $t('rideTracking.summary.cancel.value') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

import { NumberFormatter } from '@icabbi/hi-dash';
import i18n from '../../../i18nInstance';
import DriverVehicle from './DriverVehicle.vue';
import { bookingCancellable } from '../../../rideTracking';
import { iconLoader } from '../../../helpers/iconsLoader';

export default {
  name: 'ride-tracking-summary',
  components: {
    DriverVehicle,
    ...iconLoader.booking,
  },
  props: {
    booking: Object,
    title: String,
    hideStatus: Boolean,
    cancelled: {
      type: Boolean,
      default: false,
    },
    screenName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fareEstimation: false,
      isFareLoading: false,
    };
  },
  mounted() {
    this.getFare();
  },
  methods: {
    async cancelBooking() {
      await this.$store.dispatch('book/cancel', this.booking.id);

      if (this.getReturnBooking.id) {
        await this.$store.dispatch('book/cancel', this.getReturnBooking.id);
      }

      window.location = this.$router.resolve({
        name: 'bookings',
      }).href;
    },
    viewReturnBooking() {
      const link = window.location.href.split('/');
      link.pop();

      const returnBookingUrl = `${link.join('/')}/${this.getReturnBooking.id}`;
      window.open(returnBookingUrl, '_blank');
    },
    formattedDate(date) {
      if (!date) return '';
      return dayjs(date).format(i18n.t('rideTracking.summary.dateFormat'));
    },
    async getFare() {
      if (!this.booking) return false;

      const params = {
        body: {
          bookingChannelId: this.bookingChannelId,
          offerId: this.booking.offer.id,
          pickupTime: this.booking.pickupTime,
          origin: this.booking.origin,
          destination: this.booking.destination,
          waypoints: [],
        },
      };

      this.isFareLoading = true;
      this.fareEstimation = await this.$store.dispatch(
        'fareEstimation/getFareEstimation',
        params,
      );

      this.isFareLoading = false;

      if (this.fareEstimation) {
        return this.fareEstimation;
      }

      return false;
    },
  },
  computed: {
    ...mapGetters({
      bookingChannelId: 'bookingChannel/bookingChannelId',
      bookingInCancellation: 'book/getBookingInCancellation',
      cancelledBookingIds: 'book/getCancelledBookingIds',
      getReturnBooking: 'book/getReturnBooking',
      primaryColor: 'bookingChannel/primaryColor',
      currencySymbol: 'bookingChannel/currencySymbol',
    }),
    isBookingCancellable() {
      return bookingCancellable(
        this.booking,
        this.bookingInCancellation,
        this.cancelledBookingIds
      );
    },
    bookingStatus() {
      const key = `rideTracking.summary.booking.status.${this.booking.status}`;
      const computedStatus = this.$t(key);
      if (computedStatus !== key) {
        return computedStatus;
      }
      return this.booking.status;
    },
    pickupAddress() {
      if (!this.booking.origin) return '';
      return this.booking.origin.address;
    },
    destinationAddress() {
      if (!this.booking.destination) return '';
      return this.booking.destination.address;
    },
    departureDate() {
      return this.formattedDate(this.booking.pickupTime);
    },
    returnDate() {
      return this.formattedDate(this.booking.returnTime);
    },
    driver() {
      return this.booking.driver;
    },
    vehicle() {
      return this.booking.vehicle;
    },
    passenger() {
      if (!this.booking.userInfo) return '';
      const { givenName, familyName } = this.booking.userInfo;
      if (!givenName && !familyName) return '-';
      return `${givenName || ''} ${familyName || ''}`;
    },
    tripsDistance() {
      return '';
    },
    paymentMethod() {
      if (this.booking.payment && this.booking.payment.type) return this.$t(`rideTracking.summary.booking.paymentMethod.${this.booking.payment.type}`);
      return this.$t('rideTracking.summary.booking.paymentMethod.cash');
    },
    estimatedTimeArrival() {
      return this.booking.estimatedTimeArrival;
    },
    getFormattedFare() {
      if (!this.fareEstimation) return false;
      if (this.fareEstimation.type === 'RANGE') {
        const minFare = NumberFormatter.stringValueDecimalSeparator(this.fareEstimation.minimumFare.toFixed(2), this.defaultLocale);
        const maxFare = NumberFormatter.stringValueDecimalSeparator(this.fareEstimation.maximumFare.toFixed(2), this.defaultLocale);
        return `${minFare} - ${maxFare}`;
      }

      if (this.fareEstimation.roundedPrice) {
        return NumberFormatter.stringValueDecimalSeparator(this.fareEstimation.roundedPrice.toFixed(2), this.defaultLocale);
      }

      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.ride-tracking-summary-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 3%;
  top: 3%;
  padding: 32px;
  position: absolute;
  z-index: 100;
  height: 818px;
  overflow-y: auto;
  width: 480px;

  .ride-tracking-summary-header {
    margin-bottom: 32px;
  }

  .ride-tracking-summary-information {
    display: flex;
    div {
      width: 50%;
      text-align: left;
    }
  }

  .ride-tracking-summary-actions {
    text-align: left;
    button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.005em;
      color: #47D061;
    }
    button:hover {
      text-decoration: underline;
    }
  }

  hr {
    background-color: #bdbdbd;
    border: 0;
    height: 0;
    border-top: 1px solid #bdbdbd;
  }
  .ride-tracking-summary-container-group {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;

    width: 100%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    margin-bottom: 24px;
    &.addresses {
      flex-flow: row wrap;
    }

    .single-address-container {
      width: 100%;
    }

    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 19px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #888888;
      margin-bottom: 8px;
    }

    div {
      width: 50%;
      text-align: left;
    }

    &.driver-vehicle {
      flex-flow: column;

    }
  }

  .full-width {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .ride-tracking-summary-container {
    .header,
    .body {
      padding: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    .ride-tracking-summary-container-group {
      .single-address-container {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.scrollable {
  overflow-y: scroll;
}
.vias-container {
  width: 100%!important;
}
</style>
