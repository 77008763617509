/* eslint-disable import/prefer-default-export */
const paymentMethodTypeToBookingChannelPaymentMethodType = {
  personalCard: 'creditCard',
  businessCard: 'creditCard',
  businessDispatchInvoice: 'businessAccount',
};

export class AllowedPaymentMethodDelegate {
  static isCreditCardPaymentMethodAllowed({ allowedPaymentMethods }) {
    return allowedPaymentMethods.some(paymentMethod => paymentMethod.type === 'creditCard');
  }

  static resolveAllowedPaymentMethods({ allowedPaymentMethods, paymentMethods }) {
    const allowedPaymentMethodTypes = allowedPaymentMethods.map(paymentMethod => paymentMethod.type);

    const paymentMethodsToReturn = paymentMethods
      .filter(paymentMethod => allowedPaymentMethodTypes.includes(
        paymentMethodTypeToBookingChannelPaymentMethodType[paymentMethod.paymentMethodType]
      ));

    if (allowedPaymentMethodTypes.includes('cash')) { paymentMethodsToReturn.push({ paymentMethodType: 'cash' }); }
    return this.payemntMethodsFactory(paymentMethodsToReturn);
  }

  static payemntMethodsFactory(paymentMethods) {
    return paymentMethods.map(paymentMethod => ({
      ...paymentMethod,
    }));
  }
}
