import uuid from 'uuid';
import qs from 'qs';
import { randomBytes, createHash } from 'crypto-browserify';

// Value will always be s256, this is the only value supported by ORY Hydra
const CODE_CHALLENGE_METHOD = 'S256';

const RESPONSE_TYPE = 'code';
const GRANT_TYPE = 'authorization_code';
const SCOPE = 'openid offline';

function base64URLEncode(str) {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function sha256(buffer) {
  return createHash('sha256').update(buffer).digest();
}

export function getExchangeCodeForTokenPayload(code, loginContext, clientId, redirectUri) {
  return {
    grant_type: GRANT_TYPE,
    code,
    code_verifier: loginContext.verifier,
    redirect_uri: redirectUri,
    client_id: clientId,
  };
}

export function getLoginRedirectionUrl(state, codeChallenge, clientId, redirectUri, locale, audience, acrValues, requestUrl) {
  const parameters = qs.stringify({
    client_id: clientId,
    response_type: RESPONSE_TYPE,
    redirect_uri: redirectUri,
    state,
    audience,
    scope: SCOPE,
    code_challenge_method: CODE_CHALLENGE_METHOD,
    code_challenge: codeChallenge,
    ui_locales: locale,
    acr_values: acrValues,
    request_url: requestUrl,
  });

  return `${process.env.VUE_APP_AUTHENTICATION_BASE_URL}/oauth2/auth?${parameters}`;
}

export function getCodeVerifier() {
  return base64URLEncode(randomBytes(32));
}

export function getCodeChallenge(verifier) {
  return base64URLEncode(sha256(verifier));
}

export function getState() {
  return uuid();
}

export function getTokenExpirationFromDuration(duration = 3600000) {
  return (Date.now() + parseInt(duration, 10));
}
