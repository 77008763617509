import { iconLoader } from '../helpers/iconsLoader';
// eslint-disable-next-line import/prefer-default-export
export const creditCardIcon = {
  mixins: [iconLoader],
  components: { ...iconLoader.paymentMethod },
  methods: {
    creditCardIcon(brand) {
      const brandLower = brand ? brand.toLowerCase() : '';
      switch (brandLower) {
        case 'cash':
          return 'pay-in-car';
        case 'businessaccount':
          return 'business-account';
        case 'visa':
          return 'visa-card';
        case 'mastercard':
          return 'master-card';
        case 'amex':
          return 'amex-card';
        case 'discover':
          return 'discover-card';
        case 'applepay':
          return 'apple-pay';
        case 'googlepay':
          return 'google-pay';
        case 'new-card':
          return 'new-card';
        default:
          return 'unknown-card';
      }
    },
  },
};
