<template>
    <v-row v-if="booking" row wrap class="ma-0 pa-0 ride-tracking-container">
      <loader :is-loading="isLoading" color="primary" :full-page="true">
        <component
          v-bind:is="componentName"
          :booking="booking"
        ></component>
      </loader>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import RideTrackingDefault from './status/RideTrackingDefault.vue';

export default {
  name: 'shared-ride-tracking',
  components: {
    RideTrackingDefault,
  },
  created() {
    if (this.bookingId) {
      this.$store.dispatch('sharedRideTracking/startGetBooking', {
        refreshInterval: 10000,
        bookingId: this.bookingId,
        token: this.token,
        firstLoading: true,
      });
    }
    this.$store.dispatch('customerConfiguration/getPublicAppConfiguration', {
      bookingChannelId: this.bookingChannelId,
    });
  },
  destroyed() {
    this.$store.dispatch('sharedRideTracking/stopGetBooking');
  },
  computed: {
    ...mapGetters({
      bookingChannelId: 'bookingChannel/bookingChannelId',
      getBooking: 'sharedRideTracking/getBooking',
      isBookingFirstLoadingInProgress: 'sharedRideTracking/getBookingFirstLoadInProgress',
    }),
    booking() {
      return this.getBooking;
    },
    bookingId() {
      return this.$route.params.bookingId;
    },
    token() {
      return this.$route.query.token;
    },
    componentName() {
      return 'RideTrackingDefault';
    },
    isLoading() {
      return this.isBookingFirstLoadingInProgress;
    },
  },
  methods: {},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
.ride-tracking-container {
  height: 100%;
  font-family: 'Inter', sans-serif;
}
</style>
