export default {
  destination: 'book.form.destination.placeholder',
  cardholderName: 'paymentMethod.form.cardholderName',
  city: 'paymentMethod.form.city',
  line1: 'paymentMethod.form.addressLine1',
  email: 'userProfile.form.email',
  familyName: 'userProfile.form.familyName',
  firstName: 'book.form.userInformation.givenName',
  givenName: 'userProfile.form.givenName',
  lastName: 'book.form.userInformation.familyName',
  notes: 'book.form.userInformation.notes',
  origin: 'book.form.pickup.placeholder',
  phone: 'book.form.userInformation.phone',
  postal_code: 'paymentMethod.form.postCode',
  pickupTime: 'book.form.bookingTime.placeholder',
  flightNumber: 'onboarding.form.flightNumber',
  returnTime: 'book.form.bookingTime.return',
  via: 'book.form.via.placeholder',
};
